import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup, Col } from 'react-bootstrap';
import { IoEyeOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';
import { PermintaanPrelimApi } from 'api';

const ListPermintaanPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPermintaanPrelim, setDataPermintaanPrelim] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PermintaanPrelimApi.get({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataPermintaanPrelim(data?.data ?? []);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        if (searchConfig.key !== '') {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchConfig.key}`,
          });
        } else {
          setAlertConfig({
            show: false,
          });
        }
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Prelim');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi Permintaan Prelim</ThFixed>
            <ThFixed>Informasi SPK</ThFixed>
            <Th>Item Prelim</Th>
            <Th>Proyek</Th>
            <Th width={100}>Qty. Permintaan Prelim</Th>
            <Th width={150}>Unit Price</Th>
            <Th width={100}>Durasi Pemakaian</Th>
            <Th width={150}>Sub Total</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataPermintaanPrelim.map((val, index) => {
            const subtotal = parseInt(
              parseFloat(val?.qty_permintaan ?? 0) *
                parseInt(val?.harga_satuan_prelim ?? 0)
            );

            return (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <Td>
                  <div>
                    {' '}
                    {val.tgl_permintaan_prelim
                      ? DateConvert(new Date(val.tgl_permintaan_prelim))
                          .defaultDMY
                      : '-'}{' '}
                  </div>
                  <div> {val.no_permintaan_prelim ?? '-'} </div>
                </Td>
                <Td>
                  <div>
                    {' '}
                    {val.tgl_spk
                      ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                      : '-'}{' '}
                  </div>
                  <div> {val.no_spk ?? '-'} </div>
                </Td>
                <Td> {val.nama_item_prelim ?? ''} </Td>
                <Td>{val.nama_proyek ?? '-'}</Td>
                <Td>{`${parseFloat(val?.qty_permintaan ?? 0)} ${
                  val?.nama_satuan_permintaan ?? ''
                }`}</Td>
                <Td className="text-right">
                  {
                    RupiahConvert(
                      String(parseInt(val?.harga_satuan_prelim ?? 0))
                    ).detail
                  }
                </Td>
                <Td>{`${parseFloat(val?.qty_durasi ?? 0)} ${
                  val?.nama_satuan_durasi ?? ''
                }`}</Td>
                <Td className="text-right">
                  {RupiahConvert(String(parseInt(subtotal ?? 0))).detail}
                </Td>
                <Td>
                  <ButtonGroup>
                    <ActionButton
                      tooltip
                      tooltipText="Detail"
                      variant="info"
                      size="sm"
                      onClick={() =>
                        history.push(
                          `/transaksi/permintaan-prelim/detail/${val.id_spk}`,
                          {
                            id_permintaan_prelim: val.id_permintaan_prelim,
                          }
                        )
                      }
                    >
                      <IoEyeOutline />
                    </ActionButton>
                  </ButtonGroup>
                </Td>
              </Tr>
            );
          })}
        </TBody>
      </Table>
    );

    return (
      <>
        <div className="mt-2 mb-1">
          <b>List Data Permintaan Prelim</b>
        </div>

        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setTimeout(() => {
                    setPaginationConfig((prev) => ({ ...prev, page: 1 }));
                    setSearchConfig((prev) => ({
                      ...prev,
                      key: e.target.value,
                    }));
                  }, 1500);
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            onClick={() =>
              history.push('/transaksi/permintaan-prelim/list-spk')
            }
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPermintaanPrelim ? (
        dataPermintaanPrelim.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default ListPermintaanPrelim;
