import {
  IoServerOutline,
  IoCashOutline,
  IoSpeedometerOutline,
  IoDocumentTextOutline,
} from 'react-icons/io5';
import Logo from '../assets/image/LogoSadhana.png';
import Dashboard from '../pages/Dashboard';

// MASTER
import {
  ItemUpah,
  ItemSubcont,
  // ItemPrelim,
  KelompokUpahSubcont,
  ListTemplateLabourCostOnSite,
  TambahTemplateLabourCostOnSite,
  UbahTemplateLabourCostOnSite,
  DetailTemplateLabourCostOnSite,
} from '../pages/Master';
import {
  // RAE
  RAE,
  PeluangRAE,
  DetailRAE,
  TambahRAE,
  UbahRAE,

  // Permintaan Prelim
  ListPermintaanPrelim,
  DetailPermintaanPrelim,
  TambahPermintaanPrelim,
  ListSPKPermintaanPrelim,
  DetailSPKPermintaanPrelim,

  // Realisasi Prelim
  ListRealisasiPrelim,
  DataRealisasiPrelim,
  ListSPK,
  DetailSPK,
} from '../pages/Transaksi';
import {
  ProgressProduksi,
  LogProgressProduksi,

  // LaporanRAE
  LaporanRAE,
  DetailLaporanRAE,
} from '../pages/Laporan';

// Monitoring Order
import { MonitoringOrder, DetailMonitoringOrder } from '../pages/Laporan';

export default {
  LOGO: Logo,
  MODUL: 'Teknik',

  /* 
    MENU REQUIREMENTS
      > TEXT  = REQUIRED,
      > LINK  = REQUIRED,
      > EXACT = OPTIONAL (TRUE/FALSE),
      > TYPE  = REQUIRED (MENU/DROPDOWN)
      > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
      > LOGO  = OPTIONAL 
  */

  MENU: [
    {
      text: 'Dashboard',
      link: '/',
      type: 'menu',
      exact: true,
      icon: <IoSpeedometerOutline />,
      hak: ['SUPA', 'TNK'],
    },
    {
      text: 'Master Data',
      type: 'dropdown',
      icon: <IoServerOutline />,
      hak: ['SUPA', 'TNK'],
      menu: [
        {
          text: 'Kelompok Upah Dan Subkon',
          link: '/master/kelompok',
          hak: ['SUPA', 'TNK_MAS_KUS'],
        },
        {
          text: 'Item Upah',
          link: '/master/item-upah',
          hak: ['SUPA', 'TNK_MAS_UPH'],
        },
        {
          text: 'Item Subcont',
          link: '/master/item-subcont',
          hak: ['SUPA', 'TNK_MAS_SBC'],
        },
        // {
        //   text: "Item Prelim",
        //   link: "/master/item-prelim",
        //   hak: ["SUPA", "TNK_MAS_IPRE"],
        // },
        {
          text: 'Template Labour Cost On-Site',
          link: '/master/template-labour-cost-onsite',
          hak: ['SUPA', 'TNK_MAS_TLCOS'],
        },
      ],
    },
    {
      text: 'Transaksi',
      type: 'dropdown',
      icon: <IoCashOutline />,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RAE', 'TNK_TRN_PRE', 'TNK_TRN_RPR'],
      menu: [
        {
          text: 'RAE',
          link: '/transaksi/rae',
          hak: ['SUPA', 'TNK_TRN_RAE'],
        },
        {
          text: 'Permintaan Prelim',
          link: '/transaksi/permintaan-prelim',
          hak: ['SUPA', 'TNK_TRN_PRE'],
        },
        {
          text: 'Realisasi Prelim',
          link: '/transaksi/realisasi-prelim',
          hak: ['SUPA', 'TNK_TRN_RPR'],
        },
      ],
    },
    {
      text: 'Laporan',
      type: 'dropdown',
      icon: <IoDocumentTextOutline />,
      hak: ['SUPA', 'TNK'],
      menu: [
        {
          text: 'Progress Produksi',
          link: '/laporan/progress-produksi',
          hak: ['SUPA', 'TNK_RPT_PRP'],
        },
        {
          text: 'Monitoring RAE',
          link: '/laporan/rae',
          hak: ['SUPA', 'TNK_RPT_RAE'],
        },
        {
          text: 'Monitoring Order',
          link: '/laporan/monitoring-order',
          hak: ['SUPA', 'TNK_RPT_MO'],
        },
      ],
    },
  ],

  ROUTES: [
    {
      exact: true,
      path: '/',
      page: Dashboard,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RAE'],
    },
    // MASTER
    {
      exact: true,
      path: '/master/item-upah',
      page: ItemUpah,
      hak: ['SUPA', 'TNK_MAS_UPH'],
    },
    {
      exact: true,
      path: '/master/item-subcont',
      page: ItemSubcont,
      hak: ['SUPA', 'TNK_MAS_SBC'],
    },
    // {
    //   exact: true,
    //   path: "/master/item-prelim",
    //   page: ItemPrelim,
    //   hak: ["SUPA", "TNK_MAS_IPRE"],
    // },
    {
      exact: true,
      path: '/master/kelompok',
      page: KelompokUpahSubcont,
      hak: ['SUPA', 'TNK_MAS_KUS'],
    },
    {
      exact: true,
      path: '/master/template-labour-cost-onsite',
      page: ListTemplateLabourCostOnSite,
      hak: ['SUPA', 'TNK_MAS_TLCOS'],
    },
    {
      exact: true,
      path: '/master/template-labour-cost-onsite/tambah',
      page: TambahTemplateLabourCostOnSite,
      hak: ['SUPA', 'TNK_MAS_TLCOS'],
    },
    {
      exact: true,
      path: '/master/template-labour-cost-onsite/ubah/:id',
      page: UbahTemplateLabourCostOnSite,
      hak: ['SUPA', 'TNK_MAS_TLCOS'],
    },
    {
      exact: true,
      path: '/master/template-labour-cost-onsite/detail/:id',
      page: DetailTemplateLabourCostOnSite,
      hak: ['SUPA', 'TNK_MAS_TLCOS'],
    },
    // TRANSAKSI
    // RAE
    {
      exact: true,
      path: '/transaksi/rae',
      page: RAE,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RAE'],
    },
    {
      exact: true,
      path: '/transaksi/rae/peluang',
      page: PeluangRAE,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RAE'],
    },
    {
      exact: true,
      path: '/transaksi/rae/detail/:id',
      page: DetailRAE,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RAE'],
    },
    {
      exact: true,
      path: '/transaksi/rae/tambah/:id',
      page: TambahRAE,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RAE'],
    },
    {
      exact: true,
      path: '/transaksi/rae/ubah/:id',
      page: UbahRAE,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RAE'],
    },

    // Permintaan Prelim
    {
      exact: true,
      path: '/transaksi/permintaan-prelim',
      page: ListPermintaanPrelim,
      // page: PermintaanPrelim,
      hak: ['SUPA', 'TNK', 'TNK_TRN_PRE'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-prelim/list-spk',
      page: ListSPKPermintaanPrelim,
      hak: ['SUPA', 'TNK', 'TNK_TRN_PRE'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-prelim/detail-spk/:id',
      page: DetailSPKPermintaanPrelim,
      hak: ['SUPA', 'TNK', 'TNK_TRN_PRE'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-prelim/tambah/:id',
      page: TambahPermintaanPrelim,
      hak: ['SUPA', 'TNK', 'TNK_TRN_PRE'],
    },
    {
      exact: true,
      path: '/transaksi/permintaan-prelim/detail/:id',
      page: DetailPermintaanPrelim,
      hak: ['SUPA', 'TNK', 'TNK_TRN_PRE'],
    },

    // Realisasi Prelim
    {
      exact: true,
      path: '/transaksi/realisasi-prelim',
      page: ListRealisasiPrelim,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RPR'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-prelim/list-spk',
      page: ListSPK,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RPR'],
    },
    // Realisasi Prelim
    {
      exact: true,
      path: '/transaksi/realisasi-prelim/list-spk/:tipe/:id',
      page: DetailSPK,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RPR'],
    },
    {
      exact: true,
      path: '/transaksi/realisasi-prelim/:tipe/:id',
      page: DataRealisasiPrelim,
      hak: ['SUPA', 'TNK', 'TNK_TRN_RPR'],
    },

    // LAPORAN
    {
      exact: true,
      path: '/laporan/progress-produksi',
      page: ProgressProduksi,
      hak: ['SUPA', 'TNK_RPT_PRP'],
    },
    {
      exact: true,
      path: '/laporan/progress-produksi/log',
      page: LogProgressProduksi,
      hak: ['SUPA', 'TNK_RPT_PRP'],
    },
    // Laporan RAE
    {
      exact: true,
      path: '/laporan/rae',
      page: LaporanRAE,
      hak: ['SUPA', 'TNK_RPT_RAE'],
    },
    {
      exact: true,
      path: '/laporan/rae/:id',
      page: DetailLaporanRAE,
      hak: ['SUPA', 'TNK_RPT_RAE'],
    },
    {
      exact: true,
      path: '/laporan/monitoring-order',
      page: MonitoringOrder,
      hak: ['SUPA', 'TNK_RPT_MO'],
    },
    {
      exact: true,
      path: '/laporan/monitoring-order/detail/:id',
      page: DetailMonitoringOrder,
      hak: ['SUPA', 'TNK_RPT_MO'],
    },
  ],
};
