import Services from '../../services';

class ProgressProduksiApi {
  getPage(params) {
    return Services.get('/log_progress/page', { params });
  }

  getSingle(params) {
    return Services.get('/log_progress/single', { params });
  }
}

export default new ProgressProduksiApi();
