import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import Axios from 'axios';
import { Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import {
  CRUDLayout,
  Input,
  InputSearch,
  SelectSearch,
  CreateButton,
  ActionButton,
  DataStatus,
  CreateModal,
  UpdateModal,
  DeleteModal,
  UpdateButton,
  DeleteButton,
  Select,
  Alert,
  Switch,
  Pagination,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  ExportButton,
} from 'components';
import { MasterItemSubcontApi } from 'api';
import { TableNumber } from 'utilities';
import FileSaver from 'file-saver';
import { useIsGuest } from 'hooks';

const RegistrasiSubkon = ({ setNavbarTitle }) => {
  // Title
  const title = 'Item Subcont';
  const isGuest = useIsGuest();

  // STATE LOADING
  const [isLoading, setIsLoading] = useState(true);

  // STATE SEARCHING
  const [isSearching, setIsSearching] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  // MODALS SHOW STATE
  const [isCreateForm, setIsCreateForm] = useState(false); // MODAL TAMBAH STATE
  const [isUpdateForm, setIsUpdateForm] = useState(false); // MODAL UPDATE STATE
  const [isDeleteData, setIsDeleteData] = useState(false); // MODAL HAPUS STATE

  // STATE DATA SUBKON
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [dataLength, setDataLength] = useState(10);
  const [totalData, setTotalData] = useState('');
  // STATE MENAMPUNG DATA YANG AKAN DI UPDATE
  const [updateData, setUpdateData] = useState([]);
  // STATE MENAMPUNG DATA YANG AKAN DI HAPUS
  const [deleteData, setDeleteData] = useState([]);

  // STATE MENAMPUNG GENERATE KODE ITEM
  const [kodeItem, setKodeItem] = useState([]);
  // STATE MENAMPUNG DATA BUASO SELECT
  const [buasoData, setBuasoData] = useState([]);
  // STATE MENAMPUNG DATA SATUAN SELECT SEARCH
  const [satuanData, setSatuanData] = useState([]);
  // STATE MENAMPUNG DATA KELOMPOK SELECT SEARCH
  const [kelompokData, setKelompokData] = useState([]);

  // ALERT STATE
  const [showAlert, setShowAlert] = useState(false);
  // KONFIGURASI ALERT
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
  });
  const { REACT_APP_FILE_BASE_URL } = process.env;

  // FUNCTION GET DATA DARI SERVER
  const getData = () => {
    setIsLoading(true);
    setIsSearching(false);

    // setShowAlert(false);

    Axios.all([
      MasterItemSubcontApi.getPage(page, dataLength, searchKey),
      MasterItemSubcontApi.getBuaso(),
      MasterItemSubcontApi.getSatuan(),
      MasterItemSubcontApi.getKelompok(),
      MasterItemSubcontApi.generateKodeItem(),
    ])
      .then(
        Axios.spread((res, buaso, satuan, kelompok, kodeItem) => {
          setData(res.data.data);
          setBuasoData(buaso.data.data);
          setSatuanData(satuan.data.data);
          setKelompokData(kelompok.data.data);
          setKodeItem(kodeItem.data.data);
          setTotalPage(res.data.total_page);
          setTotalData(res.data.data_count);
        })
      )
      .catch((err) => {
        setAlertConfig({
          variant: 'danger',
          text: 'Data Tidak Ada',
        });
        setShowAlert(true);
      })
      .finally(() => {
        if (searchKey != '') {
          setAlertConfig({
            variant: 'primary',
            text: `Hasil Pencarian : ${searchKey}`,
          });
          setShowAlert(true);
        }
        setIsLoading(false);
      });
  };

  // FUNCTION CARI DATA DARI SERVER
  const searchData = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const key = searchKey; // SearchKey Value as key

    MasterItemSubcontApi.search(key)
      .then((res) => {
        setData(res.data.data);
        setTotalPage(res.data.total_page);
      })
      .catch((err) => alert(err))
      .finally(() => {
        setIsSearching(true);
        setIsLoading(false);
        setAlertConfig({
          variant: 'primary',
          text: `Hasil Pencarian : ${key}`,
        });
        setShowAlert(true);
      });
  };

  // FUNCTION UBAH SWITCH SHOW/HIDE STATUS
  const changeDataStatus = (status, id) => {
    // setIsLoading(true);
    setShowAlert(false);

    const value = {
      id_item_buaso: id,
    };

    const onLoadedSuccess = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: 'primary',
        text: 'Ubah status data berhasil',
      });
      setShowAlert(true);
    };

    const onLoadedFailed = () => {
      setIsSearching(false);
      setAlertConfig({
        variant: 'danger',
        text: 'Ubah status data gagal',
      });
      setShowAlert(true);
    };

    status === true
      ? MasterItemSubcontApi.show(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData())
      : MasterItemSubcontApi.hide(value)
          .then(() => onLoadedSuccess())
          .catch(() => onLoadedFailed())
          .finally(() => getData());
  };

  // ON COMPONENT MOUNT
  useEffect(() => {
    setNavbarTitle(title); // SET JUDUL NAVBAR
    getData(); // GET DATA DARI SERVER

    return () => {
      setIsLoading(false);
      setIsSearching(false);
    };
  }, [setNavbarTitle, page, dataLength, searchKey]);

  // FORMIK VALIDATION SCHEMA DENGAN YUP VALIDATION
  const formValidationSchema = Yup.object().shape({
    nama_item: Yup.string()
      // .test("checkNama", "Nama tidak dapat digunakan", (value) =>
      //   RegItemSubkonApi.single("nama_item", value)
      //     .then(() => false)
      //     .catch(() => true)
      // )
      .required('Masukkan Nama Item Subcont'),
    // keterangan: Yup.string().required("Masukkan Keterangan"),
    id_satuan: Yup.string().required('Pilih Satuan'),
    id_kelompok: Yup.string().required('Pilih Kelompok'),
  });

  // MODAL TAMBAH COMPONENT
  const TambahModal = () => {
    // FORMIK INITIAL VALUES
    const formInitialValues = {
      id_buaso: '4', // SET ID BUASO SUBKON
      kode_item: kodeItem,
      nama_item: '',
      keterangan: '',
      id_satuan: '',
      id_kelompok: '',
    };

    // KIRIM DATA BARU KE SERVER
    const formSubmitHandler = (values) => {
      setIsCreateForm(false);

      MasterItemSubcontApi.create(values)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Tambah data berhasil!',
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Tambah data gagal! (${err.response.data.message})`,
            // text: `Tambah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsCreateForm(false);
          // TAMPILKAN ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <CreateModal
        show={isCreateForm}
        onHide={() => setIsCreateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="BUASO"
                  name="id_buaso"
                  onChange={handleChange}
                  errorText={errors.id_buaso}
                  defaultValue={values.id_buaso} // ISI DEFAULT VALUE DENGAN id_buaso yang memiliki value bahan
                  disabled={true}
                >
                  {/* OPTIONS */}
                  {buasoData.map((e) => (
                    <option key={e.id_buaso} value={e.id_buaso}>
                      {e.kode_buaso} - {e.nama_buaso}
                    </option>
                  ))}
                </Select>

                <Input
                  label="Kode Subcont"
                  type="text"
                  name="kode_item"
                  placeholder="Kode Subcont"
                  value={values.kode_item}
                  // readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Nama Item Subcont"
                  type="text"
                  name="nama_item"
                  placeholder="Item Subcont"
                  value={values.nama_item}
                  onChange={handleChange}
                  error={errors.nama_item && touched.nama_item && true}
                  errorText={errors.nama_item}
                />

                <SelectSearch
                  label="Kelompok Subcont"
                  name="id_kelompok"
                  placeholder="Pilih Kelompok Subcont"
                  onChange={(val) => {
                    setFieldValue('id_kelompok', val.value);
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={kelompokData.map((val) => {
                    return {
                      value: val.id_kelompok,
                      label: val.nama_kelompok,
                    };
                  })}
                  defaultValue=""
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={
                    errors.id_kelompok &&
                    touched.id_kelompok &&
                    errors.id_kelompok
                  }
                />

                <SelectSearch
                  label="Satuan Pakai"
                  name="id_satuan"
                  placeholder="Pilih Satuan Pakai"
                  onChange={(val) => {
                    setFieldValue('id_satuan', val.value);
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={satuanData.map((val) => {
                    return {
                      value: val.id_satuan,
                      label: val.nama_satuan,
                    };
                  })}
                  defaultValue="" // default value harus berupa: {{ label: 'masukan label', value: 'masukan value' }}
                  error={errors.id_satuan && touched.id_satuan && true}
                  errorText={
                    errors.id_satuan && touched.id_satuan && errors.id_satuan
                  }
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="primary"
                  text="Tambah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </CreateModal>
    );
  };

  // MODAL UBAH COMPONENT
  const UbahModal = () => {
    const formInitialValues = {
      id_buaso: updateData.id_buaso, // SET ID BUASO UPAH
      kode_item: updateData.kode_item,
      nama_item: updateData.nama_item,
      keterangan: updateData.keterangan,
      id_satuan: updateData.id_satuan,
      id_kelompok: updateData.id_kelompok,
    };

    // KIRIM UPDATE DATA KE SERVER
    const formSubmitHandler = (values) => {
      const finalValues = {
        id_item_buaso: updateData.id_item_buaso,
        ...values,
      };
      MasterItemSubcontApi.update(finalValues)
        .then((res) => {
          // SUCCESS ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Ubah data berhasil!',
          });
        })
        .catch((err) => {
          // const errMsg = Object.values(err.response?.data?.error) ?? []
          // ERROR ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Ubah data gagal! (${err.response.data.message})`,
            // text: `Ubah data gagal! <ul> ${errMsg.map(e => `<li>${e}</li>`).join("")} </ul>`
          });
        })
        .finally(() => {
          // CLOSE UPDATE MODAL FORM
          setIsUpdateForm(false);
          // SHOW ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <UpdateModal
        show={isUpdateForm}
        onHide={() => setIsUpdateForm(false)}
        title={title}
      >
        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({
            values,
            errors,
            touched,
            isSubmitting,
            handleChange,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Select
                  label="BUASO"
                  name="id_buaso"
                  onChange={handleChange}
                  errorText={errors.id_buaso}
                  defaultValue={values.id_buaso} // ISI DEFAULT VALUE DENGAN id_buaso yang memiliki value bahan
                  disabled={true}
                >
                  {/* OPTIONS */}
                  {buasoData.map((e) => (
                    <option key={e.id_buaso} value={e.id_buaso}>
                      {e.kode_buaso} - {e.nama_buaso}
                    </option>
                  ))}
                </Select>

                <Input
                  label="Kode Subcont"
                  type="text"
                  name="kode_item"
                  placeholder="Kode Subcont"
                  value={values.kode_item}
                  // readOnly={true}
                  onChange={handleChange}
                />

                <Input
                  label="Nama Item Subcont"
                  type="text"
                  name="nama_item"
                  placeholder="Nama Item Subcont"
                  value={values.nama_item}
                  onChange={handleChange}
                  error={errors.nama_item && touched.nama_item && true}
                  errorText={errors.nama_item}
                />

                <SelectSearch
                  label="Kelompok Subcont"
                  name="id_kelompok"
                  placeholder="Pilih Kelompok"
                  onChange={(val) => {
                    setFieldValue('id_kelompok', val.value);
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={kelompokData.map((val) => {
                    return {
                      value: val.id_kelompok,
                      label: val.nama_kelompok,
                    };
                  })}
                  defaultValue={{
                    value: updateData.id_kelompok ?? '',
                    label:
                      updateData.id_kelompok === ''
                        ? 'Pilih Kelompok'
                        : kelompokData.find(
                            (val) => val.id_kelompok === updateData.id_kelompok
                          )?.nama_kelompok ?? 'Pilih Kelompok',
                  }}
                  error={errors.id_kelompok && touched.id_kelompok && true}
                  errorText={
                    errors.id_kelompok &&
                    touched.id_kelompok &&
                    errors.id_kelompok
                  }
                />

                <SelectSearch
                  label="Satuan Pakai"
                  name="id_satuan"
                  placeholder="Pilih Satuan Pakai"
                  onChange={(val) => {
                    setFieldValue('id_satuan', val.value);
                  }}
                  // MAPPING OPTIONS
                  // OPTIONS BERUPA VALUE DAN LABEL
                  option={satuanData.map((val) => {
                    return {
                      value: val.id_satuan,
                      label: val.nama_satuan,
                    };
                  })}
                  defaultValue={{
                    value: updateData.id_satuan ?? '',
                    label:
                      updateData.id_satuan === ''
                        ? 'Pilih Satuan'
                        : satuanData.find(
                            (val) => val.id_satuan === updateData.id_satuan
                          )?.nama_satuan ?? 'Pilih Satuan',
                  }}
                  error={errors.id_satuan && touched.id_satuan && true}
                  errorText={
                    errors.id_satuan && touched.id_satuan && errors.id_satuan
                  }
                />

                <Input
                  label="Keterangan"
                  type="text"
                  name="keterangan"
                  placeholder="Keterangan"
                  value={values.keterangan}
                  onChange={handleChange}
                  error={errors.keterangan && touched.keterangan && true}
                  errorText={errors.keterangan}
                />
              </Modal.Body>
              <Modal.Footer>
                <ActionButton
                  type="submit"
                  variant="success"
                  text="Ubah"
                  className="mt-2 px-4"
                  loading={isSubmitting}
                />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </UpdateModal>
    );
  };

  // MODAL HAPUS COMPONENT
  const HapusModal = () => {
    // SET DATA ID YANG DIHAPUS
    const deleteValue = { id_item_buaso: deleteData.id_item_buaso };

    // MENANGANI DELETE BUTTON LOADING
    const [btnLoading, setBtnLoading] = useState(false);

    // DELETE DATA DARI SERVER
    const deleteDataHandler = () => {
      setBtnLoading(true);

      MasterItemSubcontApi.delete(deleteValue)
        .then(() => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'primary',
            text: 'Hapus data berhasil!',
          });
        })
        .catch((err) => {
          // KONFIGURASI ALERT
          setAlertConfig({
            variant: 'danger',
            text: `Hapus data gagal!(${err.response.data.message})`,
          });
        })
        .finally(() => {
          // CLOSE MODAL
          setIsDeleteData(false);
          // TAMPIL ALERT
          setShowAlert(true);
          // FETCH DATA DARI SERVER
          getData();
        });
    };

    return (
      <DeleteModal
        show={isDeleteData}
        onHide={() => setIsDeleteData(false)}
        loading={btnLoading}
        onConfirm={deleteDataHandler}
        title={title}
      >
        <div>Kode Subcont : {deleteData.kode_item}</div>
        <div>Nama Item Subcont : {deleteData.nama_item}</div>
      </DeleteModal>
    );
  };

  // TABLE COMPONENT
  const Table = () => {
    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              {!isGuest && <ThFixed>Aksi</ThFixed>}
              <Th>Kode</Th>
              <Th>Nama Item Subcont</Th>
              <Th>Kelompok Subcont</Th>
              <Th>Satuan Pakai</Th>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={val.id_item_buaso}>
                  <TdFixed>{TableNumber(page, dataLength, index)}</TdFixed>
                  {!isGuest && (
                    <TdFixed>
                      <div className="d-flex justify-content-center">
                        <UpdateButton
                          onClick={() => {
                            setUpdateData(val);
                            setIsUpdateForm(true);
                          }}
                        />
                        <DeleteButton
                          onClick={() => {
                            setDeleteData(val);
                            setIsDeleteData(true);
                          }}
                        />

                        <Switch
                          id={(index + 1).toString()}
                          checked={val.is_hidden ? false : true}
                          onChange={() =>
                            changeDataStatus(val.is_hidden, val.id_item_buaso)
                          }
                        />
                      </div>
                    </TdFixed>
                  )}
                  <TdFixed>{val.kode_item}</TdFixed>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_kelompok}</Td>
                  <Td>{val.nama_satuan}</Td>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
        {!isSearching && (
          <Pagination
            dataLength={dataLength}
            dataPage={
              totalData <= 10
                ? data.length
                : data.map((res, index) => {
                    if (index == data.length - 1) {
                      return TableNumber(page, dataLength, index);
                    }
                  })
            }
            dataNumber={data.map((res, index) => {
              if (index == 0) {
                return TableNumber(page, dataLength, index);
              }
            })}
            dataCount={totalData}
            onDataLengthChange={(e) => {
              setDataLength(e.target.value);
              setPage(1);
            }}
            currentPage={page}
            totalPage={totalPage}
            onPaginationChange={({ selected }) => setPage(selected + 1)}
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="d-flex mb-3">
            <InputSearch
              onChange={(e) => {
                setTimeout(() => {
                  setSearchKey(e.target.value);
                  setPage(1);
                }, 1000);
              }}
              onSubmit={(e) => e.preventDefault()}
            />
          </div>
        </CRUDLayout.HeadSearchSection>

        {/* Button Section */}
        <CRUDLayout.HeadButtonSection>
          {/* Button Export */}
          <ExportButton
            onClick={() => {
              setIsLoading(true);
              MasterItemSubcontApi.export()
                .then((val) => {
                  const urlFile = REACT_APP_FILE_BASE_URL + val.data.file;
                  FileSaver.saveAs(urlFile, val.data.file);
                })
                .catch((res) => alert(res))
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
          {/* Button Tambah */}
          <CreateButton onClick={() => setIsCreateForm(true)} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* Alert */}
      <Alert
        show={showAlert}
        showCloseButton={true}
        variant={alertConfig.variant}
        text={alertConfig.text}
        onClose={() => setShowAlert(false)}
      />

      {isLoading ? (
        <DataStatus loading={isLoading} text="Memuat Data" />
      ) : !Array.isArray(data) ? (
        <DataStatus text="Tidak dapat memuat data" />
      ) : data.length > 0 ? (
        <Table />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {/* MODAL */}
      <TambahModal />
      <UbahModal />
      <HapusModal />
    </CRUDLayout>
  );
};

export default RegistrasiSubkon;
