import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import {
  IoCalendarOutline,
  IoCheckmark,
  IoChevronBackOutline,
  IoAlertOutline,
  IoCloseOutline,
  IoAddOutline,
} from 'react-icons/io5';
import { BackButton, DataStatus } from 'components';
import { DateConvert } from 'utilities';
import { ProgressProduksiApi } from 'api';

const LogProgressProduksi = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { no_peluang, baseline } = useLocation().state;
  const [dataProgress, setDataProgress] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // const dataProgress = [
  //   {
  //     type: 'date',
  //     label: '2020-01-01'
  //   },
  //   {
  //     type: 'success',
  //     label: 'Progress 1 ini test judul yang panjang gatau gimana jadinya tapi dicoba aja dulu semoga aja mau ga kenapa kenapa',
  //     date: '01:00',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.'
  //   },
  //   {
  //     type: 'warning',
  //     label: 'Progress 2',
  //     date: '02:00',
  //     text: 'Ini text dari progress 2'
  //   },
  //   {
  //     type: 'success',
  //     label: 'Progress 3',
  //     date: '03:00',
  //     text: 'Ini text dari progress 3'
  //   },
  //   {
  //     type: 'date',
  //     label: '2020-01-02'
  //   },
  //   {
  //     type: 'danger',
  //     label: 'Progress 4',
  //     date: '04:00',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  //   },
  //   {
  //     type: 'warning',
  //     label: 'Progress 5',
  //     date: '05:00',
  //     text: 'Ini text dari progress 5'
  //   },
  //   {
  //     type: 'success',
  //     label: 'Progress 6',
  //     date: '06:00',
  //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
  //   },
  // ]

  const getInitialData = () => {
    const getKeteranganLog = (
      status,
      keterangan,
      nomor,
      referensi,
      nama_referensi,
      nama
    ) => {
      if (status?.toUpperCase() === 'VER') {
        return `${keterangan} no. ${nomor} verifikasi oleh ${nama}.`;
      }

      if (status?.toUpperCase() === 'APP') {
        return `${keterangan} no. ${nomor} diapprove oleh ${nama}.`;
      }

      if (status?.toUpperCase() === 'REV') {
        return `${keterangan} no. ${nomor} diperiksa oleh ${nama} dan diminta untuk direvisi.`;
      }

      if (status?.toUpperCase() === 'REJ') {
        return `${keterangan} no. ${nomor} direject oleh ${nama}.`;
      }

      return `${keterangan} no. ${nomor} didaftarkan oleh ${nama}${
        referensi
          ? ` dengan referensi ${nama_referensi} no. ${referensi}.`
          : '.'
      }`;
    };

    ProgressProduksiApi.getSingle({ no_transaksi: no_peluang, baseline })
      .then((res) => {
        const { data } = res.data;
        let log = [];
        data.map((val) => {
          const tanggal = val.tanggal;
          const logData = val.log;

          log.push({
            type: 'date',
            label: tanggal,
          });

          logData.map((val) => {
            log.push({
              type: val.status_approval,
              label: val.keterangan_transaksi ?? '-',
              date: val.jam ?? '',
              text: getKeteranganLog(
                val.status_approval,
                val.keterangan_transaksi,
                val.no_transaksi,
                val.no_transaksi_ref,
                val.keterangan_transaksi_ref,
                val.nama_karyawan
              ),
            });

            setDataProgress(log);
          });
        });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setNavbarTitle('Progress Produksi');
    getInitialData();
  }, []);

  const StatusDate = ({ label, line }) => {
    const Icon = () => (
      <div
        className="d-flex justify-content-center align-items-center bg-white border border-secondary rounded-circle"
        style={{
          minHeight: '30px',
          minWidth: '30px',
          maxHeight: '30px',
          maxWidth: '30px',
          zIndex: 100,
        }}
      >
        <IoCalendarOutline className="text-secondary" />
      </div>
    );

    const Label = () => (
      <div
        className="bg-primary text-white ml-2 py-1 px-3 rounded-pill"
        style={{
          width: 'fit-content',
          fontSize: '14px',
        }}
      >
        {label}
      </div>
    );

    const Line = () => (
      <div
        style={{
          width: '1px',
          height: '100%',
          position: 'absolute',
          left: 14,
          top: 30,
          background: '#0012',
          zIndex: 10,
        }}
      />
    );

    return (
      <div style={{ position: 'relative' }} className="my-3">
        <div className="d-flex">
          <Icon />
          <Label />
        </div>
        {line && <Line />}
      </div>
    );
  };

  const StatusPrimary = ({ label, time, text, line }) => {
    const Icon = () => (
      <div
        className="d-flex justify-content-center align-items-center border bg-white border-primary rounded-circle"
        style={{
          minHeight: '30px',
          minWidth: '30px',
          maxHeight: '30px',
          maxWidth: '30px',
          zIndex: 100,
        }}
      >
        <IoAddOutline className="text-primary" />
      </div>
    );

    const TextBox = () => (
      <>
        {/* Triangle */}
        <div style={{ position: 'relative' }}>
          <div
            className="bg-white"
            style={{
              width: '5px',
              height: '6px',
              position: 'absolute',
              top: 12,
              left: 6,
            }}
          >
            <IoChevronBackOutline
              fontSize={10}
              style={{
                color: '#0012',
                position: 'absolute',
                top: -1.4,
                left: -2,
              }}
            />
          </div>
        </div>

        {/* Wrapper Text Box */}
        <div
          className="d-flex flex-column py-1 px-3 border rounded"
          style={{
            width: '100%',
            fontSize: '14px',
            marginLeft: '10px',
          }}
        >
          {/* Label */}
          <div
            className="d-flex justify-content-between"
            style={{ fontWeight: 1000 }}
          >
            <b>{label}</b>
            <b>{time}</b>
          </div>

          {/* Text */}
          <div
            className="py-1"
            style={{
              fontSize: 14,
              opacity: 0.8,
            }}
          >
            {text}
          </div>
        </div>
      </>
    );

    const Line = () => (
      <div
        style={{
          width: '1px',
          height: '100%',
          position: 'absolute',
          left: 14,
          top: 30,
          background: '#0012',
          zIndex: 10,
        }}
      />
    );

    return (
      <div style={{ position: 'relative' }} className="my-3">
        <div className="d-flex">
          <Icon />
          <TextBox />
        </div>
        {line && <Line />}
      </div>
    );
  };

  const StatusSuccess = ({ label, time, text, line }) => {
    const Icon = () => (
      <div
        className="d-flex justify-content-center align-items-center border bg-white border-success rounded-circle"
        style={{
          minHeight: '30px',
          minWidth: '30px',
          maxHeight: '30px',
          maxWidth: '30px',
          zIndex: 100,
        }}
      >
        <IoCheckmark className="text-success" />
      </div>
    );

    const TextBox = () => (
      <>
        {/* Triangle */}
        <div style={{ position: 'relative' }}>
          <div
            className="bg-white"
            style={{
              width: '5px',
              height: '6px',
              position: 'absolute',
              top: 12,
              left: 6,
            }}
          >
            <IoChevronBackOutline
              fontSize={10}
              style={{
                color: '#0012',
                position: 'absolute',
                top: -1.4,
                left: -2,
              }}
            />
          </div>
        </div>

        {/* Wrapper Text Box */}
        <div
          className="d-flex flex-column py-1 px-3 border rounded"
          style={{
            width: '100%',
            fontSize: '14px',
            marginLeft: '10px',
          }}
        >
          {/* Label */}
          <div
            className="d-flex justify-content-between"
            style={{ fontWeight: 1000 }}
          >
            <b>{label}</b>
            <b>{time}</b>
          </div>

          {/* Text */}
          <div
            className="py-1"
            style={{
              fontSize: 14,
              opacity: 0.8,
            }}
          >
            {text}
          </div>
        </div>
      </>
    );

    const Line = () => (
      <div
        style={{
          width: '1px',
          height: '100%',
          position: 'absolute',
          left: 14,
          top: 30,
          background: '#0012',
          zIndex: 10,
        }}
      />
    );

    return (
      <div style={{ position: 'relative' }} className="my-3">
        <div className="d-flex">
          <Icon />
          <TextBox />
        </div>
        {line && <Line />}
      </div>
    );
  };

  const StatusWarning = ({ label, time, text, line }) => {
    const Icon = () => (
      <div
        className="d-flex justify-content-center align-items-center bg-white border border-warning rounded-circle"
        style={{
          minHeight: '30px',
          minWidth: '30px',
          maxHeight: '30px',
          maxWidth: '30px',
          zIndex: 100,
        }}
      >
        <IoAlertOutline className="text-warning" />
      </div>
    );

    const TextBox = () => (
      <>
        {/* Triangle */}
        <div style={{ position: 'relative' }}>
          <div
            className="bg-white"
            style={{
              width: '5px',
              height: '6px',
              position: 'absolute',
              top: 12,
              left: 6,
            }}
          >
            <IoChevronBackOutline
              fontSize={10}
              style={{
                color: '#0012',
                position: 'absolute',
                top: -1.4,
                left: -2,
              }}
            />
          </div>
        </div>

        {/* Wrapper Text Box */}
        <div
          className="d-flex flex-column py-1 px-3 border rounded"
          style={{
            width: '100%',
            fontSize: '14px',
            marginLeft: '10px',
          }}
        >
          {/* Label */}
          <div
            className="d-flex justify-content-between"
            style={{ fontWeight: 1000 }}
          >
            <b>{label}</b>
            <b>{time}</b>
          </div>

          {/* Text */}
          <div
            className="py-1"
            style={{
              fontSize: 14,
              opacity: 0.8,
            }}
          >
            {text}
          </div>
        </div>
      </>
    );

    const Line = () => (
      <div
        style={{
          width: '1px',
          height: '100%',
          position: 'absolute',
          left: 14,
          top: 30,
          background: '#0012',
          zIndex: 10,
        }}
      />
    );

    return (
      <div style={{ position: 'relative' }} className="my-3">
        <div className="d-flex">
          <Icon />
          <TextBox />
        </div>
        {line && <Line />}
      </div>
    );
  };

  const StatusDanger = ({ label, time, text, line }) => {
    const Icon = () => (
      <div
        className="d-flex justify-content-center align-items-center bg-white border border-danger rounded-circle"
        style={{
          minHeight: '30px',
          minWidth: '30px',
          maxHeight: '30px',
          maxWidth: '30px',
          zIndex: 100,
        }}
      >
        <IoCloseOutline className="text-danger" />
      </div>
    );

    const TextBox = () => (
      <>
        {/* Triangle */}
        <div style={{ position: 'relative' }}>
          <div
            className="bg-white"
            style={{
              width: '5px',
              height: '6px',
              position: 'absolute',
              top: 12,
              left: 6,
            }}
          >
            <IoChevronBackOutline
              fontSize={10}
              style={{
                color: '#0012',
                position: 'absolute',
                top: -1.4,
                left: -2,
              }}
            />
          </div>
        </div>

        {/* Wrapper Text Box */}
        <div
          className="d-flex flex-column py-1 px-3 border rounded"
          style={{
            width: '100%',
            fontSize: '14px',
            marginLeft: '10px',
          }}
        >
          {/* Label */}
          <div
            className="d-flex justify-content-between"
            style={{ fontWeight: 1000 }}
          >
            <b>{label}</b>
            <b>{time}</b>
          </div>

          {/* Text */}
          <div
            className="py-1"
            style={{
              fontSize: 14,
              opacity: 0.8,
            }}
          >
            {text}
          </div>
        </div>
      </>
    );

    const Line = () => (
      <div
        style={{
          width: '1px',
          height: '100%',
          position: 'absolute',
          left: 14,
          top: 30,
          background: '#0012',
          zIndex: 10,
        }}
      />
    );

    return (
      <div style={{ position: 'relative' }} className="my-3">
        <div className="d-flex">
          <Icon />
          <TextBox />
        </div>
        {line && <Line />}
      </div>
    );
  };

  const Content = () =>
    dataProgress.map((val, index) => {
      return val?.type?.toUpperCase() === 'DATE' ? (
        <StatusDate
          key={index}
          label={DateConvert(new Date(val.label)).detail}
          line={dataProgress.length - 1 === index ? false : true}
        />
      ) : val?.type?.toUpperCase() === 'APP' ||
        val?.type?.toUpperCase() === 'VER' ? (
        <StatusSuccess
          key={index}
          label={val.label}
          time={val.date}
          text={val.text}
          line={dataProgress.length - 1 === index ? false : true}
        />
      ) : val?.type?.toUpperCase() === 'REV' ? (
        <StatusWarning
          key={index}
          label={val.label}
          time={val.date}
          text={val.text}
          line={dataProgress.length - 1 === index ? false : true}
        />
      ) : val?.type?.toUpperCase() === 'REJ' ? (
        <StatusDanger
          key={index}
          label={val.label}
          time={val.date}
          text={val.text}
          line={dataProgress.length - 1 === index ? false : true}
        />
      ) : (
        <StatusPrimary
          key={index}
          label={val.label}
          time={val.date}
          text={val.text}
          line={dataProgress.length - 1 === index ? false : true}
        />
      );
    });

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b>Progress Produksi</b>
        <BackButton size="sm" onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        {isLoading ? <DataStatus loading text="Memuat . . ." /> : <Content />}
      </Card.Body>
    </Card>
  );
};

export default LogProgressProduksi;
