import React, { useState, useEffect, useContext } from 'react';
import { Modal, Spinner, ButtonGroup } from 'react-bootstrap';
import {
  IoTrashOutline,
  IoPencilOutline,
  IoOptionsOutline,
  IoAddOutline,
  IoCloseOutline,
  IoCheckmark,
} from 'react-icons/io5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  SelectSearch,
  InputCurrency,
  ActionButton,
  Td,
} from 'components';
import { RupiahConvert } from 'utilities';
import { ModalPrelim } from '../Section';
import AnalisaBarangJadiRAE from '../AnalisaBarangJadiRAE';
import AnalisaBarangJadiRAEDetail from '../AnalisaBarangJadiRAEDetail';
import { RAEContext, DropdownRAEContext } from '../RAEContext';

const TableBarangJadi = ({
  dataBarangJadi,
  setDataBarangJadi,
  grandTotal,
  dataPeluang,
}) => {
  const {
    setIndexAnalisa,
    setDataPrelim,
    setDataAnalisaBarangJadi,
    dataAnalisaBarangJadi,
    type: TYPE,
  } = useContext(RAEContext);
  const { dropdown, loading } = useContext(DropdownRAEContext);

  const [processedData, setProcessedData] = useState({});
  const [dataFilterAnalisa, setDataFilterAnalisa] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    title: '',
  });
  const [modalPrelim, setModalPrelim] = useState({
    show: false,
  });

  useEffect(() => {
    const newSelectData = dropdown?.analisa_barang_jadi?.map((val) => ({
      value: val.id_barang_jadi ?? null,
      label: `${val.kode_item ? `${val.kode_item} - ` : ''} ${
        val.nama_item ?? ''
      }`,
      kode: val.kode_item,
      nama: val.nama_item,
      satuan: val.nama_satuan,
      harga: val.total_analisa,
      nama_pengaju: val.nama_pengaju,
    }));

    setDataFilterAnalisa(newSelectData);
  }, [dropdown.analisa_barang_jadi]);

  const FormSection = ({ type }) => {
    const formInitialValues = {
      kode_item: type === 'update' ? processedData.kode_item : '',
      nama_item: type === 'update' ? processedData.nama_item : '',
      id_barang_jadi: type === 'update' ? processedData.id_barang_jadi : '',
      nama_pengaju: type === 'update' ? processedData.nama_pengaju : '',
      qty: type === 'update' ? processedData.qty : 0,
      satuan: type === 'update' ? processedData.satuan : '',
      harga_satuan: type === 'update' ? processedData.harga_satuan : 0,
      ref_uid: type === 'update' ? processedData.ref_uid : '',
    };

    const formValidationSchema = Yup.object().shape({
      id_barang_jadi: Yup.string().required(),
      qty: Yup.string().required(),
    });

    const formSubmitHandler = (values, { setSubmitting }) => {
      let newData = [];

      if (type === 'update') {
        const update = dataBarangJadi.map((val, index) =>
          index === processedData.index ? values : val
        );
        newData = update;
      } else {
        newData = [...dataBarangJadi, values];
      }

      setTimeout(() => {
        setAlertConfig({
          show: true,
          variant: 'primary',
          text: 'Data berhasil ditambah!',
        });

        type === 'update'
          ? setDataBarangJadi(newData)
          : setDataBarangJadi(newData);
        setIsEdit(false);
        setSubmitting(false);
        setProcessedData({});
      }, 300);
    };

    const formik = useFormik({
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const {
      values,
      errors,
      touched,
      setValues,
      setFieldValue,
      handleSubmit,
      isSubmitting,
    } = formik;

    return (
      <tr>
        <Td colSpan={4}>
          <SelectSearch
            noMargin
            placeholder="Pilih barang jadi"
            loading={loading?.analisa_barang_jadi}
            option={dataFilterAnalisa}
            defaultValue={
              values.id_barang_jadi
                ? dataFilterAnalisa?.find(
                    (val) => val.value === values.id_barang_jadi
                  )
                : null
            }
            onChange={(val) => {
              setValues({
                ...values,
                kode_item: val.kode,
                nama_item: val.nama,
                id_barang_jadi: val.value,
                satuan: val.satuan,
                harga_satuan: val.harga,
                nama_pengaju: val.nama_pengaju,
              });
            }}
            error={Boolean(errors.id_barang_jadi && touched.id_barang_jadi)}
          />
        </Td>
        <Td width={80}>
          <InputCurrency
            noMargin
            prefix=""
            placeholder="Qty"
            decimalScale={2}
            value={parseFloat(values.qty)}
            onChange={(value) => setFieldValue('qty', value)}
            error={Boolean(errors.qty && touched.qty)}
          />
        </Td>
        <Td>{values.satuan ?? '-'}</Td>
        <Td textRight>
          {RupiahConvert(String(parseInt(values.harga_satuan))).detail}
        </Td>
        <Td textRight>
          {
            RupiahConvert(
              String(
                parseInt(
                  parseFloat(values.qty ? values.qty : 0) *
                    parseFloat(values.harga_satuan)
                )
              )
            ).detail
          }
        </Td>
        <Td>
          {type === 'update' ? (
            <ButtonGroup style={{ width: '100%' }}>
              <ActionButton
                size="sm"
                variant="outline-danger"
                onClick={() => {
                  setIsEdit(false);
                  setProcessedData({});
                }}
                disable={isSubmitting}
              >
                <IoCloseOutline />
              </ActionButton>
              <ActionButton
                size="sm"
                variant="outline-success"
                onClick={handleSubmit}
                disable={isSubmitting}
              >
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <IoCheckmark />
                )}
              </ActionButton>
            </ButtonGroup>
          ) : (
            <ActionButton
              size="sm"
              className="col"
              onClick={handleSubmit}
              disable={isSubmitting}
            >
              {isSubmitting ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <IoAddOutline />
              )}
            </ActionButton>
          )}
        </Td>
      </tr>
    );
  };

  const TableData = ({ val, index }) => {
    const checkQty = val?.qty ? parseFloat(val.qty) : 0;
    const checkSatuan = val?.harga_satuan ? parseInt(val.harga_satuan) : 0;
    const subTotal = parseInt(checkQty * checkSatuan);

    const StatusAnalisaButton = ({ is_edit = false }) => {
      return (
        <ActionButton
          disable
          variant={is_edit ? 'outline-success' : 'outline-danger'}
        >
          {is_edit ? (
            <IoCheckmark fontSize={12} />
          ) : (
            <IoCloseOutline fontSize={12} />
          )}
        </ActionButton>
      );
    };

    return isEdit && index === processedData.index ? (
      <FormSection type="update" />
    ) : (
      <tr key={index}>
        <td>{val.kode_item}</td>
        <td>{val?.nama_pengaju ?? '-'}</td>
        <td>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setProcessedData({
                ...val,
                index: index,
              });
              setModalConfig({
                show: true,
                type: 'analisa',
                title: (
                  <span className="text-primary">Analisa Barang Jadi RAE</span>
                ),
              });
              setIndexAnalisa(index);
            }}
          >
            {val.nama_item}
          </a>
        </td>
        <td className="text-center">
          <StatusAnalisaButton is_edit={val.is_edit} />
        </td>
        <td className="text-right">{val.qty}</td>
        <td>{val.satuan}</td>
        <td className="text-right">
          {RupiahConvert(parseInt(val.harga_satuan).toString()).detail}
        </td>
        <td className="text-right">
          {RupiahConvert(parseInt(subTotal)?.toString()).detail}
        </td>
        {TYPE !== 'DETAIL' && (
          <td>
            <div className="btn-group p-0">
              <ActionButton
                size="sm"
                text={<IoOptionsOutline />}
                onClick={() => {
                  setProcessedData({
                    ...val,
                    index: index,
                  });
                  setModalConfig({
                    show: true,
                    type: 'analisa',
                    title: (
                      <span className="text-primary">
                        Analisa Barang Jadi RAE
                      </span>
                    ),
                  });
                  setIndexAnalisa(index);
                }}
              />
              <ActionButton
                size="sm"
                variant="success"
                text={<IoPencilOutline />}
                onClick={() => {
                  setIsEdit(true);
                  setProcessedData({
                    ...val,
                    index: index,
                  });
                }}
              />
              <ActionButton
                size="sm"
                variant="danger"
                text={<IoTrashOutline />}
                onClick={() => {
                  setProcessedData({
                    ...val,
                    index: index,
                  });
                  setModalConfig({
                    show: true,
                    type: 'delete',
                    title: <span className="text-danger">Hapus Data</span>,
                  });
                }}
              />
            </div>
          </td>
        )}
      </tr>
    );
  };

  const TableSection = () => {
    return (
      <>
        <div className="py-2 p-1">
          <b>List Barang Jadi</b>
        </div>
        <Alert
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          showCloseButton={true}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        <table
          className="table table-sm table-bordered"
          style={{ fontSize: '14px' }}
        >
          <thead className="text-center bg-light">
            <tr>
              <th style={{ width: '100px' }}>Kode Barang Jadi</th>
              <th className="align-middle">Nama Karyawan Pembuat</th>
              <th className="align-middle" style={{ width: '350px' }}>
                {' '}
                Barang Jadi{' '}
              </th>
              <th style={{ width: '10px' }} className="align-middle">
                Status Analisa
              </th>
              <th className="align-middle">Qty</th>
              <th className="align-middle">Satuan</th>
              <th className="align-middle">Harga Satuan</th>
              <th className="align-middle">Sub Total</th>
              {TYPE !== 'DETAIL' && (
                <th className="align-middle" style={{ width: '30px' }}>
                  Aksi
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {TYPE !== 'DETAIL' && <FormSection type="create" />}
            {dataBarangJadi.map((val, index) =>
              val !== null ? <TableData val={val} index={index} /> : null
            )}
            <tr>
              <td></td>
              <td>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setModalPrelim({ show: true });
                  }}
                >
                  <b>PRELIM</b>
                </a>
              </td>
              <td className="text-right">1</td>
              <td className="text-right" colSpan={4}>
                {RupiahConvert(String(parseInt(grandTotal().prelim))).detail}
              </td>
              <td className="text-right">
                {RupiahConvert(String(parseInt(grandTotal().prelim))).detail}
              </td>
              {TYPE !== 'DETAIL' && (
                <td>
                  <ActionButton
                    size="sm"
                    className="col"
                    text={<IoOptionsOutline />}
                    onClick={() => {
                      setModalPrelim({ show: true });
                    }}
                  />
                </td>
              )}
            </tr>
          </tbody>
          <tfoot>
            {/* Total */}
            <tr className="bg-light">
              <td colSpan={7} className="text-right py-2 align-middle">
                <b>Grand Total :</b>
              </td>
              <td className="text-right align-middle">
                <b>
                  {
                    RupiahConvert(String(parseInt(grandTotal().grandTotal)))
                      .detail
                  }
                </b>
              </td>
              {TYPE !== 'DETAIL' && <td></td>}
            </tr>
          </tfoot>
        </table>
      </>
    );
  };

  const ModalSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          {TYPE !== 'DETAIL' ? (
            <AnalisaBarangJadiRAE
              processedData={processedData}
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              alertConfig={alertConfig}
              modalConfig={modalConfig}
              setAlertConfig={setAlertConfig}
              setModalConfig={setModalConfig}
            />
          ) : (
            <AnalisaBarangJadiRAEDetail
              processedData={processedData}
              dataBarangJadi={dataBarangJadi}
              setDataBarangJadi={setDataBarangJadi}
              alertConfig={alertConfig}
              modalConfig={modalConfig}
              setAlertConfig={setAlertConfig}
              setModalConfig={setModalConfig}
            />
          )}
        </Modal.Body>
      );
    };

    const DeleteModal = () => {
      const [isDeleting, setIsDeleting] = useState(false);

      const deleteDataHandler = () => {
        const index = processedData.index;

        setIsDeleting(true);

        const finalData = dataBarangJadi.map((val, i) => {
          if (i === index) {
            return null;
          }

          return val;
        });

        const finalDataAnalisa = dataAnalisaBarangJadi.map((val, i) => {
          if (i === index) {
            return null;
          }

          return val;
        });

        setTimeout(() => {
          setDataBarangJadi(finalData);
          setDataAnalisaBarangJadi(finalDataAnalisa);
          setModalConfig({
            ...modalConfig,
            show: false,
          });
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: 'Data berhasil dihapus!',
          });
        }, 300);
      };

      return (
        <>
          <Modal.Body className="text-center">
            <h5>
              <span>Hapus data dengan nama item bahan: </span>
              <br />
              <b>{processedData.nama_item}</b>
            </h5>
            <small className="text-danger">
              Data yang dihapus tidak dapat dikembalikan!
            </small>
            <div className="d-flex justify-content-center mt-3">
              <ActionButton
                variant="outline-secondary"
                className="m-1"
                text="Batal"
                onClick={() =>
                  setModalConfig({
                    ...modalConfig,
                    show: false,
                  })
                }
              />
              <ActionButton
                variant="danger"
                className="m-1"
                text="Hapus Data"
                loading={isDeleting}
                onClick={deleteDataHandler}
              />
            </div>
          </Modal.Body>
        </>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === 'delete' ? 'md' : 'xl'}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        {modalConfig.type === 'delete' ? (
          <DeleteModal />
        ) : (
          <AnalisaBarangJadiModal
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            setAlertConfig={setAlertConfig}
            modalConfig={modalConfig}
            setModalConfig={setModalConfig}
          />
        )}
      </Modal>
    );
  };

  return (
    <>
      <TableSection
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
      />
      <ModalSection
        processedData={processedData}
        dataBarangJadi={dataBarangJadi}
        setDataBarangJadi={setDataBarangJadi}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalConfig}
        setModalConfig={setModalConfig}
      />
      <ModalPrelim
        show={modalPrelim.show}
        onHide={() => setModalPrelim({ show: false })}
        onSubmit={(val) => {
          setDataPrelim(val);
          setModalPrelim({ show: false });
        }}
        dataPeluang={dataPeluang}
      />
    </>
  );
};

export default TableBarangJadi;
