import { useState, useEffect, useContext } from 'react';
import { ButtonGroup, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import {
  IoAddOutline,
  IoCheckmark,
  IoCloseOutline,
  IoPencilOutline,
  IoTrashBinOutline,
} from 'react-icons/io5';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Table,
  Th,
  ThFixed,
  Td,
  TdFixed,
  SelectSearch,
  Input,
  DataStatus,
  ActionButton,
  DeleteModal,
} from 'components';
import { RupiahConvert } from 'utilities';
import { RAEContext, DropdownRAEContext } from '../RAEContext';

const TablePrelim = ({
  dataKelompokPrelim,
  dataPrelim,
  setDataPrelim,
  dataPeluang,
}) => {
  let total = 0;
  const { type: TYPE } = useContext(RAEContext);
  const { dropdown, loading } = useContext(DropdownRAEContext);
  const [dataDropdown, setDataDropdown] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(''); //Menyimpan urutan data yang sedang di olah
  const [updateData, setUpdateData] = useState({}); //Menyimpan data yang sedang diubahh
  const ID_KELOMPOK_PRELIM = dataKelompokPrelim?.id_kelompok;
  const NAMA_KELOMPOK_PRELIM = dataKelompokPrelim?.nama_kelompok;
  const DATA_PRELIM_FILTER = dataPrelim?.filter(
    (val) => val.id_kelompok === ID_KELOMPOK_PRELIM
  );
  const [deleteConfig, setDeleteConfig] = useState({
    show: false,
    submitting: false,
    order: '',
  });

  // Konversi qty agar tidak dapat kurang dari 0 dan menghilangkan unique character
  const qtyConvert = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 10);

    return convert;
  };
  const qtyConvertAnalisa = (value) => {
    const newValue = value.toString().replace(/[^0-9\.]/g, '');
    const convert = newValue.substring(0, 6);

    return convert;
  };

  // Mendapatkan subtotal pada tabel atau form
  const getSubTotal = (qty, harga) => {
    const newQty = qty ? parseFloat(qty) : 0;
    const newHarga = harga ? parseFloat(harga) : 0;

    return newQty * newHarga;
  };

  // Menangani tambah data pada tabel
  const createDataHandler = (data) => {
    const generateOrder = () => {
      const find = dataPrelim.find(
        (val, index) => index === dataPrelim.length - 1
      );
      const lastOrder = find?.order ? find.order : 0;

      return parseInt(lastOrder) + parseInt(1);
    };

    setDataPrelim([
      ...dataPrelim,
      {
        ...data,
        order: generateOrder(),
      },
    ]);
  };

  // Menangani ubah data pada tabel
  const updateDataHandler = (data) => {
    const updateData = dataPrelim.map((val) => {
      if (val.order === selectedOrder) {
        return data;
      }

      return val;
    });

    setDataPrelim(updateData);
    setSelectedOrder('');
  };

  // Menangani hapus data pada tabel
  const deleteDataHandler = (deleted) => {
    setDeleteConfig({ ...deleteConfig, submitting: true });
    const newData = dataPrelim.filter((val) => val.order !== deleted);

    setTimeout(() => {
      setDataPrelim(newData);
      setDeleteConfig({
        show: false,
        order: '',
        submitting: false,
      });
    }, 200);
  };

  // Mapping Data
  const mappingDataDropdown = (data) =>
    data?.map((val) => {
      return {
        value: val.id_item_buaso_prelim ?? null,
        label: val.nama_item ? `${val.kode_item} - ${val.nama_item}` : '-',
        kode: val.kode_item ?? '-',
        nama: val.nama_item ?? '-',
        satuan: val.nama_satuan ?? '-',
        satuan_durasi: val.nama_satuan_waktu,
        harga: val.harga_satuan_prelim ?? 0,
        id_satuan_durasi: val.id_satuan_waktu,
        id: val.id_item_buaso_prelim ?? null,
        id_satuan: val.id_satuan,
      };
    });

  useEffect(() => {
    // Filter dropdown from Dropdwon Context
    const filterDropdown = dropdown?.item_prelim?.filter(
      (v) => v.id_kelompok === ID_KELOMPOK_PRELIM
    );

    setDataDropdown(mappingDataDropdown(filterDropdown ?? []));
  }, [dropdown?.item_prelim]);

  // Tampilah form pada saat tambah / ubah
  const FormTable = ({ type }) => {
    const formInitialValues = {
      id_kelompok: ID_KELOMPOK_PRELIM,
      id_item: type === 'update' ? updateData?.id_item : null,
      id_satuan_durasi: type === 'update' ? updateData?.id_satuan_durasi : null,
      kode_item: type === 'update' ? updateData?.kode_item : null,
      nama_item: type === 'update' ? updateData?.nama_item : null,
      qty_item: type === 'update' ? updateData?.qty_item : 1,
      id_unit: type === 'update' ? updateData?.id_unit : null,
      nama_unit: type === 'update' ? updateData?.nama_unit : '-',
      satuan_durasi: type === 'update' ? updateData?.satuan_durasi : '-',
      harga: type === 'update' ? updateData?.harga : 0,
      qty_durasi:
        type === 'create'
          ? dataPeluang?.schedule_kebutuhan_proyek
            ? dataPeluang.schedule_kebutuhan_proyek
            : 1
          : updateData?.qty_durasi,
      konstanta: type === 'update' ? updateData?.konstanta : 1,
      order: type === 'update' ? updateData?.order : 0,
    };

    const formValidationSchema = Yup.object().shape({
      id_item: Yup.string().required(),
      qty_item: Yup.string()
        .required()
        .test({ test: (val) => val > 0 }),
      harga: Yup.string().required(),
      qty_durasi: Yup.string().required(),
      konstanta: Yup.string().required(),
    });

    const formSubmitHandler = (values, { setSubmitting }) => {
      setTimeout(() => {
        type === 'update'
          ? updateDataHandler(values)
          : createDataHandler(values);
        setSubmitting(false);
      }, 300);
    };

    return (
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidationSchema}
        onSubmit={formSubmitHandler}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setValues,
          handleSubmit,
          isSubmitting,
        }) => (
          <tr>
            <Td></Td>
            {/* Item */}
            <Td colSpan={2}>
              <SelectSearch
                noMargin
                placeholder="Pilih item prelim"
                option={dataDropdown}
                loading={loading.item_prelim}
                defaultValue={
                  values.id_item
                    ? dataDropdown.find((fil) => fil.id === values.id_item)
                    : ''
                }
                menuShouldBlockScroll={true}
                menuPosition="fixed"
                onChange={(val) => {
                  setValues({
                    ...values,
                    id_item: val.id,
                    id_unit: val.id_satuan,
                    id_satuan_durasi: val.id_satuan_durasi,
                    kode_item: val.kode,
                    nama_item: val.nama,
                    harga: parseInt(val.harga),
                    nama_unit: val.satuan,
                    satuan_durasi: val.satuan_durasi,
                  });
                }}
                error={Boolean(errors.id_item && touched.id_item)}
              />
            </Td>

            {/* Qty. item */}
            <Td>
              <OverlayTrigger
                trigger="focus"
                overlay={<Tooltip>{values.qty_item}</Tooltip>}
              >
                <Input
                  noMargin
                  value={values.qty_item}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = qtyConvertAnalisa(value);
                    console.log('qty_prelim', newValue);
                    setFieldValue('qty_item', newValue);
                  }}
                  error={Boolean(errors.qty_item && touched.qty_item)}
                />
              </OverlayTrigger>
            </Td>

            {/* Unit */}
            <Td>{values.nama_unit}</Td>

            {/* Unit Price */}
            <Td>
              <OverlayTrigger
                trigger="focus"
                overlay={
                  <Tooltip>
                    {RupiahConvert(String(values.harga)).detail}
                  </Tooltip>
                }
              >
                <Input
                  noMargin
                  value={RupiahConvert(String(values.harga ?? 0)).detail}
                  onChange={(e) => {
                    const value = e.target.value;
                    const check =
                      RupiahConvert(String(value)).default > 0 ? value : 0;
                    const newValue = RupiahConvert(
                      String(check ?? value)
                    ).default;

                    setFieldValue('harga', newValue);
                  }}
                  error={Boolean(errors.harga && touched.harga)}
                />
              </OverlayTrigger>
            </Td>

            {/* Qty. Durasi */}
            <Td>
              <OverlayTrigger
                trigger="focus"
                overlay={<Tooltip>{values.qty_durasi}</Tooltip>}
              >
                <Input
                  noMargin
                  value={values.qty_durasi}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = qtyConvert(value);
                    setFieldValue('qty_durasi', newValue);
                  }}
                  error={Boolean(errors.qty_durasi && touched.qty_durasi)}
                />
              </OverlayTrigger>
            </Td>

            {/* Sub Total */}
            <Td textRight>
              {
                RupiahConvert(
                  String(getSubTotal(values.qty_item, values.harga))
                ).detail
              }
            </Td>

            {/* Aksi */}
            {TYPE !== 'DETAIL' && (
              <TdFixed className="text-center">
                {type === 'update' ? (
                  <ButtonGroup>
                    {/* Tombol Ubah */}
                    <ActionButton
                      size="sm"
                      variant="outline-success"
                      onClick={handleSubmit}
                    >
                      {isSubmitting ? (
                        <Spinner size="sm" animation="border" />
                      ) : (
                        <IoCheckmark />
                      )}
                    </ActionButton>
                    {/* Tombol Batal */}
                    <ActionButton
                      size="sm"
                      variant="outline-danger"
                      onClick={() => setSelectedOrder('')}
                    >
                      <IoCloseOutline />
                    </ActionButton>
                  </ButtonGroup>
                ) : (
                  <ActionButton
                    size="sm"
                    onClick={handleSubmit}
                    disable={isSubmitting}
                  >
                    {isSubmitting ? (
                      <Spinner size="sm" animation="border" />
                    ) : (
                      <IoAddOutline />
                    )}
                  </ActionButton>
                )}
              </TdFixed>
            )}
          </tr>
        )}
      </Formik>
    );
  };

  return (
    <div>
      <div className="p-1">
        <b>List Item Prelim Kelompok {NAMA_KELOMPOK_PRELIM}</b>
      </div>
      <Table>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th noPadding width={80}>
              Kode Item Prelim
            </Th>
            <Th width={250}>Item Prelim</Th>
            <Th noPadding width={60}>
              Qty. Item
            </Th>
            <Th noPadding width={150}>
              Unit
            </Th>
            <Th noPadding width={150}>
              Unit Price
            </Th>
            <Th noPadding width={100}>
              Qty. Durasi (Hari)
            </Th>
            <Th noPadding width={150}>
              Sub Total
            </Th>
            {TYPE !== 'DETAIL' && <ThFixed>Aksi</ThFixed>}
          </tr>
        </thead>
        <tbody>
          {/* Form pada saat tambah */}
          {TYPE !== 'DETAIL' && (
            <FormTable
              type="create" // create atau update (lowercase!)
            />
          )}

          {/* Looping data tabel */}
          {DATA_PRELIM_FILTER && DATA_PRELIM_FILTER.length > 0 ? (
            DATA_PRELIM_FILTER.map((val, index) => {
              total += getSubTotal(val.qty_item, val.harga);
              return selectedOrder === val.order ? (
                <FormTable type="update" />
              ) : (
                <>
                  <tr>
                    <Td textCenter>{index + 1}</Td>
                    <Td>{val.kode_item}</Td>
                    <Td>{val.nama_item}</Td>
                    <Td textRight>{parseFloat(val.qty_item).toPrecision()}</Td>
                    <Td>{val.nama_unit}</Td>
                    <Td>
                      {
                        RupiahConvert(
                          String(val.harga ? parseFloat(val.harga) : 0)
                        ).detail
                      }
                    </Td>
                    <Td textRight>
                      {parseFloat(val.qty_durasi).toPrecision()}
                    </Td>
                    <Td textRight>
                      {
                        RupiahConvert(
                          String(parseInt(getSubTotal(val.qty_item, val.harga)))
                        ).detail
                      }
                    </Td>
                    {TYPE !== 'DETAIL' && (
                      <Td textCenter>
                        <ButtonGroup>
                          {/* Tombol Ubah */}
                          <ActionButton
                            size="sm"
                            variant="success"
                            onClick={() => {
                              setSelectedOrder(val.order);
                              setUpdateData(val);
                            }}
                          >
                            <IoPencilOutline />
                          </ActionButton>
                          {/* Tombol Batal */}
                          <ActionButton
                            size="sm"
                            variant="danger"
                            onClick={() =>
                              setDeleteConfig({
                                show: true,
                                submitting: false,
                                order: val.order,
                              })
                            }
                          >
                            <IoTrashBinOutline />
                          </ActionButton>
                        </ButtonGroup>
                      </Td>
                    )}
                  </tr>

                  {DATA_PRELIM_FILTER.length === index + 1 && (
                    <tr>
                      <Td textRight colSpan={7}>
                        <b>Total</b>
                      </Td>
                      <Td textRight>
                        {RupiahConvert(String(parseInt(total))).detail}
                      </Td>
                      {TYPE !== 'DETAIL' && <Td />}
                    </tr>
                  )}
                </>
              );
            })
          ) : (
            <tr>
              <Td colSpan={12}>
                <DataStatus text="Tidak ada data" />
              </Td>
            </tr>
          )}
        </tbody>
      </Table>
      <hr />

      {/* Modal Delete */}
      <DeleteModal
        show={deleteConfig.show}
        loading={deleteConfig.submitting}
        onConfirm={() => deleteDataHandler(deleteConfig.order)}
        onHide={() =>
          setDeleteConfig({
            show: false,
            submitting: false,
            order: '',
          })
        }
      />
    </div>
  );
};

export default TablePrelim;
