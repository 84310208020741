import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, Tab, Nav } from 'react-bootstrap';
import Axios from 'axios';
import { Alert, DataStatus, BackButton } from 'components';
import { PermintaanPrelimApi } from 'api';
import {
  TableItemSPK,
  InfoSection,
  ModalPermintaanPrelim,
  Datatable,
} from '../Section';

const TambahPermintaanPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataJO, setDataJO] = useState({});
  const [dataSPK, setDataSPK] = useState([]);
  const [dataAnalisa, setDataAnalisa] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    val: {},
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([PermintaanPrelimApi.getSingleSPK({ id_spk: id })])
      .then(
        Axios.spread(({ data }) => {
          setDataJO(data?.data ?? {});
          setDataSPK(data?.data?.list_item ?? []);
          setDataAnalisa(data?.data.analisa ?? []);
          setDataHistory(data?.data.histori ?? []);
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const getGrandTotalPrelim = dataAnalisa?.reduce(
    (acc, { qty_analisa, unit_price, konstanta }) =>
      acc +
      parseInt(parseFloat(qty_analisa || 0) * parseInt(unit_price || 0)) *
        parseFloat(konstanta || 0),
    0
  );

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Prelim');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, []);

  if (isPageLoading) {
    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b className="text-capitalize">Tambah Data Permintaan Prelim</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <DataStatus loading={true} text="Memuat data . . ." />
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b className="text-capitalize">Tambah Data Permintaan Prelim</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />

          {/* Info section SPK */}
          <InfoSection dataJO={dataJO} />

          {/* List Item SPK */}
          <TableItemSPK
            data={dataSPK}
            diskon={dataJO.diskon}
            ppn={dataJO.ppn}
            totalPrelim={getGrandTotalPrelim}
          />

          {/* show modal tambah permintaan prelim */}
          {modalConfig.show && (
            <ModalPermintaanPrelim
              id={id}
              modalConfig={modalConfig}
              getInitialData={getInitialData}
              setModalConfig={setModalConfig}
              setAlertConfig={setAlertConfig}
              // history={dataHistory}
            />
          )}
        </Card.Body>
      </Card>

      {/* Tab List Item Prelim */}
      <Card className="border mt-3">
        <Tab.Container defaultActiveKey="honor_staff">
          <Card.Header className='className="d-flex justify-content-between align-items-center'>
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="honor_staff">Honor Staff</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="atk">ATK</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="safety">Safety</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="hand_tools">Hand Tools</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="proteksi">Proteksi</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="lain_lain">Lain-lain</Nav.Link>
              </Nav.Item>
            </Nav>
          </Card.Header>

          <Card.Body>
            <Tab.Content>
              <Tab.Pane eventKey="honor_staff">
                <Datatable
                  data={dataAnalisa}
                  history={dataHistory}
                  setModalConfig={setModalConfig}
                  kelompok="Honor Staff"
                  tipe="tambah"
                />
              </Tab.Pane>
              <Tab.Pane eventKey="atk">
                <Datatable
                  data={dataAnalisa}
                  history={dataHistory}
                  setModalConfig={setModalConfig}
                  kelompok="ATK"
                  tipe="tambah"
                />
              </Tab.Pane>
              <Tab.Pane eventKey="safety">
                <Datatable
                  data={dataAnalisa}
                  history={dataHistory}
                  setModalConfig={setModalConfig}
                  kelompok="Safety"
                  tipe="tambah"
                />
              </Tab.Pane>
              <Tab.Pane eventKey="hand_tools">
                <Datatable
                  data={dataAnalisa}
                  history={dataHistory}
                  setModalConfig={setModalConfig}
                  kelompok="Hand Tools"
                  tipe="tambah"
                />
              </Tab.Pane>
              <Tab.Pane eventKey="proteksi">
                <Datatable
                  data={dataAnalisa}
                  history={dataHistory}
                  setModalConfig={setModalConfig}
                  kelompok="Proteksi"
                  tipe="tambah"
                />
              </Tab.Pane>
              <Tab.Pane eventKey="lain_lain">
                <Datatable
                  data={dataAnalisa}
                  history={dataHistory}
                  setModalConfig={setModalConfig}
                  kelompok="Lain-lain"
                  tipe="tambah"
                />
              </Tab.Pane>
            </Tab.Content>
          </Card.Body>
        </Tab.Container>
      </Card>
    </>
  );
};

export default TambahPermintaanPrelim;
