import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Modal, Row, Col, Nav, Card } from 'react-bootstrap';
import {
  IoAddOutline,
  IoEyeOutline,
  IoCloseCircleOutline,
  IoCheckboxOutline,
} from 'react-icons/io5';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { debounce } from 'lodash';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  BackButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  FilterButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { useSessionStorage } from 'hooks';
import { RAEApi } from 'api';
import {
  TabFilePeluang,
  TabInfoPeluang,
  TabGambarPeluang,
} from '../PeluangSection';
import { ModalFilter } from '../components';

const BarangJadiCollapse = ({ data }) => {
  const [isReadMoreClick, setisReadMoreClick] = useState(false);

  return (
    <>
      <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
        {data.map((brg, index) => {
          if (isReadMoreClick) {
            return <li index={index}>{brg.nama_item}</li>;
          } else {
            return index <= 2 && <li index={index}>{brg.nama_item}</li>;
          }
        })}
      </ul>
      {data.length <= 2 ? (
        ''
      ) : (
        <div
          className="text-primary"
          style={{
            cursor: 'pointer',
            textDecoration: 'underline',
            // fontWeight: "bold",
          }}
          onClick={() => {
            setisReadMoreClick((prev) => !prev);
          }}
        >
          {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
        </div>
      )}
    </>
  );
};

const PeluangRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataPeluangRAE, setDataPeluangRAE] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [modalPeluangConfig, setModalPeluangConfig] = useState({
    show: false,
    nomor: '',
  });
  const [textEditorState, setTextEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  const [showFilter, setShowFilter] = useState(false);
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    tgl_peluang_awal: undefined,
    tgl_peluang_akhir: undefined,
    peringkat_peluang: undefined,
    customer: undefined,
    proyek: undefined,
    kelompok_proyek: undefined,
    target_penawaran_awal: undefined,
    target_penawaran_akhir: undefined,
    realisasi_penawaran_awal: undefined,
    realisasi_penawaran_akhir: undefined,
    instalasi: undefined,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    const query = {
      q: pagination.q,
      page: pagination.page,
      per_page: pagination.per_page,

      tgl_peluang_awal: pagination.tgl_peluang_awal,
      tgl_peluang_akhir: pagination.tgl_peluang_akhir,
      peringkat_peluang: pagination.peringkat_peluang,
      customer: pagination.customer,
      proyek: pagination.proyek,
      kelompok_proyek: pagination.kelompok_proyek,
      target_penawaran_awal: pagination.target_penawaran_awal,
      target_penawaran_akhir: pagination.target_penawaran_akhir,
      realisasi_penawaran_awal: pagination.realisasi_penawaran_awal,
      realisasi_penawaran_akhir: pagination.realisasi_penawaran_akhir,
      instalasi: pagination.instalasi,
    };

    RAEApi.getPeluang(query)
      .then((data) => {
        setDataPeluangRAE(data?.data?.data ?? []);
        setPagination((prev) => ({
          ...prev,
          data_count: data?.data?.data_count,
          total_page: data?.data?.total_page,
        }));
      })
      .catch((err) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  const checkAlert = () => {
    const locationState = location.state;
    if (locationState?.alert) {
      setAlertConfig(locationState?.alert);
    }
  };

  useEffect(() => {
    setNavbarTitle('Rencana Anggaran Estimasi (RAE)');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    pagination.q,
    pagination.page,
    pagination.per_page,

    pagination.active,
    pagination.tgl_peluang_awal,
    pagination.tgl_peluang_akhir,
    pagination.peringkat_peluang,
    pagination.customer,
    pagination.proyek,
    pagination.kelompok_proyek,
    pagination.target_penawaran_awal,
    pagination.target_penawaran_akhir,
    pagination.realisasi_penawaran_awal,
    pagination.realisasi_penawaran_akhir,
    pagination.instalasi,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <>
        <div className="mt-2 mb-1">
          <b mb>Peluang Yang Siap Didaftarkan Menjadi RAE</b>
        </div>

        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <ThFixed>Peringkat Peluang</ThFixed>
              <Th>Customer</Th>
              <Th>Proyek</Th>
              <Th>Kelompok Proyek</Th>
              <ThFixed>Instalasi</ThFixed>
              <Th>ATT</Th>
              <Th style={{ minWidth: 300 }}>Barang Jadi</Th>
              <ThFixed>Tgl. Selesai</ThFixed>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataPeluangRAE.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(pagination?.page, pagination?.per_page, index)}
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_peluang
                      ? DateConvert(new Date(val.tgl_peluang)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_peluang ? val.no_peluang : '-'}</div>
                </TdFixed>
                <TdFixed>
                  {val.nama_peringkat_peluang
                    ? val.nama_peringkat_peluang
                    : '-'}
                </TdFixed>
                <Td style={{ minWidth: '200px' }}>
                  {val.nama_customer ? val.nama_customer : '-'}
                </Td>
                <Td style={{ minWidth: '200px' }}>
                  {val.nama_proyek ? val.nama_proyek : '-'}
                </Td>
                <Td style={{ minWidth: '200px' }}>
                  {val.kelompok_proyek ? val.kelompok_proyek : '-'}
                </Td>
                <TdFixed textCenter>
                  {val.instalasi ?? val.instalasi === true ? (
                    <IoCheckboxOutline size="20" />
                  ) : (
                    <IoCloseCircleOutline size="20" />
                  )}
                </TdFixed>
                <Td style={{ minWidth: '200px' }}>{val.att ? val.att : '-'}</Td>
                <Td>
                  {val.barang_jadi ? (
                    val.barang_jadi.length > 0 ? (
                      <BarangJadiCollapse data={val.barang_jadi} />
                    ) : (
                      '-'
                    )
                  ) : (
                    '-'
                  )}
                </Td>
                <TdFixed>
                  {val.tgl_selesai
                    ? DateConvert(new Date(val.tgl_selesai)).defaultDMY
                    : '-'}
                </TdFixed>
                <Td>
                  <div className="d-flex align-middle">
                    <ActionButton
                      size="sm"
                      text={<IoEyeOutline />}
                      className="m-1"
                      tooltip={true}
                      tooltipText="Lihat detail data"
                      onClick={() =>
                        setModalPeluangConfig({
                          show: true,
                          nomor: val.no_peluang ?? '',
                        })
                      }
                    />
                    <ActionButton
                      size="sm"
                      variant="success"
                      className="m-1 text-nowrap"
                      tooltip={true}
                      tooltipText="Register RAE"
                      text={<IoAddOutline />}
                      onClick={() =>
                        history.push('/transaksi/rae/tambah/' + val.id_peluang)
                      }
                    />
                  </div>
                </Td>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={pagination?.per_page}
          dataNumber={
            pagination?.page * pagination?.per_page - pagination?.per_page + 1
          }
          dataPage={
            pagination?.data_count < pagination?.per_page
              ? pagination?.data_count
              : pagination?.page * pagination?.per_page
          }
          dataCount={pagination?.data_count}
          currentPage={pagination?.page}
          totalPage={pagination?.total_page}
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({
              ...prev,
              page: selected + 1,
            }))
          }
          onDataLengthChange={(e) =>
            setPagination((prev) => ({
              ...prev,
              page: 1,
              per_page: e.target.value,
            }))
          }
        />
      </>
    );
  };

  const ModalPeluang = () => {
    const [dataInfoPeluang, setDataInfoPeluang] = useState({});
    const [dataTablePeluang, setDataTablePeluang] = useState([]);
    const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
      loading: true,
      success: false,
    });
    const tabsRef = useRef();
    const [defaultTabs, setDefaultTabs] = useState('info');
    const [dataGambar, setDataGambar] = useState([]);
    const [dataFile, setDataFile] = useState([]);
    // Konfigurasi komponen tabs
    const tabsConfig = [
      {
        tab: 'info',
        label: 'Informasi Umum',
        component: ({ ...rest }) => <TabInfoPeluang {...rest} />,
      },
      {
        tab: 'gambar',
        label: 'Gambar',
        component: ({ ...rest }) => <TabGambarPeluang {...rest} />,
      },
      {
        tab: 'file',
        label: 'File',
        component: ({ ...rest }) => <TabFilePeluang {...rest} />,
      },
    ];

    // Menangani cek type dari file yang dikirim
    const checkTypeAndStoreFile = (data) => {
      let gambar = [];
      let file = [];
      data.map((val) => {
        const map = {
          link: val.path_gambar,
          id: val.id_peluang_gambar,
          nama: '',
          data: null,
        };
        const generalFileType = map.link.split('.').pop();
        const fileName = map.link.split('/').pop();
        // Check apakah data adalah gambar
        if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
          return gambar.push({ ...map, nama: fileName });
        }
        return file.push({ ...map, nama: fileName });
      });
      setDataGambar(gambar);
      setDataFile(file);
    };

    // Menangani perubahan pada tabs
    const onTabsChangeHandler = (e, tabs) => {
      e.preventDefault();
      setDefaultTabs(tabs);
    };

    const getDataRAE = (noPeluang) => {
      setFetchPeluangStatus({
        loading: true,
        success: false,
      });

      RAEApi.getDetailPeluangRAE({ no_peluang: noPeluang })
        .then((res) => {
          const dataInfo = res.data.data ?? {};
          const dataTable = res.data.data.detail ?? {};
          setDataInfoPeluang(dataInfo);
          checkTypeAndStoreFile(dataInfo?.gambar ?? []);
          setDataTablePeluang(dataTable);
          setFetchPeluangStatus({
            loading: false,
            success: true,
          });
        })
        .catch(() => {
          setFetchPeluangStatus({
            loading: false,
            success: false,
          });
        });
    };

    useEffect(() => {
      if (modalPeluangConfig.nomor) {
        getDataRAE(modalPeluangConfig.nomor);
      } else {
        setFetchPeluangStatus({
          loading: false,
          success: false,
        });
      }
    }, []);

    if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
      return (
        <Modal
          size="xl"
          show={modalPeluangConfig.show}
          onHide={() =>
            setModalPeluangConfig({
              ...modalPeluangConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton>
            <b>Detail Peluang</b>
          </Modal.Header>
          <Modal.Body>
            {fetchPeluangStatus.loading ? (
              <DataStatus loading text="Memuat data . . ." />
            ) : (
              <DataStatus text="Data gagal dimuat" />
            )}
          </Modal.Body>
        </Modal>
      );
    }
    return (
      <Modal
        size="xl"
        show={modalPeluangConfig.show}
        onHide={() =>
          setModalPeluangConfig({
            ...modalPeluangConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton>
          <b>Detail Peluang</b>
        </Modal.Header>
        <Modal.Body>
          <Card.Header>
            {/* Tab Header */}
            <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
              {tabsConfig.map((val, index) => (
                <Nav.Item key={index}>
                  <Nav.Link
                    ref={tabsRef}
                    href={`#${val.tab}`}
                    onClick={(e) => onTabsChangeHandler(e, val.tab)}
                  >
                    {val.label}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {tabsConfig.map(
              ({ tab, component: Component }, index) =>
                tab === defaultTabs && (
                  <Component
                    key={index}
                    type="DETAIL"
                    dataInfoPeluang={dataInfoPeluang}
                    dataTablePeluang={dataTablePeluang}
                    dataGambar={dataGambar}
                    dataFile={dataFile}
                    setDataGambar={setDataGambar}
                    setDataFile={setDataFile}
                  />
                )
            )}
          </Card.Body>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="d-flex align-items-center mb-2">
            <Col md="10" className="d-flex">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(searchHandler, 1500)}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.push('/transaksi/rae')} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataPeluangRAE?.length ? (
        <PageContent />
      ) : (
        <DataStatus text="Tidak ada data" />
      )}

      {modalPeluangConfig.show && <ModalPeluang />}

      <ModalFilter
        status="peluang"
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};
export default PeluangRAE;
