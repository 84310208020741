// React
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Component
import {
  Button,
  Card,
  Col,
  Row,
  ButtonGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import {
  CRUDLayout,
  Tr,
  Th,
  Td,
  Alert,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  BackButton,
} from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

// Icon
import { IoDocumentTextOutline } from 'react-icons/io5';

// File Saver
import FileSaver from 'file-saver';

// API
import { LaporanRAEApi } from 'api';

const DetailLaporanRAE = ({ setNavbarTitle }) => {
  const { id } = useParams();
  const history = useHistory();
  let total = 0;
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    show: false,
  });

  const handleButtonExport = (id_rae_detail, tipe) => {
    LaporanRAEApi.getSingleExport({
      id_rae_detail,
      tipe,
    }).then(({ data }) => {
      const ext = tipe === 'excel' ? 'xlsx' : 'pdf';
      FileSaver.saveAs(
        `${process.env.REACT_APP_BASE_URL}/${data.data_only.replace(
          'tnk/',
          ''
        )}`,
        `Laporan_detail_.${ext}`
      );
    });
  };

  const handleButtonExportPrelim = (id_rae, tipe) => {
    LaporanRAEApi.getSingleExportPrelim({
      id_rae,
      tipe,
    }).then(({ data }) => {
      const ext = tipe === 'excel' ? 'xlsx' : 'pdf';
      FileSaver.saveAs(
        `${process.env.REACT_APP_BASE_URL}/${data.data_only.replace(
          'tnk/',
          ''
        )}`,
        `Laporan_prelim_.${ext}`
      );
    });
  };

  const mappingDetail = (detail, prelim) => {
    let harga_satuan = 0;
    for (let i = 0; i < prelim.length; i++) {
      const qty_analisa = prelim[i].qty_analisa
        ? parseFloat(prelim[i].qty_analisa)
        : 0;
      // const qty_durasi = prelim[i].qty_durasi ? parseFloat(prelim[i].qty_durasi) : 0
      const unit_price = prelim[i].unit_price
        ? parseFloat(prelim[i].unit_price)
        : 0;
      harga_satuan += parseInt(qty_analisa * unit_price);
    }

    return [
      ...detail,
      {
        status_prelim: true,
        kode_item: '',
        nama_item: 'PRELIM',
        qty_rae: 1,
        harga_satuan_rae: harga_satuan,
      },
    ];
  };

  useEffect(() => {
    setNavbarTitle('Laporan RAE');
    setLoading(true);

    LaporanRAEApi.getSingle({
      id_rae: id,
    })
      .then(({ data }) => {
        setData({
          data: mappingDetail(data.data.detail, data.data.prelim),
          ...data.data,
        });
      })
      .catch(() => {
        setAlertConfig({
          variant: 'danger',
          text: 'Tidak dapat memuat data!',
          show: true,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setNavbarTitle]);

  const ExportButton = ({ variant = 'outline-success', onClick, type }) => {
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip" className="text-capitalize">
            Export {type}
          </Tooltip>
        }
      >
        <Button variant={variant} onClick={onClick} size="sm">
          <IoDocumentTextOutline size={18} />
        </Button>
      </OverlayTrigger>
    );
  };

  const InfoItem = ({ title, value }) => (
    <tr>
      <td className="align-top">{title}</td>
      <td className="pl-4 pr-2 align-top">:</td>
      <td className="align-top">{value}</td>
    </tr>
  );

  const TableSection = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Kode Barang Jadi</ThFixed>
              <Th>Barang Jadi</Th>
              <ThFixed>Qty.</ThFixed>
              <Th>Satuan</Th>
              <Th>Harga Satuan</Th>
              <Th>Sub Total</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.data.map((val, index) => {
              const qty_rae = val.qty_rae ? parseInt(val.qty_rae) : 0;
              const harga_satuan_rae = val.harga_satuan_rae
                ? parseInt(val.harga_satuan_rae)
                : 0;
              total += qty_rae * harga_satuan_rae;

              return (
                <>
                  <Tr key={index}>
                    <TdFixed>{index + 1}</TdFixed>
                    <TdFixed>{val.kode_item}</TdFixed>
                    <Td>{val.nama_item}</Td>
                    <TdFixed>{qty_rae}</TdFixed>
                    {val.status_prelim ? (
                      <Td className="text-right" colSpan={2}>
                        {RupiahConvert(String(harga_satuan_rae)).detail}
                      </Td>
                    ) : (
                      <>
                        <Td>{val.nama_satuan}</Td>
                        <Td className="text-right">
                          {RupiahConvert(String(harga_satuan_rae)).detail}
                        </Td>
                      </>
                    )}
                    <Td className="text-right">
                      {RupiahConvert(String(qty_rae * harga_satuan_rae)).detail}
                    </Td>
                    {!val.status_prelim ? (
                      <TdFixed>
                        <ButtonGroup>
                          <ExportButton
                            variant="outline-danger"
                            type="PDF"
                            onClick={() =>
                              handleButtonExport(val.id_rae_detail, 'pdf')
                            }
                          />
                          <ExportButton
                            type="Excel"
                            onClick={() =>
                              handleButtonExport(val.id_rae_detail, 'excel')
                            }
                          />
                        </ButtonGroup>
                      </TdFixed>
                    ) : (
                      <TdFixed>
                        <ButtonGroup>
                          <ExportButton
                            variant="outline-danger"
                            type="PDF"
                            onClick={() =>
                              handleButtonExportPrelim(data.id_rae, 'pdf')
                            }
                          />
                          <ExportButton
                            type="Excel"
                            onClick={() =>
                              handleButtonExportPrelim(data.id_rae, 'excel')
                            }
                          />
                        </ButtonGroup>
                      </TdFixed>
                    )}
                  </Tr>
                  {data.data.length == index + 1 && (
                    <Tr>
                      <Td colSpan={6} className="text-right font-weight-bold">
                        Grand Total
                      </Td>
                      <Td className="text-right">
                        {RupiahConvert(String(total)).detail}
                      </Td>
                      <Td></Td>
                    </Tr>
                  )}
                </>
              );
            })}
          </TBody>
        </CRUDLayout.Table>
      </>
    );
  };

  if (isLoading) {
    return <DataStatus loading={true} text="Memuat data ..." />;
  } else {
    return (
      <CRUDLayout>
        <Alert
          showCloseButton={true}
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        <Card>
          <Card.Header>
            <div className="d-flex justify-content-between align-items-center">
              <b>Detail Data RAE</b>
              <BackButton onClick={() => history.goBack()} />
            </div>
          </Card.Header>

          <Card.Body>
            <Row>
              <Col>
                <table style={{ fontSize: '14px' }}>
                  <tbody>
                    <InfoItem
                      title="Tgl. Peluang"
                      value={
                        data.tgl_peluang
                          ? DateConvert(new Date(data.tgl_peluang)).detail
                          : '-'
                      }
                    />
                    <InfoItem
                      title="No. Peluang"
                      value={data.no_peluang ? data.no_peluang : '-'}
                    />
                    <InfoItem
                      title="Peringkat Peluang"
                      value={
                        data.nama_peringkat_peluang
                          ? data.nama_peringkat_peluang
                          : '-'
                      }
                    />
                    <InfoItem
                      title="Realisasi Penawaran"
                      value={
                        data.tgl_selesai
                          ? DateConvert(new Date(data.tgl_selesai)).detail
                          : '-'
                      }
                    />
                  </tbody>
                </table>
              </Col>
              <Col>
                <table style={{ fontSize: '14px' }}>
                  <tbody>
                    <InfoItem
                      title="Customer"
                      value={data.nama_customer ? data.nama_customer : '-'}
                    />
                    <InfoItem title="ATT" value={data.att ? data.att : '-'} />
                    <InfoItem
                      title="Target Penawaran"
                      value={
                        data.target_penawaran
                          ? DateConvert(new Date(data.target_penawaran)).detail
                          : '-'
                      }
                    />
                    <InfoItem
                      title="Schedule Kebutuhan Proyek (Total Hari)"
                      value={data.schedule_kebutuhan_proyek}
                    />
                    <InfoItem
                      title="Proyek"
                      value={data.nama_proyek ? data.nama_proyek : '-'}
                    />
                  </tbody>
                </table>
              </Col>
            </Row>
            <hr />

            <b>Tampilan Tabel (Normal)</b>
            <TableSection />
          </Card.Body>
        </Card>
      </CRUDLayout>
    );
  }
};

export default DetailLaporanRAE;
