// React
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Component
import { Card, Modal, Row, Col, Nav } from 'react-bootstrap';
import { Alert, DataStatus, BackButton, ActionButton, Input } from 'components';
import { RupiahConvert, DateConvert } from 'utilities';

// Form
import { Formik } from 'formik';
import * as Yup from 'yup';

// API
import Axios from 'axios';
import { RealisasiPrelimApi } from 'api';

// View
import { Datatable } from '../Section';

const DetailSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const title = 'Realisasi Prelim';
  const { tipe, id } = useParams();

  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataJO, setDataJO] = useState({});
  const [dataKontrak, setDataKontrak] = useState({
    analisa: [],
    history: [],
  });
  const [dataOverhead, setDataOverhead] = useState({
    analisa: [],
    history: [],
  });
  const [dataTransfer, setDataTransfer] = useState({
    analisa: [],
    history: [],
  });

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: '',
    tipe: '',
    val: {},
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([
      RealisasiPrelimApi.job_order_single({ id_spk: id }),
      RealisasiPrelimApi.kontrak_prelim({ id_spk: id }),
      RealisasiPrelimApi.realisasi_kontrak_prelim({ id_spk: id }),
      RealisasiPrelimApi.overhead_prelim({ id_spk: id }),
      RealisasiPrelimApi.realisasi_overhead_prelim({ id_spk: id }),
      RealisasiPrelimApi.transfer_prelim({ id_spk: id }),
      RealisasiPrelimApi.realisasi_transfer_prelim({ id_spk: id }),
    ])
      .then(
        Axios.spread(
          (
            job_order,
            kontrak_prelim,
            realisasi_kontrak,
            overhead_prelim,
            realisasi_overhead,
            transfer_prelim,
            realisasi_transfer
          ) => {
            setDataJO(job_order?.data?.data ?? {});
            setDataKontrak({
              analisa: kontrak_prelim?.data?.data ?? [],
              history: realisasi_kontrak?.data?.data ?? [],
            });
            setDataOverhead({
              analisa: overhead_prelim?.data?.data ?? [],
              history: realisasi_overhead?.data?.data ?? [],
            });
            setDataTransfer({
              analisa: transfer_prelim?.data?.data ?? [],
              history: realisasi_transfer?.data?.data ?? [],
            });
          }
        )
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const InfoItem = ({ title, value }) => (
    <tr>
      <td width="120">{title}</td>
      <td className="pl-4 pr-2 align-top">:</td>
      <td className="align-top">{value}</td>
    </tr>
  );

  const InfoSection = () => {
    return (
      <Row>
        <Col md>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              {/* <InfoItem
								title="Tgl. SPK"
								value={dataJO?.tgl_spk ? DateConvert(new Date(dataJO?.tgl_spk)).detail : '-'}
							/> */}
              <InfoItem title="No. SPK" value={dataJO?.no_spk ?? '-'} />
              <InfoItem title="Customer" value={dataJO?.nama_customer ?? '-'} />
              <InfoItem title="ATT" value={dataJO?.att ?? '-'} />
              <InfoItem
                title="Tgl. Selesai"
                value={
                  dataJO?.tgl_selesai
                    ? DateConvert(new Date(dataJO?.tgl_selesai)).detail
                    : '-'
                }
              />
            </tbody>
          </table>
        </Col>
      </Row>
    );
  };

  const ModalSection = () => {
    const tgl_prelim =
      modalConfig?.tipe === 'Lain-lain'
        ? modalConfig?.data?.tgl_overhead_prelim
        : modalConfig?.tipe === 'Honor Staff'
        ? modalConfig?.data?.tgl_kontrak_prelim
        : modalConfig?.data?.tgl_transfer_prelim;
    const no_prelim =
      modalConfig?.tipe === 'Lain-lain'
        ? modalConfig?.data?.no_overhead_prelim
        : modalConfig?.tipe === 'Honor Staff'
        ? modalConfig?.data?.no_kontrak_prelim
        : modalConfig?.data?.no_transfer_prelim;
    const qty =
      modalConfig?.tipe === 'Lain-lain'
        ? modalConfig?.data?.qty_overhead
        : modalConfig?.tipe === 'Honor Staff'
        ? modalConfig?.data?.qty_kontrak
        : modalConfig?.data?.qty_transfer;
    const nama_satuan =
      modalConfig?.tipe === 'Lain-lain'
        ? modalConfig?.data?.nama_satuan_overhead
        : modalConfig?.tipe === 'Honor Staff'
        ? modalConfig?.data?.nama_satuan_kontrak
        : modalConfig?.data?.nama_satuan_transfer;

    const [dataAtribut, setDataAtribut] = useState({
      no_baru: '',
    });

    useEffect(() => {
      setDataAtribut({ no_baru: 'Menunggu...' });

      if (modalConfig?.tipe === 'Lain-lain') {
        RealisasiPrelimApi.overhead_no_baru({
          tanggal: DateConvert(new Date()).default,
        }).then((res) => setDataAtribut({ no_baru: res.data.data }));
      } else if (modalConfig?.tipe === 'Honor Staff') {
        RealisasiPrelimApi.kontrak_no_baru({
          tanggal: DateConvert(new Date()).default,
        }).then((res) => setDataAtribut({ no_baru: res.data.data }));
      } else {
        RealisasiPrelimApi.transfer_no_baru({
          tanggal: DateConvert(new Date()).default,
        }).then((res) => setDataAtribut({ no_baru: res.data.data }));
      }
    }, []);

    const initModalValues = {
      tgl: DateConvert(new Date()).default,
      qty: null,
      durasi: null,
    };

    const modalValidationSchema = Yup.object().shape({
      tgl: Yup.string().required('Tgl. Relisasi Prelim wajib diisi'),
      qty: Yup.number()
        .typeError('Input Angka Salah')
        .required('Qty. Realisasi wajib diisi'),
      durasi: Boolean(
        modalConfig?.tipe === 'Lain-lain' || modalConfig?.tipe === 'Honor Staff'
      )
        ? Yup.number()
            .typeError('Input Angka Salah')
            .required('Durasi Realisasi wajib diisi')
        : Yup.number().nullable(),
    });

    const modalSubmitHandler = (values, { setSubmitting }) => {
      if (modalConfig?.tipe === 'Lain-lain') {
        RealisasiPrelimApi.realisasi_overhead_prelim_create({
          tgl_realisasi_overhead_prelim: values.tgl,
          id_overhead_prelim: modalConfig?.data?.id_overhead_prelim,
          qty_realisasi: values.qty,
          durasi_realisasi: values.durasi,
        })
          .then(() =>
            setAlertConfig({
              show: true,
              text: 'Berhasil tambah permintaan',
              variant: 'primary',
            })
          )
          .catch(() =>
            setAlertConfig({
              show: true,
              text: 'Gagal tambah permintaan',
              variant: 'danger',
            })
          )
          .finally(() => {
            setSubmitting(false);
            setModalConfig({ ...modalConfig, show: false });
            getInitialData();
          });
      } else if (modalConfig?.tipe === 'Honor Staff') {
        RealisasiPrelimApi.realisasi_kontrak_prelim_create({
          tgl_realisasi_kontrak_prelim: values.tgl,
          id_kontrak_prelim: modalConfig?.data?.id_kontrak_prelim,
          qty_realisasi: values.qty,
          durasi_realisasi: values.durasi,
        })
          .then(() =>
            setAlertConfig({
              show: true,
              text: 'Berhasil tambah permintaan',
              variant: 'primary',
            })
          )
          .catch(() =>
            setAlertConfig({
              show: true,
              text: 'Gagal tambah permintaan',
              variant: 'danger',
            })
          )
          .finally(() => {
            setSubmitting(false);
            setModalConfig({ ...modalConfig, show: false });
            getInitialData();
          });
      } else {
        RealisasiPrelimApi.realisasi_transfer_prelim_create({
          tgl_realisasi_transfer_prelim: values.tgl,
          id_transfer_prelim: modalConfig?.data?.id_transfer_prelim,
          qty_realisasi: values.qty,
        })
          .then(() =>
            setAlertConfig({
              show: true,
              text: 'Berhasil tambah permintaan',
              variant: 'primary',
            })
          )
          .catch(() =>
            setAlertConfig({
              show: true,
              text: 'Gagal tambah permintaan',
              variant: 'danger',
            })
          )
          .finally(() => {
            setSubmitting(false);
            setModalConfig({ ...modalConfig, show: false });
            getInitialData();
          });
      }
    };

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>Tambah Data Realisasi Prelim</small>
          </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={initModalValues}
          validationSchema={modalValidationSchema}
          onSubmit={modalSubmitHandler}
        >
          {(modalFormik) => {
            return (
              <form onSubmit={modalFormik.handleSubmit}>
                <Modal.Body>
                  <table style={{ fontSize: '14px' }}>
                    <tbody>
                      <InfoItem
                        title={`Tgl. ${
                          modalConfig?.tipe === 'Staff Honor'
                            ? 'Kontrak'
                            : modalConfig?.tipe === 'Lain-lain'
                            ? 'Overhead'
                            : 'Transfer'
                        } Prelim`}
                        value={
                          tgl_prelim
                            ? DateConvert(new Date(tgl_prelim)).detail
                            : '-'
                        }
                      />
                      <InfoItem
                        title={`No. ${
                          modalConfig?.tipe === 'Staff Honor'
                            ? 'Kontrak'
                            : modalConfig?.tipe === 'Lain-lain'
                            ? 'Overhead'
                            : 'Transfer'
                        } Prelim`}
                        value={no_prelim ?? '-'}
                      />
                      <InfoItem
                        title="Item Prelim"
                        value={
                          modalConfig?.data?.nama_item_prelim
                            ? `${
                                modalConfig?.data?.kode_item_prelim &&
                                `(${modalConfig?.data?.kode_item_prelim}) `
                              }${modalConfig?.data?.nama_item_prelim}`
                            : '-'
                        }
                      />
                      <InfoItem
                        title={`Qty. ${
                          modalConfig?.tipe === 'Staff Honor'
                            ? 'Kontrak'
                            : modalConfig?.tipe === 'Lain-lain'
                            ? 'Overhead'
                            : 'Transfer'
                        } Prelim`}
                        value={qty ? `${qty} ${nama_satuan}` : 0}
                      />
                      {modalConfig?.tipe === 'Staff Honor' && (
                        <InfoItem
                          title="Vendor"
                          value={modalConfig?.data?.nama_vendor ?? '-'}
                        />
                      )}
                      {Boolean(
                        modalConfig?.tipe === 'Staff Honor' ||
                          modalConfig?.tipe === 'Lain-lain'
                      ) && (
                        <>
                          <InfoItem
                            title={`Harga Satuan ${
                              modalConfig?.tipe === 'Staff Honor'
                                ? 'Kontrak'
                                : modalConfig?.tipe === 'Lain-lain'
                                ? 'Overhead'
                                : ''
                            }`}
                            value={
                              modalConfig?.data?.harga_satuan
                                ? RupiahConvert(
                                    String(
                                      parseInt(modalConfig?.data?.harga_satuan)
                                    )
                                  ).detail
                                : '-'
                            }
                          />
                          <InfoItem
                            title="Durasi Prelim"
                            value={
                              modalConfig?.data?.qty_durasi
                                ? `${parseInt(modalConfig?.data?.qty_durasi)} ${
                                    modalConfig?.data?.nama_satuan_durasi ?? ''
                                  }`
                                : 0
                            }
                          />
                          <InfoItem
                            title="Total"
                            value={
                              modalConfig?.data?.harga_satuan &&
                              modalConfig?.data?.qty_durasi
                                ? RupiahConvert(
                                    String(
                                      parseInt(
                                        modalConfig?.data?.harga_satuan
                                      ) *
                                        parseInt(modalConfig?.data?.qty_durasi)
                                    )
                                  ).detail
                                : 'Rp. 0'
                            }
                          />
                        </>
                      )}
                      {Boolean(
                        modalConfig?.tipe !== 'Staff Honor' &&
                          modalConfig?.tipe !== 'Lain-lain'
                      ) && (
                        <>
                          <InfoItem
                            title="Gudang Asal"
                            value={modalConfig?.data?.nama_gudang_asal ?? '-'}
                          />
                          <InfoItem
                            title="Gudang Tujuan"
                            value={modalConfig?.data?.nama_gudang_tujuan ?? '-'}
                          />
                        </>
                      )}
                    </tbody>
                  </table>

                  <hr />
                  <Row>
                    <Col md="6">
                      <Input
                        label="Tgl. Realisasi Prelim"
                        type="date"
                        name="tgl"
                        value={modalFormik.values.tgl}
                        onChange={(e) => {
                          const value = e.target.value;
                          modalFormik.setFieldValue('tgl', value);
                          setDataAtribut({
                            no_baru: 'Menunggu...',
                          });

                          if (modalConfig?.tipe === 'Lain-lain') {
                            RealisasiPrelimApi.overhead_no_baru({
                              tanggal: DateConvert(new Date()).default,
                            })
                              .then((res) =>
                                setDataAtribut({ no_baru: res.data.data })
                              )
                              .catch(() => setDataAtribut({ no_baru: '' }));
                          } else if (modalConfig?.tipe === 'Honor Staff') {
                            RealisasiPrelimApi.kontrak_no_baru({
                              tanggal: DateConvert(new Date()).default,
                            })
                              .then((res) =>
                                setDataAtribut({ no_baru: res.data.data })
                              )
                              .catch(() => setDataAtribut({ no_baru: '' }));
                          } else {
                            RealisasiPrelimApi.transfer_no_baru({
                              tanggal: DateConvert(new Date()).default,
                            })
                              .then((res) =>
                                setDataAtribut({ no_baru: res.data.data })
                              )
                              .catch(() => setDataAtribut({ no_baru: '' }));
                          }
                        }}
                        error={
                          modalFormik.errors.tgl &&
                          modalFormik.touched.tgl &&
                          true
                        }
                        errorText={modalFormik.errors.tgl}
                      />
                    </Col>

                    <Col md="6">
                      <Input
                        label="No. Realisasi Prelim"
                        type="text"
                        value={dataAtribut.no_baru}
                        readOnly={true}
                      />
                    </Col>

                    <Col md="6">
                      <Input
                        label="Qty. Realisasi"
                        placeholder="Qty. Realisasi"
                        name="qty"
                        value={modalFormik.values.qty}
                        onChange={modalFormik.handleChange}
                        error={
                          modalFormik.errors.qty &&
                          modalFormik.touched.qty &&
                          true
                        }
                        errorText={modalFormik.errors.qty}
                      />
                    </Col>

                    <Col md="6">
                      <Input
                        label="Satuan Qty."
                        type="text"
                        value={nama_satuan ?? '-'}
                        readOnly={true}
                      />
                    </Col>

                    {Boolean(
                      modalConfig?.tipe === 'Lain-lain' ||
                        modalConfig?.tipe === 'Honor Staff'
                    ) && (
                      <>
                        <Col md="6">
                          <Input
                            label="Durasi Realisasi"
                            placeholder="Durasi Realisasi"
                            name="durasi"
                            value={modalFormik.values.durasi}
                            onChange={modalFormik.handleChange}
                            error={
                              modalFormik.errors.durasi &&
                              modalFormik.touched.durasi &&
                              true
                            }
                            errorText={modalFormik.errors.durasi}
                          />
                        </Col>

                        <Col md="6">
                          <Input
                            label="Satuan Durasi"
                            type="text"
                            value="Hari"
                            readOnly={true}
                          />
                        </Col>

                        <Col md="6">
                          <Input
                            label={`Harga Satuan ${
                              modalConfig?.tipe === 'Staff Honor'
                                ? 'Kontrak'
                                : modalConfig?.tipe === 'Lain-lain'
                                ? 'Overhead'
                                : ''
                            }`}
                            type="text"
                            value={
                              modalConfig?.data?.harga_satuan
                                ? RupiahConvert(
                                    String(
                                      parseInt(modalConfig.data.harga_satuan)
                                    )
                                  ).detail
                                : 'Rp. 0'
                            }
                            readOnly={true}
                          />
                        </Col>

                        <Col md="6">
                          <Input
                            label="Total"
                            type="text"
                            value={
                              modalConfig?.data?.harga_satuan &&
                              modalFormik.values.qty &&
                              modalFormik.values.durasi
                                ? RupiahConvert(
                                    String(
                                      parseInt(modalConfig.data.harga_satuan) *
                                        parseInt(modalFormik.values.qty)
                                    )
                                  ).detail
                                : 'Rp. 0'
                            }
                            readOnly={true}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                </Modal.Body>

                <Modal.Footer className="justify-content-end">
                  <ActionButton
                    variant="outline-secondary"
                    text="Batal"
                    className="px-4"
                    onClick={() =>
                      setModalConfig({
                        ...modalConfig,
                        show: false,
                      })
                    }
                  />
                  <ActionButton
                    type="submit"
                    text="Simpan"
                    variant="primary"
                    className="m-1 px-3 text-white"
                    // onClick={handleSubmit}
                    loading={modalFormik.isSubmitting}
                  />
                </Modal.Footer>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();

    // eslint-disable-next-line
  }, []);

  const [tabs, setTabs] = useState('honor_staff'); //State untuk menampung tabs yang aktif

  // Konfigurasi komponen tabs
  const tabsConfig = [
    {
      tab: 'honor_staff',
      label: 'Honor Staff',
      component: () => (
        <Datatable
          data={dataKontrak?.analisa ?? []}
          history={dataKontrak?.history ?? []}
          setModalConfig={setModalConfig}
          kelompok="Honor Staff"
          tipe={tipe}
        />
      ),
    },
    {
      tab: 'atk',
      label: 'ATK',
      component: () => (
        <Datatable
          data={dataTransfer?.analisa ?? []}
          history={dataTransfer?.history ?? []}
          setModalConfig={setModalConfig}
          kelompok="ATK"
          tipe={tipe}
        />
      ),
    },
    {
      tab: 'safety',
      label: 'Safety',
      component: () => (
        <Datatable
          data={dataTransfer?.analisa ?? []}
          history={dataTransfer?.history ?? []}
          setModalConfig={setModalConfig}
          kelompok="Safety"
          tipe={tipe}
        />
      ),
    },
    {
      tab: 'hand_tools',
      label: 'Hand Tools',
      component: () => (
        <Datatable
          data={dataTransfer?.analisa ?? []}
          history={dataTransfer?.history ?? []}
          setModalConfig={setModalConfig}
          kelompok="Hand Tools"
          tipe={tipe}
        />
      ),
    },
    {
      tab: 'proteksi',
      label: 'Proteksi',
      component: () => (
        <Datatable
          data={dataTransfer?.analisa ?? []}
          history={dataTransfer?.history ?? []}
          setModalConfig={setModalConfig}
          kelompok="Proteksi"
          tipe={tipe}
        />
      ),
    },
    {
      tab: 'lain_lain',
      label: 'Lain-lain',
      component: () => (
        <Datatable
          data={dataOverhead?.analisa ?? []}
          history={dataOverhead?.history ?? []}
          setModalConfig={setModalConfig}
          kelompok="Lain-lain"
          tipe={tipe}
        />
      ),
    },
  ];

  // Menangani perubahan pada tabs
  const onTabsChangeHandler = (e, newPage) => {
    e.preventDefault();
    setTabs(newPage);
  };

  const TabsNav = ({ tab, label }) => (
    <Nav.Item>
      <Nav.Link href={`#${tab}`} onClick={(e) => onTabsChangeHandler(e, tab)}>
        {label}
      </Nav.Link>
    </Nav.Item>
  );

  if (tipe !== 'tambah' && tipe !== 'detail') {
    return <DataStatus text="Data gagal dimuat" />;
  }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center">
        <b className="text-capitalize">
          {tipe} Data {title}
        </b>
        <BackButton onClick={() => history.goBack()} />
      </Card.Header>
      <Card.Body>
        <Alert
          showCloseButton
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() =>
            setAlertConfig({
              ...alertConfig,
              show: false,
            })
          }
        />
        {isPageLoading ? (
          <DataStatus loading={true} text="Memuat data . . ." />
        ) : (
          <>
            <InfoSection />
            <hr />
            <Card>
              <Card.Header>
                <Nav variant="tabs" defaultActiveKey={`#${tabs}`}>
                  {tabsConfig.map((val, index) => (
                    <TabsNav key={index} tab={val.tab} label={val.label} />
                  ))}
                </Nav>
              </Card.Header>
              {tabsConfig.map(
                ({ tab, component: Component }, index) =>
                  tab === tabs && <Component key={index} />
              )}
            </Card>
            {modalConfig.show && <ModalSection />}

            {tipe === 'tambah' && (
              <div className="text-right mt-3">
                <ActionButton
                  type="button"
                  variant="primary"
                  text="Simpan"
                  onClick={() => history.goBack()}
                />
              </div>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default DetailSPK;
