import { useState, useEffect } from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import { RAEApi } from 'api';
import { FilterModal, DatePicker, SelectSearch } from 'components';
import { DateConvert } from 'utilities';

const ModalFilter = ({ show, setShow, filter, setFilter, status }) => {
  const [loading, setLoading] = useState({
    allDropdown: true,
    proyek: true,
  });
  const [dataPeringkatPeluang, setDataPeringkatPeluang] = useState([
    { label: 'Semua', value: null },
  ]);
  const [dataCustomer, setDataCustomer] = useState([
    { label: 'Semua', value: null },
  ]);
  const [dataProyek, setDataProyek] = useState([
    { label: 'Semua', value: null },
  ]);
  const dataKelompokProyek = [
    {
      value: null,
      label: 'Semua',
    },
    {
      value: 'proyek',
      label: 'Proyek',
    },
    {
      value: 'umum',
      label: 'Umum',
    },
    {
      value: 'tender',
      label: 'Tender',
    },
  ];
  const dataInstalasi = [
    { label: 'Semua', value: null },
    { label: 'Dengan Instalasi', value: true },
    { label: 'Tanpa Instalasi', value: false },
  ];

  const initialValues = {
    tgl_peluang_awal: filter?.tgl_peluang_awal,
    tgl_peluang_akhir: filter?.tgl_peluang_akhir,
    peringkat_peluang: filter?.peringkat_peluang,
    tgl_rae_awal: filter?.tgl_rae_awal,
    tgl_rae_akhir: filter?.tgl_rae_akhir,
    customer: filter?.customer,
    proyek: filter?.proyek,
    kelompok_proyek: filter?.kelompok_proyek,
    target_penawaran_awal: filter?.target_penawaran_awal,
    target_penawaran_akhir: filter?.target_penawaran_akhir,
    realisasi_penawaran_awal: filter?.realisasi_penawaran_awal,
    realisasi_penawaran_akhir: filter?.realisasi_penawaran_akhir,
    instalasi: filter?.instalasi,
  };

  const onSubmit = (values) => {
    const checkActive =
      !!Object.values(values).filter((val) => val !== undefined).length > 0;

    if (checkActive) {
      setFilter((prev) => ({
        ...prev,
        ...values,
        active: true,
        page: '1',
      }));

      setShow(false);
      return;
    }

    setFilter((prev) => ({ ...values, ...prev, filter: false }));
    setShow(false);
  };

  const getDataDropdown = () => {
    axios
      .all([
        RAEApi.getDropdown({ tipe: 'peringkat_peluang' }),
        RAEApi.getDropdown({ tipe: 'customer' }),
        RAEApi.getDropdown({ tipe: 'proyek' }),
      ])
      .then(
        axios.spread((peringkat, customer, proyek) => {
          const mapDataPeringkat = peringkat?.data?.data
            ? peringkat.data.data.map((val) => ({
                label: val?.nama_peringkat_peluang,
                value: val?.id_peringkat_peluang,
              }))
            : [];
          const mapDataCustomer = customer?.data?.data
            ? customer.data.data.map((val) => ({
                label: val?.nama_customer,
                value: val?.id_customer,
              }))
            : [];
          const mapDataProyek = proyek?.data?.data
            ? proyek.data.data.map((val) => ({
                label: val?.nama_proyek,
                value: val?.id_proyek,
              }))
            : [];

          setDataPeringkatPeluang([
            ...dataPeringkatPeluang,
            ...mapDataPeringkat,
          ]);
          setDataCustomer([...dataCustomer, ...mapDataCustomer]);
          setDataProyek([{ label: 'Semua', value: null }, ...mapDataProyek]);
        })
      )
      .finally(() => {
        setLoading({
          ...loading,
          allDropdown: false,
          proyek: false,
        });
      });
  };

  const getProyek = (data) => {
    setLoading({
      ...loading,
      proyek: true,
    });

    RAEApi.getDropdown({ tipe: 'proyek', id_customer: data })
      .then((proyek) => {
        const mapDataProyek = proyek?.data?.data
          ? proyek.data.data.map((val) => ({
              label: val.nama_proyek,
              value: val.id_proyek,
            }))
          : [];
        setDataProyek([{ label: 'Semua', value: null }, ...mapDataProyek]);
      })
      .finally(() => {
        setLoading({
          ...loading,
          proyek: false,
        });
      });
  };

  const onTanggalRAEChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_rae_awal: startDate,
      tgl_rae_akhir: endDate,
    });
  };

  const onTanggalPeluangChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      tgl_peluang_awal: startDate,
      tgl_peluang_akhir: endDate,
    });
  };

  const onTargetPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      target_penawaran_awal: startDate,
      target_penawaran_akhir: endDate,
    });
  };

  const onRealisasiPenawaranChange = (dates, values, setValues) => {
    const [start, end] = dates;
    const startDate = start ? DateConvert(start).default : null;
    const endDate = end ? DateConvert(end).default : null;

    setValues({
      ...values,
      realisasi_penawaran_awal: startDate,
      realisasi_penawaran_akhir: endDate,
    });
  };

  const onResetButtonClick = (values, setValues) => {
    setFilter((prev) => ({
      ...prev,
      active: false,
      tgl_peluang_awal: undefined,
      tgl_peluang_akhir: undefined,
      tgl_rae_awal: undefined,
      tgl_rae_akhir: undefined,
      peringkat_peluang: undefined,
      customer: undefined,
      proyek: undefined,
      kelompok_proyek: undefined,
      target_penawaran_awal: undefined,
      target_penawaran_akhir: undefined,
      realisasi_penawaran_awal: undefined,
      realisasi_penawaran_akhir: undefined,
      instalasi: undefined,
    }));
    setShow(false);
  };

  useEffect(() => {
    getDataDropdown();
  }, []);

  useEffect(() => {
    filter.customer && getProyek(filter.customer);
  }, [filter.customer]);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ values, handleSubmit, setFieldValue, setValues }) => (
        <FilterModal
          show={show}
          setShow={setShow}
          onResetButtonClick={() => onResetButtonClick(values, setValues)}
          onFilterButtonClick={handleSubmit}
        >
          {status === 'peluang' ? (
            <DatePicker
              selectsRange
              label="Tgl. Peluang"
              placeholderText="Pilih tanggal peluang"
              startDate={
                values.tgl_peluang_awal ? new Date(values.tgl_peluang_awal) : ''
              }
              endDate={
                values.tgl_peluang_akhir
                  ? new Date(values.tgl_peluang_akhir)
                  : ''
              }
              onChange={(dates) =>
                onTanggalPeluangChange(dates, values, setValues)
              }
              monthsShown={2}
            />
          ) : (
            <DatePicker
              selectsRange
              label="Tgl. RAE"
              placeholderText="Pilih tanggal rae"
              startDate={
                values.tgl_rae_awal ? new Date(values.tgl_rae_awal) : ''
              }
              endDate={
                values.tgl_rae_akhir ? new Date(values.tgl_rae_akhir) : ''
              }
              onChange={(dates) => onTanggalRAEChange(dates, values, setValues)}
              monthsShown={2}
            />
          )}
          <SelectSearch
            key={values.peringkat_peluang}
            label="Peringkat Peluang"
            placeholder="Pilih peringkat peluang"
            defaultValue={dataPeringkatPeluang.find(
              (val) => val.value === values.peringkat_peluang
            )}
            option={dataPeringkatPeluang}
            onChange={(val) => setFieldValue('peringkat_peluang', val.value)}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.customer}
            label="Customer"
            placeholder="Pilih customer"
            defaultValue={dataCustomer.find(
              (val) => val.value === values.customer
            )}
            option={dataCustomer}
            onChange={(val) => {
              setValues({
                ...values,
                customer: val.value,
                proyek: undefined,
              });
              getProyek(val.value, values, setValues);
            }}
            loading={loading.allDropdown}
          />
          <SelectSearch
            key={values.proyek}
            label="Proyek"
            placeholder="Pilih proyek untuk memilih proyek"
            defaultValue={dataProyek.find((val) => val.value === values.proyek)}
            option={dataProyek}
            onChange={(val) => setFieldValue('proyek', val.value)}
            loading={loading.proyek}
          />
          <SelectSearch
            key={values.kelompok_proyek}
            label="Kelompok Proyek"
            placeholder="Pilih kelompok proyek"
            defaultValue={dataKelompokProyek.find(
              (val) => val.value === values.kelompok_proyek
            )}
            option={dataKelompokProyek}
            onChange={(val) => setFieldValue('kelompok_proyek', val.value)}
            loading={loading.allDropdown}
          />
          <DatePicker
            selectsRange
            label="Target Penawaran"
            placeholderText="Pilih target penawaran"
            startDate={
              values.target_penawaran_awal
                ? new Date(values.target_penawaran_awal)
                : ''
            }
            endDate={
              values.target_penawaran_akhir
                ? new Date(values.target_penawaran_akhir)
                : ''
            }
            onChange={(dates) =>
              onTargetPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <DatePicker
            selectsRange
            label="Realisasi Penawaran"
            placeholderText="Pilih realisasi penawaran"
            startDate={
              values.realisasi_penawaran_awal
                ? new Date(values.realisasi_penawaran_awal)
                : ''
            }
            endDate={
              values.realisasi_penawaran_akhir
                ? new Date(values.realisasi_penawaran_akhir)
                : ''
            }
            onChange={(dates) =>
              onRealisasiPenawaranChange(dates, values, setValues)
            }
            monthsShown={2}
          />
          <SelectSearch
            key={values.instalasi}
            label="Instalasi"
            placeholder="Pilih instalasi"
            defaultValue={dataInstalasi.find(
              (val) => val.value === values.instalasi
            )}
            option={dataInstalasi}
            onChange={(val) => setFieldValue('instalasi', val.value)}
          />
        </FilterModal>
      )}
    </Formik>
  );
};

export default ModalFilter;
