import { useState, useEffect } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ActionButton, Input } from 'components';
import { PermintaanPrelimApi } from 'api';
import { RupiahConvert, DateConvert } from 'utilities';

const InfoItem = ({ title, value }) => (
  <tr>
    <td width="120">{title}</td>
    <td className="pl-4 pr-2">:</td>
    <td>{value}</td>
  </tr>
);

const ModalPermintaanPrelim = ({
  id,
  getInitialData,
  modalConfig,
  setModalConfig,
  setAlertConfig,
  // history,
}) => {
  const [dataAtribut, setDataAtribut] = useState({ no_baru: '' });

  const checkQtyPermintaan = (value) => {
    const qty_permintaan_history =
      modalConfig?.history?.length > 0
        ? modalConfig.history.find(
            (val) =>
              val.nama_item_prelim === modalConfig?.data?.nama_item_prelim
          )?.qty_permintaan
        : 0;
    console.log(modalConfig);
    const akumulasi =
      qty_permintaan_history > 0
        ? parseFloat(modalConfig?.data?.qty_analisa) -
          parseFloat(qty_permintaan_history)
        : parseFloat(modalConfig?.data?.qty_analisa);

    return parseFloat(value) > parseFloat(akumulasi) ? false : true;
  };

  useEffect(() => {
    setDataAtribut({ no_baru: 'Menunggu...' });

    PermintaanPrelimApi.getNoBaru({
      tanggal: DateConvert(new Date()).default,
    }).then((res) => setDataAtribut({ no_baru: res.data.data }));
  }, []);

  const initModalValues = {
    tgl_permintaan_prelim: DateConvert(new Date()).default,
    id_spk: id,
    qty_permintaan: '',
    qty_durasi: '',
    id_item_buaso_prelim: modalConfig?.data?.id_item_prelim,
    id_satuan_permintaan: modalConfig?.data?.id_satuan_prelim,
    id_satuan_durasi: modalConfig?.data?.id_satuan_prelim,
    harga_satuan_prelim: modalConfig?.data?.unit_price ?? 0,
  };

  const modalValidationSchema = Yup.object().shape({
    tgl_permintaan_prelim: Yup.string().required(
      'Tgl. Permintaan Prelim wajib diisi'
    ),
    // qty_permintaan: Yup.number()
    //   .typeError("Input Angka Salah")
    //   .required("Qty. Permintaan Prelim wajib diisi"),
    qty_durasi: Yup.number()
      .typeError('Input Angka Salah')
      .required('Qty. Permintaan Durasi wajib diisi'),
    qty_permintaan: Yup.number()
      .test(
        'checkQty',
        'Qty. Permintaan Prelim tidak boleh melebihi Qty. Prelim',
        (value) => checkQtyPermintaan(value)
      )
      .typeError('Input Angka Salah')
      .required('Qty. Permintaan Prelim wajib diisi'),
  });

  const modalSubmitHandler = (values, { setSubmitting, resetForm }) => {
    PermintaanPrelimApi.postData(values)
      .then(() =>
        setAlertConfig({
          show: true,
          text: 'Berhasil tambah permintaan',
          variant: 'primary',
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          text: 'Gagal tambah permintaan',
          variant: 'danger',
        })
      )
      .finally(() => {
        setSubmitting(false);
        resetForm();
        setModalConfig({ ...modalConfig, show: false });
        getInitialData();
      });
  };

  return (
    <Modal
      show={modalConfig.show}
      onHide={() => setModalConfig({ ...modalConfig, show: false })}
    >
      <Modal.Header closeButton className="py-2 d-flex align-items-center">
        <Modal.Title>
          <h6 className="mb-0 font-weight-bold">
            {' '}
            Tambah Data Permintaan Prelim{' '}
          </h6>
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={initModalValues}
        validationSchema={modalValidationSchema}
        onSubmit={modalSubmitHandler}
      >
        {(modalFormik) => {
          return (
            <form onSubmit={modalFormik.handleSubmit}>
              <Modal.Body>
                <table style={{ fontSize: '14px' }}>
                  <tbody>
                    <InfoItem
                      title="Kode Item"
                      value={modalConfig?.data?.kode_item_prelim ?? '-'}
                    />
                    <InfoItem
                      title="Item Prelim"
                      value={modalConfig?.data?.nama_item_prelim ?? '-'}
                    />
                    <InfoItem
                      title="Qty. Prelim"
                      value={
                        modalConfig?.data?.qty_analisa
                          ? `${parseFloat(
                              modalConfig.data.qty_analisa
                            ).toPrecision()} ${
                              modalConfig?.data?.nama_satuan_prelim ?? ''
                            }`
                          : '-'
                      }
                    />
                    <InfoItem
                      title="Unit Price"
                      value={
                        modalConfig?.data?.unit_price
                          ? RupiahConvert(
                              String(parseInt(modalConfig.data.unit_price))
                            ).detail
                          : 'Rp0'
                      }
                    />
                    <InfoItem
                      title="Durasi Pemakaian"
                      value={
                        modalConfig?.data?.qty_durasi
                          ? `${parseInt(modalConfig.data.qty_durasi)} Hari`
                          : '-'
                      }
                    />
                    <InfoItem
                      title="Sub Total"
                      value={
                        Boolean(
                          modalConfig?.data?.qty_analisa &&
                            modalConfig?.data?.unit_price
                        )
                          ? RupiahConvert(
                              String(
                                parseFloat(modalConfig.data.qty_analisa) *
                                  parseInt(modalConfig.data.unit_price)
                              )
                            ).detail
                          : 'Rp0'
                      }
                    />
                  </tbody>
                </table>

                <hr />

                <Row>
                  <Col md="6">
                    <Input
                      label="Tgl. Permintaan Prelim"
                      type="date"
                      name="tgl_permintaan_prelim"
                      value={modalFormik.values.tgl_permintaan_prelim}
                      onChange={(e) => {
                        const value = e.target.value;
                        modalFormik.setFieldValue(
                          'tgl_permintaan_prelim',
                          value
                        );
                        setDataAtribut({ no_baru: 'Menunggu...' });

                        PermintaanPrelimApi.getNoBaru({ tanggal: value })
                          .then((res) =>
                            setDataAtribut({ no_baru: res.data.data })
                          )
                          .catch(() => setDataAtribut({ no_baru: '' }));
                      }}
                      error={
                        modalFormik.errors.tgl_permintaan_prelim &&
                        modalFormik.touched.tgl_permintaan_prelim &&
                        true
                      }
                      errorText={modalFormik.errors.tgl_permintaan_prelim}
                    />
                  </Col>

                  <Col md="6">
                    <Input
                      label="No. Permintaan Prelim"
                      type="text"
                      name="no_permintaan"
                      value={dataAtribut.no_baru}
                      readOnly={true}
                    />
                  </Col>

                  <Col md="6">
                    <Input
                      label="Qty. Permintaan Prelim"
                      placeholder="Qty. Permintaan Prelim"
                      name="qty_permintaan"
                      value={modalFormik.values.qty_permintaan}
                      onChange={modalFormik.handleChange}
                      error={
                        modalFormik.errors.qty_permintaan &&
                        modalFormik.touched.qty_permintaan &&
                        true
                      }
                      errorText={modalFormik.errors.qty_permintaan}
                    />
                  </Col>

                  <Col md="6">
                    <Input
                      label="Satuan Permintaan Prelim"
                      type="text"
                      value={modalConfig?.data?.nama_satuan_prelim ?? '-'}
                      readOnly={true}
                    />
                  </Col>

                  <Col md="6">
                    <Input
                      label="Durasi Pemakaian"
                      placeholder="Qty. Durasi Pemakaian"
                      name="qty_durasi"
                      value={modalFormik.values.qty_durasi}
                      onChange={modalFormik.handleChange}
                      error={
                        modalFormik.errors.qty_durasi &&
                        modalFormik.touched.qty_durasi &&
                        true
                      }
                      errorText={modalFormik.errors.qty_durasi}
                    />
                  </Col>

                  <Col md="6">
                    <Input
                      label="Satuan Durasi"
                      type="text"
                      value={'Hari'}
                      readOnly={true}
                    />
                  </Col>
                </Row>
              </Modal.Body>

              <Modal.Footer className="justify-content-end">
                <ActionButton
                  variant="outline-secondary"
                  text="Batal"
                  className="px-4"
                  onClick={() =>
                    setModalConfig({ ...modalConfig, show: false })
                  }
                />

                <ActionButton
                  type="submit"
                  text="Simpan"
                  variant="primary"
                  className="m-1 px-3 text-white"
                  loading={modalFormik.isSubmitting}
                />
              </Modal.Footer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ModalPermintaanPrelim;
