import Services from '../../services';

class BuasoApi {
  getDropdownUpah(params) {
    return Services.get('/buaso/dropdown_upah', { params });
  }

  getDropdownAlatMesin(params) {
    return Services.get('/buaso/dropdown_alat', { params });
  }
}

export default new BuasoApi();
