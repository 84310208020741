import Services from 'services';

class TemplateLabourCostOnsiteApi {
  get(params) {
    return Services.get('/template_labour_cost_on_site/page/', { params });
  }
  getDropdown(params) {
    return Services.get('/template_labour_cost_on_site/dropdown', { params });
  }
  getSingle(params) {
    return Services.get('/template_labour_cost_on_site/single', { params });
  }
  create(data) {
    return Services.post('/template_labour_cost_on_site', data);
  }
  update(data) {
    return Services.put('/template_labour_cost_on_site', data);
  }
  delete(id_template_labour_cost_on_site) {
    return Services.post(
      '/template_labour_cost_on_site/delete',
      id_template_labour_cost_on_site
    );
  }
}

export default new TemplateLabourCostOnsiteApi();
