import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoItem = ({ title, value }) => (
  <tr>
    <td width="120">{title}</td>
    <td className="pl-4 pr-2">:</td>
    <td>{value}</td>
  </tr>
);

const InfoSection = ({ dataJO }) => {
  return (
    <>
      <Row>
        <Col md>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. SPK"
                value={
                  dataJO?.tgl_spk
                    ? DateConvert(new Date(dataJO?.tgl_spk)).detail
                    : '-'
                }
              />
              <InfoItem title="No. SPK" value={dataJO?.no_spk ?? '-'} />
              <InfoItem
                title="Tgl. Selesai"
                value={
                  dataJO?.tgl_selesai
                    ? DateConvert(new Date(dataJO?.tgl_selesai)).detail
                    : '-'
                }
              />
            </tbody>
          </table>
        </Col>
        <Col md>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem title="Proyek" value={dataJO?.nama_proyek ?? '-'} />
              <InfoItem title="Customer" value={dataJO?.nama_customer ?? '-'} />
              <InfoItem title="ATT" value={dataJO?.att ?? '-'} />
              <InfoItem title="Baseline" value={dataJO?.baseline ?? '-'} />
            </tbody>
          </table>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default InfoSection;
