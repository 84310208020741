import Services from 'services';

class PrelimApi {
  getKelompokPrelim(params) {
    return Services.get('/analisa_barang_jadi_rae/list_kelompok_prelim', {
      params,
    });
  }

  getItemPrelim(params) {
    return Services.get('/analisa_barang_jadi_rae/dropdown_item_prelim', {
      params,
    });
  }
}

export default new PrelimApi();
