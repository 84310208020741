import { memo, useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Modal, Nav, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import {
  ActionButton,
  Alert,
  Approval,
  BackButton,
  DataStatus,
  DatePicker,
  Input,
  TextArea,
} from 'components';
import { DateConvert } from 'utilities';
import { RAEApi } from 'api';
import {
  TabFilePeluang,
  TabGambarPeluang,
  TabInfoPeluang,
} from '../PeluangSection';
import {
  RAEContext,
  RAEContextProvider,
  DropdownRAEContext,
  DropdownAnalisaBarangJadiContext,
  DropdownRAEContextProvider,
  DropdownAnalisaBarangJadiContextProvider,
} from '../RAEContext';
import { TableBarangJadi } from '../Section';

const UbahRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const ContentUbahRAE = memo(() => {
    const { setTriggerFetch: setTriggerDropdownRAE } =
      useContext(DropdownRAEContext);
    const { setTriggerFetch: setTriggerDropdownAnalisa } = useContext(
      DropdownAnalisaBarangJadiContext
    );
    const {
      dataAnalisaBarangJadi: contextAnalisa,
      dataPrelim,
      setDataPrelim,
      setType,
    } = useContext(RAEContext);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataRAE, setDataRAE] = useState({});
    const [dataSelectAnalisaBarangJadi, setDataSelectAnalisaBarangJadi] =
      useState([]);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });
    const [modalPeluangConfig, setModalPeluangConfig] = useState({
      show: false,
      nomor: '',
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      Axios.all([RAEApi.getSingle({ id_rae: id })])
        .then(
          Axios.spread((rae) => {
            const barangJadi = rae?.data?.data?.detail ?? [];
            const prelim = rae?.data?.data?.prelim ?? [];

            const filterBarangJadi = barangJadi.map((val) => ({
              id_rae_detail: val.id_rae_detail,
              id_barang_jadi: val.id_barang_jadi,
              nama_item: val.nama_item,
              nama_pengaju: val.nama_pengaju,
              kode_item: val.kode_item,
              qty: val.qty_rae,
              satuan: val.nama_satuan,
              harga_satuan: val.harga_satuan_rae,
              has_edit: false, // frontend state if user change barang jadi
              is_edit: val.is_edit, // backend state if user already edited barang jadi
            }));

            const filterPrelim = prelim.map((val, index) => ({
              id_kelompok: val.id_kelompok,
              id_item: val.id_item_prelim,
              id_satuan_durasi: val.id_satuan_waktu,
              kode_item: val.kode_item_prelim,
              nama_item: val.nama_item_prelim,
              qty_item: val.qty_analisa,
              id_unit: val.id_satuan_prelim,
              nama_unit: val.nama_satuan_prelim,
              satuan_durasi: val.nama_satuan_waktu,
              harga: parseFloat(val.unit_price ?? 0),
              qty_durasi: parseFloat(val.qty_durasi ?? 0),
              konstanta: parseFloat(val.konstanta ?? 0),
              order: index + 1,
            }));

            setDataRAE(rae.data.data);
            setDataBarangJadi(filterBarangJadi);
            setDataPrelim(filterPrelim);

            setTriggerDropdownRAE(true);
            setTriggerDropdownAnalisa(true);
          })
        )
        .catch(() => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const mappingHardwood = (data) =>
      data.map((val) => ({
        id_barang_jadi: val.id_barang_jadi,
        urutan_item: val.urutan_item,
        id_deskripsi: val.id_deskripsi,
        is_header: val.is_header,
        qty_raw: val.qty_raw,
        t_raw: val.t_raw,
        w_raw: val.w_raw,
        l_raw: val.l_raw,
        qty_final: val.qty_final,
        t_final: val.t_final,
        w_final: val.w_final,
        l_final: val.l_final,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
        id_jenis_kayu: val.id_jenis_kayu,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
        id_part_kayu: val.id_part_kayu,
      }));

    const mappingPlywood = (data) =>
      data.map((val) => ({
        id_barang_jadi: val.id_barang_jadi,
        urutan_item: val.urutan_item,
        id_deskripsi: val.id_deskripsi,
        deskripsi: val.deskripsi,
        is_header: val.is_header,
        qty_final: val.qty_final,
        t_final: val.t_final,
        w_final: val.w_final,
        l_final: val.l_final,
        qty_raw: val.qty_raw,
        t_raw: val.t_raw,
        w_raw: val.w_raw,
        l_raw: val.l_raw,
        unit_price: val.unit_price,
        konstanta: val.konstanta,
        id_item_buaso: val.id_item_buaso,
        id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        id_tipe_sisi: val.id_tipe_sisi,
      }));

    const mappingTP = (value) => {
      const final = [];
      // eslint-disable-next-line
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_buaso,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
        });
      });

      return final;
    };

    const mappingFN = (value) => {
      const final = [];
      // eslint-disable-next-line
      value.map((val) => {
        final.push({
          id_barang_jadi: val.id_barang_jadi,
          id_item_buaso: val.id_item_bahan,
          qty: val.qty,
          unit_price: val.unit_price,
          konstanta: val.konstanta,
          id_finishing_barang_jadi: val.id_finishing_barang_jadi,
        });
      });

      return final;
    };

    const mappingPrelim = (data) =>
      // eslint-disable-next-line
      data.map((val) => ({
        id_item_buaso: val.id_item,
        qty_analisa: val.qty_item,
        unit_price: val.harga,
        qty_durasi: val.qty_durasi,
        id_satuan_durasi: val.id_satuan_durasi,
        konstanta: val.konstanta,
      }));

    const formInitialValues = {
      id_peluang: id,
      tgl_rae: dataRAE.tgl_rae,
      no_rae: dataRAE.no_rae,
      catatan: dataRAE.catatan,
    };

    const formValidationSchema = Yup.object().shape({
      tgl_rae: Yup.string().required('Pilih tanggal RAE'),
      no_rae: Yup.string().required('Pilih tanggal untuk menentukan nomor RAE'),
    });

    const formSubmitHandler = (values) => {
      const detailAnalisa = [];
      const barangJadi = [];

      contextAnalisa.map((val) =>
        detailAnalisa.push({
          index: val.index,
          analisa_hardwood: mappingHardwood(val.dataHardwood ?? []),
          analisa_plywood: mappingPlywood(val.dataPlywood ?? []),
          analisa_fin_tp_fs: mappingTP(val.dataTPFS ?? []),
          analisa_fin_tp_lc: mappingTP(val.dataTPLC ?? []),
          analisa_fin_tp_mp: mappingTP(val.dataTPMP ?? []),
          analisa_fin_tp_bop: mappingTP(val.dataTPBOP ?? []),
          analisa_fin_fn_bp: mappingFN(val.dataFNBP ?? []),
          analisa_fin_fn_sc: mappingFN(val.dataFNSC ?? []),
          analisa_fin_fn_lc: mappingFN(val.dataFNLC ?? []),
          analisa_alat_bantu_onsite: val.dataAlatBantuOnSite,
          analisa_biaya_overhead_kantor: val.dataAnalisaBOK,
          analisa_labour_cost_onsite: val.dataLabourCostOnSite,
        })
      );

      // eslint-disable-next-line
      dataBarangJadi.map((val, idx) => {
        if (val !== null) {
          barangJadi.push({
            is_edit: val.is_edit ?? false,
            id_barang_jadi: val.id_barang_jadi,
            qty_rae: val.qty,
            harga_satuan_rae: val.harga_satuan,
            id_rae_detail: val.id_rae_detail,
            analisa_barang_jadi: detailAnalisa.find((val) => val.index === idx)
              ? detailAnalisa.find((val) => val.index === idx)
              : {},
          });
        }
      });

      const finalValues = {
        id_rae: id,
        id_peluang: dataRAE.id_peluang,
        tgl_rae: values.tgl_rae,
        detail_barang_jadi: barangJadi,
        detail_prelim: mappingPrelim(dataPrelim),
        catatan: values.catatan,
      };

      if (Boolean(values.is_draft)) {
        finalValues.is_draft = true;
      } else {
        finalValues.is_draft = false;
      }

      RAEApi.save(finalValues)
        .then(() =>
          history.push('/transaksi/rae', {
            alert: {
              show: true,
              variant: 'primary',
              text: 'Data berhasil diubah!',
            },
            rae: location?.state?.rae,
            peluang_rae: location?.state?.peluang_rae,
          })
        )
        .catch(() => {
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal ditambah!',
          });
        });
    };

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formInitialValues,
      validationSchema: formValidationSchema,
      onSubmit: formSubmitHandler,
    });

    const grandTotal = () => {
      const totalBarangJadi = dataBarangJadi.reduce(
        (prevValue, currentValue) => {
          const checkQty = currentValue?.qty ? parseFloat(currentValue.qty) : 0;
          const checkSatuan = currentValue?.harga_satuan
            ? parseInt(currentValue.harga_satuan)
            : 0;
          const subTotal = parseInt(checkQty * checkSatuan);
          const total = parseInt(prevValue + subTotal);

          return total;
        },
        0
      );

      const totalPrelim = dataPrelim.reduce((prev, current) => {
        const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
        const newHarga = current.harga ? parseFloat(current.harga) : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);

      const grandTotal = totalBarangJadi + totalPrelim;

      return {
        total: totalBarangJadi,
        prelim: totalPrelim,
        grandTotal: grandTotal,
      };
    };

    const ButtonSection = () => {
      return (
        <div className="d-flex justify-content-end pt-3">
          <ActionButton
            variant="success"
            className="mr-2"
            text="Ubah Rencana Anggaran Estimasi"
            onClick={formik.handleSubmit}
            loading={formik.isSubmitting}
          />

          <ActionButton
            text="Simpan Sebagai Draft"
            onClick={() => {
              formik.setFieldValue('is_draft', true);
              formik.handleSubmit();
            }}
            loading={formik.isSubmitting}
          />
        </div>
      );
    };

    const ModalPeluang = () => {
      const [dataInfoPeluang, setDataInfoPeluang] = useState({});
      const [dataTablePeluang, setDataTablePeluang] = useState([]);
      const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
        loading: true,
        success: false,
      });
      const tabsRef = useRef();

      const [defaultTabs, setDefaultTabs] = useState('info');
      const [dataGambar, setDataGambar] = useState([]);
      const [dataFile, setDataFile] = useState([]);

      // Konfigurasi komponen tabs
      const tabsConfig = [
        {
          tab: 'info',
          label: 'Informasi Umum',
          component: ({ ...rest }) => <TabInfoPeluang {...rest} />,
        },
        {
          tab: 'gambar',
          label: 'Gambar',
          component: ({ ...rest }) => <TabGambarPeluang {...rest} />,
        },
        {
          tab: 'file',
          label: 'File',
          component: ({ ...rest }) => <TabFilePeluang {...rest} />,
        },
      ];

      // Menangani cek type dari file yang dikirim
      const checkTypeAndStoreFile = (data) => {
        let gambar = [];
        let file = [];

        data.map((val) => {
          const map = {
            link: val.path_gambar,
            id: val.id_peluang_gambar,
            nama: '',
            data: null,
          };

          const generalFileType = map.link.split('.').pop();
          const fileName = map.link.split('/').pop();

          // Check apakah data adalah gambar
          if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
            return gambar.push({ ...map, nama: fileName });
          }

          return file.push({ ...map, nama: fileName });
        });

        setDataGambar(gambar);
        setDataFile(file);
      };

      // Menangani perubahan pada tabs
      const onTabsChangeHandler = (e, tabs) => {
        e.preventDefault();
        setDefaultTabs(tabs);
      };

      const getDataRAE = (noPeluang) => {
        setFetchPeluangStatus({
          loading: true,
          success: false,
        });

        RAEApi.getDetailPeluangRAE({ no_peluang: noPeluang })
          .then((res) => {
            const dataInfo = res.data.data ?? {};
            const dataTable = res.data.data.detail ?? {};

            setDataInfoPeluang(dataInfo);
            checkTypeAndStoreFile(dataInfo?.gambar ?? []);
            setDataTablePeluang(dataTable);
            setFetchPeluangStatus({
              loading: false,
              success: true,
            });
          })
          .catch(() => {
            setFetchPeluangStatus({
              loading: false,
              success: false,
            });
          });
      };

      useEffect(() => {
        if (modalPeluangConfig.nomor) {
          getDataRAE(modalPeluangConfig.nomor);
        } else {
          setFetchPeluangStatus({
            loading: false,
            success: false,
          });
        }

        // eslint-disable-next-line
      }, []);

      if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
        return (
          <Modal
            size="xl"
            show={modalPeluangConfig.show}
            onHide={() =>
              setModalPeluangConfig({
                ...modalPeluangConfig,
                show: false,
              })
            }
          >
            <Modal.Header closeButton>
              <b>Detail Peluang</b>
            </Modal.Header>
            <Modal.Body>
              {fetchPeluangStatus.loading ? (
                <DataStatus loading text="Memuat data . . ." />
              ) : (
                <DataStatus text="Data gagal dimuat" />
              )}
            </Modal.Body>
          </Modal>
        );
      }

      return (
        <Modal
          size="xl"
          show={modalPeluangConfig.show}
          onHide={() =>
            setModalPeluangConfig({
              ...modalPeluangConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton>
            <b>Detail Peluang</b>
          </Modal.Header>
          <Modal.Body>
            <Card.Header>
              {/* Tab Header */}
              <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
                {tabsConfig.map((val, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      ref={tabsRef}
                      href={`#${val.tab}`}
                      onClick={(e) => onTabsChangeHandler(e, val.tab)}
                    >
                      {val.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
            <Card.Body>
              {tabsConfig.map(
                ({ tab, component: Component }, index) =>
                  tab === defaultTabs && (
                    <Component
                      key={index}
                      type="DETAIL"
                      dataInfoPeluang={dataInfoPeluang}
                      dataTablePeluang={dataTablePeluang}
                      dataGambar={dataGambar}
                      dataFile={dataFile}
                      setDataGambar={setDataGambar}
                      setDataFile={setDataFile}
                    />
                  )
              )}
            </Card.Body>
          </Modal.Body>
        </Modal>
      );
    };

    const InfoSection = () => {
      const InfoItem = ({ title, value, link, onClick }) => (
        <tr onClick={onClick}>
          <td>{title}</td>
          <td className={`pl-4 pr-2`}>:</td>
          <td
            className={link ? 'text-primary' : ''}
            style={{ cursor: link ? 'pointer' : 'default' }}
          >
            {value}
          </td>
        </tr>
      );

      return (
        <Row>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. Peluang"
                  value={
                    dataRAE.tgl_peluang
                      ? DateConvert(new Date(dataRAE.tgl_peluang)).detail
                      : '-'
                  }
                />
                <InfoItem
                  link
                  title="No. Peluang"
                  value={dataRAE.no_peluang ? dataRAE.no_peluang : '-'}
                  onClick={() =>
                    setModalPeluangConfig({
                      show: true,
                      nomor: dataRAE.no_peluang,
                    })
                  }
                />
                <InfoItem
                  title="Peringkat Peluang"
                  value={
                    dataRAE.nama_peringkat_peluang
                      ? dataRAE.nama_peringkat_peluang
                      : '-'
                  }
                />
                <InfoItem
                  title="Realisasi Penawaran"
                  value={
                    dataRAE.realisasi_penawaran
                      ? DateConvert(new Date(dataRAE.realisasi_penawaran))
                          .detail
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Customer"
                  value={dataRAE.nama_customer ? dataRAE.nama_customer : '-'}
                />
                <InfoItem title="ATT" value={dataRAE.att ? dataRAE.att : '-'} />
                <InfoItem
                  title="Kelompok Proyek"
                  value={
                    dataRAE.kelompok_proyek ? dataRAE.kelompok_proyek : '-'
                  }
                />
                <InfoItem
                  title="Target Penawaran"
                  value={
                    dataRAE.tgl_selesai
                      ? DateConvert(new Date(dataRAE.tgl_selesai)).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="Proyek"
                  value={dataRAE.nama_proyek ? dataRAE.nama_proyek : '-'}
                />
              </tbody>
            </table>
          </Col>
        </Row>
      );
    };

    const FormRAE = ({ formik }) => {
      const { values, errors, touched, setFieldValue } = formik;

      return (
        <Row>
          <Col sm={6}>
            <DatePicker
              label="Tanggal RAE"
              placeholderText="Pilih tanggal rae"
              selected={values.tgl_rae ? new Date(values.tgl_rae) : ''}
              onChange={(val) => {
                const value = val.toISOString().slice(0, 10);
                setFieldValue('tgl_rae', value);
                // getNomorRAE(value)
              }}
              error={errors.tgl_rae && touched.tgl_rae && true}
              errorText={errors.tgl_rae && touched.tgl_rae && errors.tgl_rae}
            />
          </Col>
          <Col sm={6}>
            <Input
              label="Nomor RAE"
              placeholder="Pilih tanggal untuk menentukan nomor RAE"
              value={values.no_rae}
              readOnly={true}
              error={errors.no_rae && touched.no_rae && true}
              errorText={errors.no_rae && touched.no_rae && errors.no_rae}
            />
          </Col>
        </Row>
      );
    };

    useEffect(() => {
      setNavbarTitle('Rencana Anggaran Estimasi (RAE)');
      getInitialData();
      setType('UPDATE');
      return () => {
        setIsPageLoading(false);
      };

      // eslint-disable-next-line
    }, []);

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Ubah Data RAE</b>
            <BackButton
              onClick={() =>
                history.push('/transaksi/rae', { ...location?.state })
              }
            />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() =>
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                })
              }
            />
            {isPageLoading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : isFetchingFailed ? (
              <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
            ) : (
              <>
                <InfoSection />
                <hr />
                <FormRAE formik={formik} />
                <hr />
                <TableBarangJadi
                  dataBarangJadi={dataBarangJadi}
                  dataSelectAnalisaBarangJadi={dataSelectAnalisaBarangJadi}
                  setDataBarangJadi={setDataBarangJadi}
                  setDataSelectAnalisaBarangJadi={
                    setDataSelectAnalisaBarangJadi
                  }
                  grandTotal={grandTotal}
                />

                <TextArea
                  label="Catatan"
                  placeholder="Masukan Catatan"
                  value={formik.values.catatan}
                  name="catatan"
                  onChange={formik.handleChange}
                  rows={5}
                />

                <ButtonSection />
              </>
            )}
          </Card.Body>
          {modalPeluangConfig.show && <ModalPeluang />}
        </Card>

        {!isPageLoading && <Approval data={dataRAE ?? { stakeholder: [] }} />}
      </>
    );
  });

  return (
    <RAEContextProvider>
      <DropdownRAEContextProvider>
        <DropdownAnalisaBarangJadiContextProvider>
          <ContentUbahRAE />
        </DropdownAnalisaBarangJadiContextProvider>
      </DropdownRAEContextProvider>
    </RAEContextProvider>
  );
};

export default UbahRAE;
