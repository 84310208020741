import { createContext, useEffect, useState } from 'react';
import { BarangJadiApi, PrelimApi } from 'api';

export const DropdownRAEContext = createContext();

export const DropdownRAEContextProvider = ({ children }) => {
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [dropdown, setDropdown] = useState({
    analisa_barang_jadi: [],
    item_prelim: [],
  });

  const [loading, setLoading] = useState({
    analisa_barang_jadi: false,
    item_prelim: false,
  });

  const fetchSelectAnalisaBarangJadi = () => {
    setLoading((prev) => ({ ...prev, analisa_barang_jadi: true }));
    BarangJadiApi.getDropdownAnalisa()
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          analisa_barang_jadi: res.data.data,
        }))
      )
      .catch(() => window.alert('Gagal memuat dropdown analisa barang jadi'))
      .finally(() =>
        setLoading((prev) => ({ ...prev, analisa_barang_jadi: false }))
      );
  };

  const fetchItemPrelim = () => {
    setLoading((prev) => ({ ...prev, item_prelim: true }));

    PrelimApi.getItemPrelim()
      .then((res) =>
        setDropdown((prev) => ({
          ...prev,
          item_prelim: res.data.data,
        }))
      )
      .catch(() => alert('Gagal memuat dropdown item prelim'))
      .finally(() => setLoading((prev) => ({ ...prev, item_prelim: false })));
  };

  useEffect(() => {
    if (triggerFetch) {
      fetchSelectAnalisaBarangJadi();
      fetchItemPrelim();
    }

    return () => {
      setDropdown({
        analisa_barang_jadi: [],
      });

      setLoading({
        analisa_barang_jadi: false,
      });
    };
  }, [triggerFetch]);

  return (
    <DropdownRAEContext.Provider
      value={{
        dropdown,
        setDropdown,
        loading,
        setLoading,
        setTriggerFetch,
      }}
    >
      {children}
    </DropdownRAEContext.Provider>
  );
};
