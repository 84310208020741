import Services from '../../services';

class LaporanRAEApi {
  getPage(params) {
    return Services.get('/laporan_rae/page', { params });
  }

  getSingle(params) {
    return Services.get('/laporan_rae/single', { params });
  }

  getSingleExport(params) {
    return Services.get('/rae_report/barang_jadi', { params });
  }

  getExport(params) {
    return Services.get('/rae_report/laporan', { params });
  }

  getDropdown(params) {
    return Services.get('/laporan_rae/dropdown', { params });
  }

  getSingleExportPrelim(params) {
    return Services.get('/rae_report/prelim', { params });
  }
}

export default new LaporanRAEApi();
