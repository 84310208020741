import { memo, useContext, useEffect, useRef, useState } from 'react';
import { Card, Col, Modal, Nav, Row } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Axios from 'axios';
import {
  Alert,
  Approval,
  BackButton,
  DataStatus,
  InfoItemVertical,
} from 'components';
import { DateConvert } from 'utilities';
import { RAEApi } from 'api';
import {
  TabFilePeluang,
  TabGambarPeluang,
  TabInfoPeluang,
} from '../PeluangSection';
import {
  DropdownAnalisaBarangJadiContext,
  DropdownAnalisaBarangJadiContextProvider,
  DropdownRAEContext,
  DropdownRAEContextProvider,
  RAEContext,
  RAEContextProvider,
} from '../RAEContext';
import { TableBarangJadi } from '../Section';

const UbahRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const ContentUbahRAE = memo(() => {
    const { setTriggerFetch: setTriggerDropdownRAE } =
      useContext(DropdownRAEContext);
    const { setTriggerFetch: setTriggerDropdownAnalisa } = useContext(
      DropdownAnalisaBarangJadiContext
    );
    const { dataPrelim, setDataPrelim, setType } = useContext(RAEContext);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isFetchingFailed, setIsFetchingFailed] = useState(false);
    const [dataRAE, setDataRAE] = useState({});
    const [dataSelectJenisBarangJadi, setDataSelectJenisBarangJadi] = useState(
      []
    );
    const [dataSelectAnalisaBarangJadi, setDataSelectAnalisaBarangJadi] =
      useState([]);
    const [dataBarangJadi, setDataBarangJadi] = useState([]);
    const [alertConfig, setAlertConfig] = useState({
      show: false,
      variant: 'primary',
      text: '',
    });
    const [modalPeluangConfig, setModalPeluangConfig] = useState({
      show: false,
      nomor: '',
    });

    const getInitialData = () => {
      setIsPageLoading(true);

      Axios.all([RAEApi.getSingle({ id_rae: id })])
        .then(
          Axios.spread((rae) => {
            const barangJadi = rae.data.data.detail ? rae.data.data.detail : [];
            const prelim = rae.data.data.prelim ? rae.data.data.prelim : [];

            const filterBarangJadi = barangJadi.map((val) => ({
              id_rae_detail: val.id_rae_detail,
              id_barang_jadi: val.id_barang_jadi,
              nama_item: val.nama_item,
              kode_item: val.kode_item,
              qty: val.qty_rae,
              satuan: val.nama_satuan,
              harga_satuan: val.harga_satuan_rae,
              has_edit: false,
              is_edit: val.is_edit,
              nama_pengaju: val.nama_pengaju,
            }));

            const filterPrelim = prelim.map((val, index) => ({
              id_kelompok: val.id_kelompok,
              id_item: val.id_item_prelim,
              id_satuan_durasi: val.id_satuan_waktu,
              kode_item: val.kode_item_prelim,
              nama_item: val.nama_item_prelim,
              qty_item: val.qty_analisa,
              id_unit: val.id_satuan_prelim,
              nama_unit: val.nama_satuan_prelim,
              satuan_durasi: val.nama_satuan_waktu,
              harga: parseFloat(val.unit_price ?? 0),
              qty_durasi: parseFloat(val.qty_durasi ?? 0),
              konstanta: parseFloat(val.konstanta ?? 0),
              order: index + 1,
            }));

            setDataRAE(rae.data.data);
            setDataPrelim(filterPrelim);
            setDataBarangJadi(filterBarangJadi);

            setTriggerDropdownRAE(true);
            setTriggerDropdownAnalisa(true);
          })
        )
        .catch(() => {
          setIsFetchingFailed(true);
          setAlertConfig({
            show: true,
            variant: 'danger',
            text: 'Data gagal dimuat!',
          });
        })
        .finally(() => setIsPageLoading(false));
    };

    const grandTotal = () => {
      const totalBarangJadi = dataBarangJadi.reduce(
        (prevValue, currentValue) => {
          const checkQty = currentValue.qty ? parseFloat(currentValue.qty) : 0;
          const checkSatuan = currentValue.harga_satuan
            ? parseInt(currentValue.harga_satuan)
            : 0;
          const subTotal = parseInt(checkQty * checkSatuan);
          const total = parseInt(prevValue + subTotal);

          return total;
        },
        0
      );

      const totalPrelim = dataPrelim.reduce((prev, current) => {
        const newQtyItem = current.qty_item ? parseFloat(current.qty_item) : 0;
        const newHarga = current.harga ? parseFloat(current.harga) : 0;
        const newQtyDurasi = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newKonts = current.konstanta ? parseFloat(current.konstanta) : 0;
        const subTotal = newQtyItem * newHarga;
        // const total = subTotal * newQtyDurasi * newKonts
        const total = subTotal;

        return prev + total;
      }, 0);

      const grandTotal = totalBarangJadi + totalPrelim;

      return {
        total: totalBarangJadi,
        prelim: totalPrelim,
        grandTotal: grandTotal,
      };
    };

    const ModalPeluang = () => {
      const [dataInfoPeluang, setDataInfoPeluang] = useState({});
      const [dataTablePeluang, setDataTablePeluang] = useState([]);
      const [fetchPeluangStatus, setFetchPeluangStatus] = useState({
        loading: true,
        success: false,
      });
      const tabsRef = useRef();

      const [defaultTabs, setDefaultTabs] = useState('info');
      const [dataGambar, setDataGambar] = useState([]);
      const [dataFile, setDataFile] = useState([]);

      // Konfigurasi komponen tabs
      const tabsConfig = [
        {
          tab: 'info',
          label: 'Informasi Umum',
          component: ({ ...rest }) => <TabInfoPeluang {...rest} />,
        },
        {
          tab: 'gambar',
          label: 'Gambar',
          component: ({ ...rest }) => <TabGambarPeluang {...rest} />,
        },
        {
          tab: 'file',
          label: 'File',
          component: ({ ...rest }) => <TabFilePeluang {...rest} />,
        },
      ];

      // Menangani cek type dari file yang dikirim
      const checkTypeAndStoreFile = (data) => {
        let gambar = [];
        let file = [];

        data.map((val) => {
          const map = {
            link: val.path_gambar,
            id: val.id_peluang_gambar,
            nama: '',
            data: null,
          };

          const generalFileType = map.link.split('.').pop();
          const fileName = map.link.split('/').pop();

          // Check apakah data adalah gambar
          if (['jpg', 'png', 'gif', 'jpeg'].includes(generalFileType)) {
            return gambar.push({ ...map, nama: fileName });
          }

          return file.push({ ...map, nama: fileName });
        });

        setDataGambar(gambar);
        setDataFile(file);
      };

      // Menangani perubahan pada tabs
      const onTabsChangeHandler = (e, tabs) => {
        e.preventDefault();
        setDefaultTabs(tabs);
      };

      const getDataRAE = (noPeluang) => {
        setFetchPeluangStatus({
          loading: true,
          success: false,
        });

        RAEApi.getDetailPeluangRAE({ no_peluang: noPeluang })
          .then((res) => {
            const dataInfo = res.data.data ?? {};
            const dataTable = res.data.data.detail ?? {};

            setDataInfoPeluang(dataInfo);
            checkTypeAndStoreFile(dataInfo?.gambar ?? []);
            setDataTablePeluang(dataTable);
            setFetchPeluangStatus({
              loading: false,
              success: true,
            });
          })
          .catch(() => {
            setFetchPeluangStatus({
              loading: false,
              success: false,
            });
          });
      };

      useEffect(() => {
        if (modalPeluangConfig.nomor) {
          getDataRAE(modalPeluangConfig.nomor);
        } else {
          setFetchPeluangStatus({
            loading: false,
            success: false,
          });
        }

        // eslint-disable-next-line
      }, []);

      if (fetchPeluangStatus.loading || !fetchPeluangStatus.success) {
        return (
          <Modal
            size="xl"
            show={modalPeluangConfig.show}
            onHide={() =>
              setModalPeluangConfig({
                ...modalPeluangConfig,
                show: false,
              })
            }
          >
            <Modal.Header closeButton>
              <b>Detail Peluang</b>
            </Modal.Header>
            <Modal.Body>
              {fetchPeluangStatus.loading ? (
                <DataStatus loading text="Memuat data . . ." />
              ) : (
                <DataStatus text="Data gagal dimuat" />
              )}
            </Modal.Body>
          </Modal>
        );
      }

      return (
        <Modal
          size="xl"
          show={modalPeluangConfig.show}
          onHide={() =>
            setModalPeluangConfig({
              ...modalPeluangConfig,
              show: false,
            })
          }
        >
          <Modal.Header closeButton>
            <b>Detail Peluang</b>
          </Modal.Header>
          <Modal.Body>
            <Card.Header>
              {/* Tab Header */}
              <Nav variant="tabs" defaultActiveKey={`#${defaultTabs}`}>
                {tabsConfig.map((val, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      ref={tabsRef}
                      href={`#${val.tab}`}
                      onClick={(e) => onTabsChangeHandler(e, val.tab)}
                    >
                      {val.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Card.Header>
            <Card.Body>
              {tabsConfig.map(
                ({ tab, component: Component }, index) =>
                  tab === defaultTabs && (
                    <Component
                      key={index}
                      type="DETAIL"
                      dataInfoPeluang={dataInfoPeluang}
                      dataTablePeluang={dataTablePeluang}
                      dataGambar={dataGambar}
                      dataFile={dataFile}
                      setDataGambar={setDataGambar}
                      setDataFile={setDataFile}
                    />
                  )
              )}
            </Card.Body>
          </Modal.Body>
        </Modal>
      );
    };

    const InfoSection = () => {
      const InfoItem = ({ title, value, link, onClick }) => (
        <tr onClick={onClick}>
          <td>{title}</td>
          <td className={`pl-4 pr-2`}>:</td>
          <td
            className={link ? 'text-primary' : ''}
            style={{ cursor: link ? 'pointer' : 'default' }}
          >
            {value}
          </td>
        </tr>
      );

      return (
        <Row>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Tgl. RAE"
                  value={
                    dataRAE.tgl_rae
                      ? DateConvert(new Date(dataRAE.tgl_rae)).detail
                      : '-'
                  }
                />

                <InfoItem title="No. RAE" value={dataRAE.no_rae ?? '-'} />

                <InfoItem
                  title="Tgl. Peluang"
                  value={
                    dataRAE.tgl_peluang
                      ? DateConvert(new Date(dataRAE.tgl_peluang)).detail
                      : '-'
                  }
                />
                <InfoItem
                  link
                  title="No. Peluang"
                  value={dataRAE.no_peluang ? dataRAE.no_peluang : '-'}
                  onClick={() =>
                    setModalPeluangConfig({
                      show: true,
                      nomor: dataRAE.no_peluang,
                    })
                  }
                />
                <InfoItem
                  title="Peringkat Peluang"
                  value={
                    dataRAE.nama_peringkat_peluang
                      ? dataRAE.nama_peringkat_peluang
                      : '-'
                  }
                />
                <InfoItem
                  title="Realisasi Penawaran"
                  value={
                    dataRAE.realisasi_penawaran
                      ? DateConvert(new Date(dataRAE.realisasi_penawaran))
                          .detail
                      : '-'
                  }
                />
              </tbody>
            </table>
          </Col>
          <Col>
            <table style={{ fontSize: '14px' }}>
              <tbody>
                <InfoItem
                  title="Customer"
                  value={dataRAE.nama_customer ? dataRAE.nama_customer : '-'}
                />
                <InfoItem title="ATT" value={dataRAE.att ? dataRAE.att : '-'} />
                <InfoItem
                  title="Kelompok Proyek"
                  value={
                    dataRAE.kelompok_proyek ? dataRAE.kelompok_proyek : '-'
                  }
                />
                <InfoItem
                  title="Target Penawaran"
                  value={
                    dataRAE.tgl_selesai
                      ? DateConvert(new Date(dataRAE.tgl_selesai)).detail
                      : '-'
                  }
                />
                <InfoItem
                  title="Schedule Kebutuhan Proyek (Total Hari)"
                  value={
                    dataRAE.schedule_kebutuhan_proyek
                      ? dataRAE.schedule_kebutuhan_proyek
                      : '-'
                  }
                />
                <InfoItem
                  title="Proyek"
                  value={dataRAE.nama_proyek ? dataRAE.nama_proyek : '-'}
                />
              </tbody>
            </table>
          </Col>
        </Row>
      );
    };

    useEffect(() => {
      setNavbarTitle('Rencana Anggaran Estimasi (RAE)');
      getInitialData();
      setType('DETAIL');

      return () => {
        setIsPageLoading(false);
      };

      // eslint-disable-next-line
    }, []);

    return (
      <>
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <b>Detail Data RAE</b>
            <BackButton
              onClick={() =>
                history.push('/transaksi/rae', { ...location?.state })
              }
            />
          </Card.Header>
          <Card.Body>
            <Alert
              showCloseButton
              show={alertConfig.show}
              variant={alertConfig.variant}
              text={alertConfig.text}
              onClose={() =>
                setAlertConfig({
                  ...alertConfig,
                  show: false,
                })
              }
            />
            {isPageLoading ? (
              <DataStatus loading={true} text="Memuat data . . ." />
            ) : isFetchingFailed ? (
              <DataStatus text="Data gagal dimuat, tidak dapat menambah data!" />
            ) : (
              <>
                <InfoSection />
                <hr />
                <TableBarangJadi
                  dataBarangJadi={dataBarangJadi}
                  dataSelectJenisBarangJadi={dataSelectJenisBarangJadi}
                  dataSelectAnalisaBarangJadi={dataSelectAnalisaBarangJadi}
                  setDataBarangJadi={setDataBarangJadi}
                  setDataSelectJenisBarangJadi={setDataSelectJenisBarangJadi}
                  setDataSelectAnalisaBarangJadi={
                    setDataSelectAnalisaBarangJadi
                  }
                  grandTotal={grandTotal}
                />

                <InfoItemVertical
                  label="Catatan"
                  text={
                    <pre
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: 13,
                        lineHeight: 1.7,
                      }}
                    >
                      {dataRAE.catatan ?? '-'}
                    </pre>
                  }
                />
              </>
            )}
          </Card.Body>
          {modalPeluangConfig.show && <ModalPeluang />}
        </Card>

        {!isPageLoading && <Approval data={dataRAE} />}
      </>
    );
  });

  return (
    <RAEContextProvider>
      <DropdownRAEContextProvider>
        <DropdownAnalisaBarangJadiContextProvider>
          <ContentUbahRAE />
        </DropdownAnalisaBarangJadiContextProvider>
      </DropdownRAEContextProvider>
    </RAEContextProvider>
  );
};

export default UbahRAE;
