import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ButtonGroup, Col } from 'react-bootstrap';
import { IoAdd, IoEyeOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
} from 'components';
import { useIsGuest } from 'hooks';
import { DateConvert, TableNumber } from 'utilities';
import { PermintaanPrelimApi } from 'api';

const ListSPKPermintaanPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const isGuest = useIsGuest();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataSPK, setDataSPK] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    PermintaanPrelimApi.spk_page({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setDataSPK(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        if (searchConfig.key !== '') {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchConfig.key}`,
          });
        } else {
          setAlertConfig({
            show: false,
          });
        }
        setIsPageLoading(false);
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Prelim');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return <li index={index}>{brg.nama_item}</li>;
            } else {
              return (
                index < 2 && (
                  <li key={index} index={index}>
                    {brg.nama_item}
                  </li>
                )
              );
            }
          })}
        </ul>

        {data.length > 2 && (
          <div
            className="text-primary"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setisReadMoreClick((prev) => !prev)}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Informasi SPK</ThFixed>
            <Th>Customer</Th>
            <Th>Nama Proyek</Th>
            <Th>ATT</Th>
            <ThFixed>Tgl. Selesai</ThFixed>
            <Th>Barang Jadi</Th>
            <ThFixed>Baseline</ThFixed>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {dataSPK.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <Td>
                <div>
                  {' '}
                  {val.tgl_spk
                    ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                    : '-'}{' '}
                </div>
                <div> {val.no_spk ?? '-'} </div>
              </Td>
              <Td>{val?.nama_customer}</Td>
              <Td>{val.nama_proyek ?? '-'}</Td>
              <Td>{val?.att}</Td>
              <Td>
                {' '}
                {val.tgl_selesai
                  ? DateConvert(new Date(val.tgl_selesai)).defaultDMY
                  : '-'}{' '}
              </Td>
              <Td>
                {val?.barang_jadi?.length > 0 && (
                  <BarangJadiCollapse data={val.barang_jadi} />
                )}
              </Td>
              <Td>{val?.baseline ?? 'null'}</Td>
              <Td>
                <ButtonGroup>
                  <ActionButton
                    tooltip
                    tooltipText="Detail SPK"
                    variant="info"
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/transaksi/permintaan-prelim/detail-spk/${val.id_spk}`
                      )
                    }
                  >
                    <IoEyeOutline />
                  </ActionButton>

                  {!isGuest && (
                    <ActionButton
                      size="sm"
                      tooltip
                      tooltipText="Buat permintaan prelim"
                      onClick={() =>
                        history.push(
                          `/transaksi/permintaan-prelim/tambah/${val.id_spk}`
                        )
                      }
                    >
                      <IoAdd />
                    </ActionButton>
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <div className="mt-2 mb-1">
          <b>List SPK Yang Siap Dibuatkan Permintaan Prelim</b>
        </div>
        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({ ...paginationConfig, page: selected + 1 })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setPaginationConfig((prev) => ({ ...prev, page: 1 }));
                  setSearchConfig((prev) => ({ ...prev, key: e.target.value }));
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataSPK ? (
        dataSPK.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default ListSPKPermintaanPrelim;
