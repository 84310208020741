import { useState, useEffect, useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Axios from 'axios';
import { RealisasiPrelimApi } from 'api';
import { Td, Table, Th, ThFixed, DataStatus } from 'components';
import { RupiahConvert } from 'utilities';

const ModalPrelim = ({ show, onHide, onSubmit, dataPeluang, dataJO, data }) => {
  const grandTotal = () => {
    const totalATK = dataJO?.prelim
      ?.filter((val) => val.nama_kelompok === 'ATK')
      .reduce((prev, current) => {
        const newQtyItem = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newHarga = current.unit_price
          ? parseFloat(current.unit_price)
          : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);
    const totalSafety = dataJO?.prelim
      ?.filter((val) => val.nama_kelompok === 'Safety')
      .reduce((prev, current) => {
        const newQtyItem = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newHarga = current.unit_price
          ? parseFloat(current.unit_price)
          : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);
    const totalProteksi = dataJO?.prelim
      ?.filter((val) => val.nama_kelompok === 'Proteksi')
      .reduce((prev, current) => {
        const newQtyItem = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newHarga = current.unit_price
          ? parseFloat(current.unit_price)
          : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);
    const totalHandTools = dataJO?.prelim
      ?.filter((val) => val.nama_kelompok === 'Hand Tools')
      .reduce((prev, current) => {
        const newQtyItem = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newHarga = current.unit_price
          ? parseFloat(current.unit_price)
          : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);
    const totalHonorStaff = dataJO?.prelim
      ?.filter((val) => val.nama_kelompok === 'Honor Staff')
      .reduce((prev, current) => {
        const newQtyItem = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newHarga = current.unit_price
          ? parseFloat(current.unit_price)
          : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);
    const totalLainLain = dataJO?.prelim
      ?.filter((val) => val.nama_kelompok === 'Lain-lain')
      .reduce((prev, current) => {
        const newQtyItem = current.qty_durasi
          ? parseFloat(current.qty_durasi)
          : 0;
        const newHarga = current.unit_price
          ? parseFloat(current.unit_price)
          : 0;
        const subTotal = newQtyItem * newHarga;
        const total = subTotal;

        return prev + total;
      }, 0);

    const grandTotal =
      totalATK +
      totalSafety +
      totalProteksi +
      totalHandTools +
      totalHonorStaff +
      totalLainLain;

    return {
      prelim_atk: totalATK,
      prelim_safety: totalSafety,
      prelim_proteksi: totalProteksi,
      prelim_hand_tools: totalHandTools,
      prelim_honor_staff: totalHonorStaff,
      prelim_lain_lain: totalLainLain,
      grand_total: grandTotal,
    };
  };

  return (
    <Modal size="xl" show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <b className="text-primary">Analisa Prelim</b>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="p-1">
            <b>List Item Prelim Kelompok ATK</b>
          </div>
          <Table>
            <thead className="bg-light">
              <tr>
                <ThFixed>No</ThFixed>
                <Th noPadding width={80}>
                  Kode Item Prelim
                </Th>
                <Th width={250}>Item Prelim</Th>
                <Th noPadding width={60}>
                  {' '}
                  Qty. Item
                </Th>
                <Th noPadding width={150}>
                  Unit
                </Th>
                <Th noPadding width={150}>
                  Unit Price
                </Th>
                <Th noPadding width={100}>
                  Qty. Durasi (Hari)
                </Th>
                <Th noPadding width={150}>
                  Sub Total
                </Th>
              </tr>
            </thead>
            <tbody>
              {dataJO?.prelim?.filter((val) => val.nama_kelompok === 'ATK')
                .length > 0 ? (
                dataJO?.prelim
                  ?.filter((val) => val.nama_kelompok === 'ATK')
                  .map((val, index) => {
                    return (
                      <>
                        <tr>
                          <Td textCenter>{index + 1}</Td>
                          <Td>{val.kode_item_prelim ?? '-'}</Td>
                          <Td>{val.nama_item_prelim ?? '-'}</Td>
                          <Td textRight>
                            {parseFloat(val.qty_analisa).toPrecision()}
                          </Td>
                          <Td>{val.nama_satuan_prelim}</Td>
                          <Td textRight>
                            {RupiahConvert(
                              String(
                                val.unit_price ? parseInt(val.unit_price) : 0
                              )
                            ).detail ?? 'Rp. 0'}
                          </Td>
                          <Td textRight>
                            {val.qty_durasi
                              ? parseFloat(val.qty_durasi).toPrecision()
                              : '0'}
                          </Td>
                          <Td textRight>
                            {val.unit_price
                              ? RupiahConvert(
                                  String(
                                    parseInt(
                                      val.unit_price *
                                        parseFloat(val.qty_durasi)
                                    )
                                  )
                                ).detail
                              : 'Rp. 0'}
                          </Td>
                        </tr>
                      </>
                    );
                  })
              ) : (
                <tr>
                  <Td colSpan={12}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
              <tr>
                <Td textRight colSpan={7}>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(String(parseInt(grandTotal().prelim_atk)))
                      .detail
                  }
                </Td>
              </tr>
            </tbody>
          </Table>
          <div className="p-1">
            <b>List Item Prelim Kelompok Safety</b>
          </div>
          <Table>
            <thead className="bg-light">
              <tr>
                <ThFixed>No</ThFixed>
                <Th noPadding width={80}>
                  Kode Item Prelim
                </Th>
                <Th width={250}>Item Prelim</Th>
                <Th noPadding width={60}>
                  {' '}
                  Qty. Item
                </Th>
                <Th noPadding width={150}>
                  Unit
                </Th>
                <Th noPadding width={150}>
                  Unit Price
                </Th>
                <Th noPadding width={100}>
                  Qty. Durasi (Hari)
                </Th>
                <Th noPadding width={150}>
                  Sub Total
                </Th>
              </tr>
            </thead>
            <tbody>
              {dataJO?.prelim?.filter((val) => val.nama_kelompok === 'Safety')
                .length > 0 ? (
                dataJO?.prelim
                  ?.filter((val) => val.nama_kelompok === 'Safety')
                  .map((val, index) => {
                    return (
                      <>
                        <tr>
                          <Td textCenter>{index + 1}</Td>
                          <Td>{val.kode_item_prelim ?? '-'}</Td>
                          <Td>{val.nama_item_prelim ?? '-'}</Td>
                          <Td textRight>1</Td>
                          <Td>{val.nama_satuan_prelim}</Td>
                          <Td textRight>
                            {RupiahConvert(
                              String(
                                val.unit_price ? parseInt(val.unit_price) : 0
                              )
                            ).detail ?? 'Rp. 0'}
                          </Td>
                          <Td textRight>
                            {val.qty_durasi
                              ? parseFloat(val.qty_durasi).toPrecision()
                              : '0'}
                          </Td>
                          <Td textRight>
                            {val.unit_price
                              ? RupiahConvert(
                                  String(
                                    parseInt(
                                      val.unit_price *
                                        parseFloat(val.qty_durasi)
                                    )
                                  )
                                ).detail
                              : 'Rp. 0'}
                          </Td>
                        </tr>
                      </>
                    );
                  })
              ) : (
                <tr>
                  <Td colSpan={12}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
              <tr>
                <Td textRight colSpan={7}>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(String(parseInt(grandTotal().prelim_safety)))
                      .detail
                  }
                </Td>
              </tr>
            </tbody>
          </Table>
          <div className="p-1">
            <b>List Item Prelim Kelompok Proteksi</b>
          </div>
          <Table>
            <thead className="bg-light">
              <tr>
                <ThFixed>No</ThFixed>
                <Th noPadding width={80}>
                  Kode Item Prelim
                </Th>
                <Th width={250}>Item Prelim</Th>
                <Th noPadding width={60}>
                  {' '}
                  Qty. Item
                </Th>
                <Th noPadding width={150}>
                  Unit
                </Th>
                <Th noPadding width={150}>
                  Unit Price
                </Th>
                <Th noPadding width={100}>
                  Qty. Durasi (Hari)
                </Th>
                <Th noPadding width={150}>
                  Sub Total
                </Th>
              </tr>
            </thead>
            <tbody>
              {dataJO?.prelim?.filter((val) => val.nama_kelompok === 'Proteksi')
                .length > 0 ? (
                dataJO?.prelim
                  ?.filter((val) => val.nama_kelompok === 'Proteksi')
                  .map((val, index) => {
                    return (
                      <>
                        <tr>
                          <Td textCenter>{index + 1}</Td>
                          <Td>{val.kode_item_prelim ?? '-'}</Td>
                          <Td>{val.nama_item_prelim ?? '-'}</Td>
                          <Td textRight>1</Td>
                          <Td>{val.nama_satuan_prelim}</Td>
                          <Td textRight>
                            {RupiahConvert(
                              String(
                                val.unit_price ? parseInt(val.unit_price) : 0
                              )
                            ).detail ?? 'Rp. 0'}
                          </Td>
                          <Td textRight>
                            {val.qty_durasi
                              ? parseFloat(val.qty_durasi).toPrecision()
                              : '0'}
                          </Td>
                          <Td textRight>
                            {val.unit_price
                              ? RupiahConvert(
                                  String(
                                    parseInt(
                                      val.unit_price *
                                        parseFloat(val.qty_durasi)
                                    )
                                  )
                                ).detail
                              : 'Rp. 0'}
                          </Td>
                        </tr>
                      </>
                    );
                  })
              ) : (
                <tr>
                  <Td colSpan={12}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
              <tr>
                <Td textRight colSpan={7}>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(parseInt(grandTotal().prelim_proteksi))
                    ).detail
                  }
                </Td>
              </tr>
            </tbody>
          </Table>
          <div className="p-1">
            <b>List Item Prelim Kelompok Hand Tools</b>
          </div>
          <Table>
            <thead className="bg-light">
              <tr>
                <ThFixed>No</ThFixed>
                <Th noPadding width={80}>
                  Kode Item Prelim
                </Th>
                <Th width={250}>Item Prelim</Th>
                <Th noPadding width={60}>
                  {' '}
                  Qty. Item
                </Th>
                <Th noPadding width={150}>
                  Unit
                </Th>
                <Th noPadding width={150}>
                  Unit Price
                </Th>
                <Th noPadding width={100}>
                  Qty. Durasi (Hari)
                </Th>
                <Th noPadding width={150}>
                  Sub Total
                </Th>
              </tr>
            </thead>
            <tbody>
              {dataJO?.prelim?.filter(
                (val) => val.nama_kelompok === 'Hand Tools'
              ).length > 0 ? (
                dataJO?.prelim
                  ?.filter((val) => val.nama_kelompok === 'Hand Tools')
                  .map((val, index) => {
                    return (
                      <>
                        <tr>
                          <Td textCenter>{index + 1}</Td>
                          <Td>{val.kode_item_prelim ?? '-'}</Td>
                          <Td>{val.nama_item_prelim ?? '-'}</Td>
                          <Td textRight>1</Td>
                          <Td>{val.nama_satuan_prelim}</Td>
                          <Td textRight>
                            {RupiahConvert(
                              String(
                                val.unit_price ? parseInt(val.unit_price) : 0
                              )
                            ).detail ?? 'Rp. 0'}
                          </Td>
                          <Td textRight>
                            {val.qty_durasi
                              ? parseFloat(val.qty_durasi).toPrecision()
                              : '0'}
                          </Td>
                          <Td textRight>
                            {val.unit_price
                              ? RupiahConvert(
                                  String(
                                    parseInt(
                                      val.unit_price *
                                        parseFloat(val.qty_durasi)
                                    )
                                  )
                                ).detail
                              : 'Rp. 0'}
                          </Td>
                        </tr>
                      </>
                    );
                  })
              ) : (
                <tr>
                  <Td colSpan={12}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
              <tr>
                <Td textRight colSpan={7}>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(parseInt(grandTotal().prelim_hand_tools))
                    ).detail
                  }
                </Td>
              </tr>
            </tbody>
          </Table>
          <div className="p-1">
            <b>List Item Prelim Kelompok Honor Staff</b>
          </div>
          <Table>
            <thead className="bg-light">
              <tr>
                <ThFixed>No</ThFixed>
                <Th noPadding width={80}>
                  Kode Item Prelim
                </Th>
                <Th width={250}>Item Prelim</Th>
                <Th noPadding width={60}>
                  {' '}
                  Qty. Item
                </Th>
                <Th noPadding width={150}>
                  Unit
                </Th>
                <Th noPadding width={150}>
                  Unit Price
                </Th>
                <Th noPadding width={100}>
                  Qty. Durasi (Hari)
                </Th>
                <Th noPadding width={150}>
                  Sub Total
                </Th>
              </tr>
            </thead>
            <tbody>
              {dataJO?.prelim?.filter(
                (val) => val.nama_kelompok === 'Honor Staff'
              ).length > 0 ? (
                dataJO?.prelim
                  ?.filter((val) => val.nama_kelompok === 'Honor Staff')
                  .map((val, index) => {
                    return (
                      <>
                        <tr>
                          <Td textCenter>{index + 1}</Td>
                          <Td>{val.kode_item_prelim ?? '-'}</Td>
                          <Td>{val.nama_item_prelim ?? '-'}</Td>
                          <Td textRight>1</Td>
                          <Td>{val.nama_satuan_prelim}</Td>
                          <Td textRight>
                            {RupiahConvert(
                              String(
                                val.unit_price ? parseInt(val.unit_price) : 0
                              )
                            ).detail ?? 'Rp. 0'}
                          </Td>
                          <Td textRight>
                            {val.qty_durasi
                              ? parseFloat(val.qty_durasi).toPrecision()
                              : '0'}
                          </Td>
                          <Td textRight>
                            {val.unit_price
                              ? RupiahConvert(
                                  String(
                                    parseInt(
                                      val.unit_price *
                                        parseFloat(val.qty_durasi)
                                    )
                                  )
                                ).detail
                              : 'Rp. 0'}
                          </Td>
                        </tr>
                      </>
                    );
                  })
              ) : (
                <tr>
                  <Td colSpan={12}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
              <tr>
                <Td textRight colSpan={7}>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(parseInt(grandTotal().prelim_honor_staff))
                    ).detail
                  }
                </Td>
              </tr>
            </tbody>
          </Table>
          <div className="p-1">
            <b>List Item Prelim Kelompok Lain-lain</b>
          </div>
          <Table>
            <thead className="bg-light">
              <tr>
                <ThFixed>No</ThFixed>
                <Th noPadding width={80}>
                  Kode Item Prelim
                </Th>
                <Th width={250}>Item Prelim</Th>
                <Th noPadding width={60}>
                  {' '}
                  Qty. Item
                </Th>
                <Th noPadding width={150}>
                  Unit
                </Th>
                <Th noPadding width={150}>
                  Unit Price
                </Th>
                <Th noPadding width={100}>
                  Qty. Durasi (Hari)
                </Th>
                <Th noPadding width={150}>
                  Sub Total
                </Th>
              </tr>
            </thead>
            <tbody>
              {dataJO?.prelim?.filter(
                (val) => val.nama_kelompok === 'Lain-lain'
              ).length > 0 ? (
                dataJO?.prelim
                  ?.filter((val) => val.nama_kelompok === 'Lain-lain')
                  .map((val, index) => {
                    return (
                      <>
                        <tr>
                          <Td textCenter>{index + 1}</Td>
                          <Td>{val.kode_item_prelim ?? '-'}</Td>
                          <Td>{val.nama_item_prelim ?? '-'}</Td>
                          <Td textRight>1</Td>
                          <Td>{val.nama_satuan_prelim}</Td>
                          <Td textRight>
                            {RupiahConvert(
                              String(
                                val.unit_price ? parseInt(val.unit_price) : 0
                              )
                            ).detail ?? 'Rp. 0'}
                          </Td>
                          <Td textRight>
                            {val.qty_durasi
                              ? parseFloat(val.qty_durasi).toPrecision()
                              : '0'}
                          </Td>
                          <Td textRight>
                            {val.unit_price
                              ? RupiahConvert(
                                  String(
                                    parseInt(
                                      val.unit_price *
                                        parseFloat(val.qty_durasi)
                                    )
                                  )
                                ).detail
                              : 'Rp. 0'}
                          </Td>
                        </tr>
                      </>
                    );
                  })
              ) : (
                <tr>
                  <Td colSpan={12}>
                    <DataStatus text="Tidak ada data" />
                  </Td>
                </tr>
              )}
              <tr>
                <Td textRight colSpan={7}>
                  <b>Total</b>
                </Td>
                <Td textRight>
                  {
                    RupiahConvert(
                      String(parseInt(grandTotal().prelim_lain_lain))
                    ).detail
                  }
                </Td>
              </tr>
            </tbody>
          </Table>
          <hr />
          <div className="text-right p-2 border" style={{ fontSize: 14 }}>
            <b>Grand Total : </b>
            <b className="ml-3">
              {RupiahConvert(String(parseInt(grandTotal().grand_total)))
                .detail ?? 'Rp. 0'}
            </b>
          </div>
          <hr />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPrelim;
