// React
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Component
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  CreateButton,
} from 'components';
import { DateConvert, TableNumber, RupiahConvert } from 'utilities';
import { ButtonGroup, Col } from 'react-bootstrap';
import { IoAdd, IoEyeOutline } from 'react-icons/io5';

// API
import { RealisasiPrelimApi } from 'api';
import { useIsGuest } from 'hooks';

const ListRealisasiPrelim = ({ setNavbarTitle }) => {
  const title = 'Realisasi Prelim';
  const history = useHistory();
  const isGuest = useIsGuest();

  // STATES
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  console.log(data);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RealisasiPrelimApi.getPageRealisasiPrelim({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        if (searchConfig.key !== '') {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchConfig.key}`,
          });
        } else {
          setAlertConfig({
            show: false,
          });
        }
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const PageContent = () => {
    const DataTable = () => (
      <>
        <div className="mt-2 mb-1">
          <b>List Data Realisasi Prelim</b>
        </div>

        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi Realisasi Prelim</ThFixed>
              <ThFixed>Informasi Transfer Prelim</ThFixed>
              <Th>Item Prelim</Th>
              <Th>Qty. Realisasi Prelim</Th>
              <Th>Unit Price</Th>
              <ThFixed>Durasi Pemakaian</ThFixed>
              <Th>Sub Total</Th>
              <ThFixed>Aksi</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => (
              <Tr key={index}>
                <TdFixed>
                  {TableNumber(
                    paginationConfig.page,
                    paginationConfig.dataLength,
                    index
                  )}
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_realisasi_prelim
                      ? DateConvert(new Date(val.tgl_realisasi_prelim))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_realisasi_prelim ?? '-'}</div>
                </TdFixed>
                <TdFixed>
                  <div>
                    {val.tgl_transfer_prelim
                      ? DateConvert(new Date(val.tgl_transfer_prelim))
                          .defaultDMY
                      : '-'}
                  </div>
                  <div>
                    <div>{val.no_transfer_prelim ?? '-'}</div>
                  </div>
                </TdFixed>
                <Td>{val.nama_item_prelim ?? '-'}</Td>
                <Td style={{ minWidth: '50px' }}>{val.qty_realisasi ?? '-'}</Td>
                <Td textRight>
                  {val.harga_satuan
                    ? RupiahConvert(String(parseInt(val.harga_satuan))).detail
                    : 'Rp. 0'}
                </Td>
                <TdFixed>
                  {val.durasi_realisasi
                    ? `${parseInt(val.durasi_realisasi)} Hari`
                    : 0}
                </TdFixed>
                <Td textRight>
                  {val.subtotal
                    ? RupiahConvert(String(parseInt(val.subtotal))).detail
                    : 'Rp. 0'}
                </Td>
                <TdFixed>
                  <ActionButton
                    tooltip
                    tooltipText="Detail"
                    variant="info"
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/transaksi/realisasi-prelim/detail/${val.id_spk}`
                      )
                    }
                  >
                    <IoEyeOutline />
                  </ActionButton>
                </TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      {/* head section */}
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setPaginationConfig((prev) => ({ ...prev, page: 1 }));
                  setSearchConfig((prev) => ({ ...prev, key: e.target.value }));
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <div className="d-flex justify-content-end">
            <CreateButton
              onClick={() =>
                history.push('/transaksi/realisasi-prelim/list-spk')
              }
            />
          </div>
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data ? (
        data.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default ListRealisasiPrelim;
