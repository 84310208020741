// Export PDF front page Monitoring Order

import React, { forwardRef } from 'react';
import Logo from 'config';

const ExportMonitoringOrder = forwardRef(
  ({ dataFilter, tableData: TableData }, ref) => {
    const KopSurat = () => {
      return (
        <div className="d-flex justify-content-start">
          <div>
            <img src={Logo.LOGO} width={110} />
          </div>
          <div style={{ marginLeft: 30, textAlign: 'left' }}>
            <p>
              <b>PT. Mardika Griya Prasta</b>
              <br />
              Jl. Sekar Sari No.30 Desa Kesiman Kertalangu, Denpasar Timur Telp.
              : (0361) 467342, 467343
              <br />
              Fax. : (0361) 467301
              <br />
            </p>
          </div>
        </div>
      );
    };

    return (
      <div className="container my-5 px-5" ref={ref}>
        <KopSurat />
        <div className="mt-4" />
        <TableData readOnly={true} />
      </div>
    );
  }
);

export default ExportMonitoringOrder;
