const Checkbox = ({
  id,
  label,
  name,
  value,
  error,
  onChange,
  className,
  width,
  height,
  style,
  ...rest
}) => {
  return (
    <div
      className={`custom-control custom-checkbox custom-control-inline ${className}`}
    >
      <input
        {...rest}
        width={width}
        height={height}
        type="checkbox"
        name={name}
        value={value}
        className={`custom-control-input ${error && 'is-invalid'}`}
        style={style}
        onChange={onChange}
        id={id}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default Checkbox;
