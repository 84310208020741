import Services from '../../../services';

class RegItemSubkonApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/subkon/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getBuaso() {
    return Services.get('/buaso');
  }
  getSatuan() {
    return Services.get('/satuan/dropdown');
  }
  getKelompok() {
    return Services.get('/subkon/kelompok?id_buaso=4');
  }
  generateKodeItem() {
    return Services.get('/subkon/no_baru');
  }
  create(data) {
    return Services.post('/subkon', data);
  }
  update(data) {
    return Services.put('/subkon', data);
  }
  delete(id_subkon) {
    return Services.post('/subkon/delete', id_subkon);
  }
  search(key) {
    return Services.get(`subkon/page/?q=${key}`);
  }
  show(id_subkon) {
    return Services.put('/subkon/show', id_subkon);
  }
  hide(id_subkon) {
    return Services.put('/subkon/hide', id_subkon);
  }

  export() {
    return Services.get('/subkon/csv');
  }
}

export default new RegItemSubkonApi();
