// React
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

// Component
import { Button, Card } from 'react-bootstrap';
import {
  CRUDLayout,
  Tr,
  Th,
  Td,
  Alert,
  DataStatus,
  TdFixed,
  ThFixed,
  THead,
  TBody,
  DatePicker,
  ActionButton,
  SelectSearch,
  Checkbox,
  ReadButton,
  Pagination,
} from 'components';
import { DateConvert, RupiahConvert, TableNumber } from 'utilities';

// Icon
import { IoDocumentTextOutline } from 'react-icons/io5';

// File Saver
import FileSaver from 'file-saver';

// API
import axios from 'axios';
import { LaporanRAEApi } from 'api';

const LaporanRAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const TODAY = new Date().toISOString().slice(0, 10);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [table, setTable] = useState({
    page: '1',
    per_page: '10',
    total_page: '0',
    total_data: '0',
  });
  const [form, setForm] = useState({
    tanggal_start: TODAY,
    tanggal_end: TODAY,
    nama_customer: null,
    nama_peringkat_peluang: null,
  });
  const [alertConfig, setAlertConfig] = useState({
    variant: 'primary',
    text: '',
    show: false,
  });
  const [atribut, setAtribut] = useState({
    peringkat: [],
    customer: [],
  });

  const handleFetchReport = () => {
    setLoading(true);

    LaporanRAEApi.getPage({
      page: table.page,
      per_page: table.per_page,
      ...form,
    })
      .then(({ data }) => {
        setData(data.data ?? []);
        setTable({
          ...table,
          total_page: data.total_page,
          total_data: data.data_count,
        });
      })
      .catch(() =>
        setAlertConfig({
          variant: 'danger',
          text: 'Tidak dapat memuat data!',
          show: true,
        })
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleFetchReport();
  }, [table.page, table.per_page]);

  const handleButtonExport = (tipe) => {
    const query = { ...form, tipe };

    LaporanRAEApi.getExport(query).then(({ data }) => {
      const ext = tipe === 'excel' ? 'xlsx' : 'pdf';
      FileSaver.saveAs(
        data.data.replace('http://', 'https://'),
        `Laporan_PO.${ext}`
      );
    });
  };

  const checkStatus = (data) => {
    if (data === 'PEN') {
      return 'Pending';
    } else if (data === 'APP') {
      return 'Approve';
    } else if (data === 'REV') {
      return 'Revise';
    } else {
      return 'Reject';
    }
  };

  useEffect(() => {
    setNavbarTitle('Laporan RAE');

    const semua = { value: null, label: 'Semua Data' };
    setPageLoading(true);
    axios
      .all([
        LaporanRAEApi.getDropdown({ tipe: 'peringkat' }),
        LaporanRAEApi.getDropdown({ tipe: 'customer' }),
      ])
      .then(
        axios.spread((peringkat, customer) =>
          setAtribut({
            peringkat: [
              {
                id_peringkat_peluang: semua.value,
                nama_peringkat_peluang: semua.label,
              },
              ...peringkat.data.data.data,
            ],
            customer: [
              {
                id_customer: semua.value,
                nama_customer: semua.label,
              },
              ...customer.data.data.data,
            ],
          })
        )
      )
      .finally(() => {
        setPageLoading(false);
      });
  }, [setNavbarTitle]);

  const ExportButton = ({
    variant = 'outline-success',
    text = 'Export EXCEL',
    onClick,
  }) => {
    return (
      <Button variant={variant} onClick={onClick} className="mx-1 mb-1">
        <IoDocumentTextOutline size={18} />
        <span className="ml-2 text-uppercase">{text}</span>
      </Button>
    );
  };

  const TableSection = () => {
    if (!data || data.length < 1) {
      return <DataStatus text="Tidak ada data" />;
    }

    return (
      <>
        <CRUDLayout.Table>
          <THead>
            <Tr className="text-center">
              <ThFixed>No</ThFixed>
              <ThFixed>Tgl. RAE</ThFixed>
              <ThFixed>No. RAE</ThFixed>
              <Th>Customer</Th>
              <ThFixed>Instalasi</ThFixed>
              <Th>ATT</Th>
              <ThFixed>Tgl. Selesai</ThFixed>
              <Th>Barang Jadi</Th>
              <ThFixed>Status Approval</ThFixed>
              <ThFixed>Baseline</ThFixed>
              <ThFixed></ThFixed>
            </Tr>
          </THead>
          <TBody>
            {data.map((val, index) => {
              return (
                <Tr key={index}>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>
                    {val.tgl_rae
                      ? DateConvert(new Date(val.tgl_rae)).defaultDMY
                      : ''}
                  </TdFixed>
                  <TdFixed>{val.no_rae}</TdFixed>
                  <Td>{val.nama_customer}</Td>
                  <TdFixed>
                    <div className="text-center">
                      <Checkbox checked={val.instalasi} className="mr-0" />
                    </div>
                  </TdFixed>
                  <Td>{val.att}</Td>
                  <TdFixed>
                    {val.tgl_selesai
                      ? DateConvert(new Date(val.tgl_selesai)).defaultDMY
                      : ''}
                  </TdFixed>
                  <Td>
                    <ul>
                      {val.barang_jadi?.map((item) => (
                        <li>{item.nama_item}</li>
                      )) ?? ''}
                    </ul>
                  </Td>
                  <TdFixed>{checkStatus(val.status_approval)}</TdFixed>
                  <TdFixed>{val.baseline}</TdFixed>
                  <TdFixed>
                    <ReadButton
                      onClick={() => history.push(`/laporan/rae/${val.id_rae}`)}
                    />
                  </TdFixed>
                </Tr>
              );
            })}
          </TBody>
        </CRUDLayout.Table>

        <Pagination
          dataLength={table.per_page}
          dataPage={
            table.total_data <= 10
              ? data.length
              : data.map(
                  (res, index) =>
                    index === data.length - 1 &&
                    TableNumber(table.page, table.per_page, index)
                )
          }
          dataNumber={data.map(
            (res, index) =>
              index === 0 && TableNumber(table.page, table.per_page, index)
          )}
          dataCount={table.total_data}
          onDataLengthChange={(e) =>
            setTable({
              ...table,
              per_page: e.target.value,
              page: '1',
            })
          }
          currentPage={table.page}
          totalPage={table.total_page}
          onPaginationChange={({ selected }) =>
            setTable({
              ...table,
              page: selected + 1,
            })
          }
        />
      </>
    );
  };

  if (pageLoading) {
    return <DataStatus loading={true} text="Memuat data ..." />;
  } else {
    return (
      <CRUDLayout>
        <Card className="px-3">
          {/* Periode */}
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <span className="mr-2 mt-1 text-">Periode:</span>
            <div className="p-2 pl-0">
              <DatePicker
                label="Tgl. Mulai"
                name="tanggal_start"
                selected={
                  form.tanggal_start ? new Date(form.tanggal_start) : ''
                }
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tanggal_start: value }));
                }}
              />
            </div>
            <span className="mx-2 mt-1">s.d.</span>
            <div className="p-2 pl-0">
              <DatePicker
                label="Tgl. Selesai"
                name="tanggal_end"
                selected={form.tanggal_end ? new Date(form.tanggal_end) : ''}
                onChange={(val) => {
                  const value = val.toISOString().slice(0, 10);
                  setForm((prev) => ({ ...prev, tanggal_end: value }));
                }}
              />
            </div>
          </div>

          {/* Filter */}
          <div className="d-flex flex-wrap justify-content-start align-items-center">
            <span className="mr-2 mt-1 text-">Filter By:</span>
            <div className="p-2 pl-0" style={{ width: '200px' }}>
              <SelectSearch
                label="Peringkat Peluang"
                placeholder="Pilih salah satu..."
                option={atribut.peringkat.map((val) => {
                  return {
                    value: val.id_peringkat_peluang,
                    label: val.nama_peringkat_peluang,
                  };
                })}
                onChange={(val) =>
                  setForm((prev) => ({
                    ...prev,
                    nama_peringkat_peluang:
                      val.value !== null ? val.label : null,
                  }))
                }
              />
            </div>
            <div className="p-2" style={{ width: '200px' }}>
              <SelectSearch
                label="Customer"
                placeholder="Pilih salah satu..."
                option={atribut.customer.map((val) => {
                  return {
                    value: val.id_customer,
                    label: val.nama_customer,
                  };
                })}
                onChange={(val) =>
                  setForm((prev) => ({
                    ...prev,
                    nama_customer: val.value !== null ? val.label : null,
                  }))
                }
              />
            </div>
            <ActionButton
              className="mt-1 p-50"
              size="sm"
              text="LIHAT DATA"
              onClick={handleFetchReport}
            />
          </div>
        </Card>

        <Alert
          showCloseButton={true}
          show={alertConfig.show}
          variant={alertConfig.variant}
          text={alertConfig.text}
          onClose={() => setAlertConfig((prev) => ({ ...prev, show: false }))}
        />

        {!isLoading && data.length > 0 && (
          <div className="d-flex justify-content-end align-items-center mx-1 mt-4 mb-1">
            <ExportButton
              variant="outline-danger"
              text="Export PDF"
              onClick={() => handleButtonExport('pdf')}
            />
            <ExportButton onClick={() => handleButtonExport('excel')} />
          </div>
        )}

        {isLoading ? (
          <DataStatus loading={true} text="Memuat data ..." />
        ) : (
          <TableSection />
        )}
      </CRUDLayout>
    );
  }
};

export default LaporanRAE;
