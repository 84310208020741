import React, { useState } from 'react';
import { Table, Th, Td, ThFixed } from 'components';
import { IoAddOutline } from 'react-icons/io5';
import { Alert, ActionButton, DataStatus } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

const Datatable = ({ data, history, setModalConfig, kelompok, tipe }) => {
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };

  let grandTotal = 0;

  const filteredData = [...data].filter(
    (val) => val.nama_kelompok === kelompok
  );
  const filteredHistory =
    [...history].filter((val) => val.nama_kelompok === kelompok) ?? [];

  const ListDataTable = ({
    index,
    val,
    subTotal,
    total,
    setModalConfig,
    isHistory,
    qty,
    satuan_qty,
    satuan_durasi,
    kode,
  }) => {
    const checkQtyPermintaan = filteredHistory
      .filter((fil) => kode === fil.kode_item_prelim)
      .reduce(
        (prev, current) =>
          parseFloat(prev) + parseFloat(current.qty_permintaan),
        0
      );

    return (
      <tr key={index}>
        <Td className="text-center" style={tableStyling}>
          {index + 1}
        </Td>

        {/* Cek jika history, tampilkan tgl_permintaan_prelim dan no prelim*/}
        {isHistory && (
          <Td style={tableStyling}>
            <div>
              {val.tgl_permintaan_prelim
                ? DateConvert(new Date(val.tgl_permintaan_prelim)).defaultDMY
                : '-'}
            </div>
            <div> {val.no_permintaan_prelim ?? '-'} </div>
          </Td>
        )}

        <Td className="text-center" style={tableStyling}>
          {' '}
          {val.kode_item_prelim ?? '-'}{' '}
        </Td>
        <Td style={tableStyling}>{val.nama_item_prelim ?? '-'}</Td>
        <Td style={tableStyling}>
          {qty ? `${parseFloat(qty).toPrecision()} ${satuan_qty ?? ''}` : '-'}
        </Td>
        <Td className="text-right" style={tableStyling}>
          {val.unit_price
            ? RupiahConvert(String(parseInt(val.unit_price))).detail
            : val.harga_satuan_prelim
            ? RupiahConvert(String(parseInt(val.harga_satuan_prelim))).detail
            : 'Rp.0'}
        </Td>
        <Td style={tableStyling}>
          {' '}
          {val.qty_durasi ? `${parseInt(val.qty_durasi)} Hari` : '-'}{' '}
        </Td>
        <Td className="text-right" style={tableStyling}>
          {' '}
          {RupiahConvert(String(parseInt(subTotal))).detail}{' '}
        </Td>

        {!isHistory && tipe === 'tambah' && (
          <Td className="text-center" style={tableStyling}>
            <ActionButton
              size="sm"
              variant="primary"
              text={<IoAddOutline />}
              onClick={() =>
                setModalConfig({
                  show: true,
                  type: 'primary',
                  data: val,
                  history: filteredHistory,
                })
              }
              disable={checkQtyPermintaan >= qty ? true : false}
            />
          </Td>
        )}
      </tr>
    );
  };

  return (
    <>
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      <div>
        {/* List Item Prelim */}
        <div>
          <small className="font-weight-bold">List Item Prelim</small>
        </div>

        <Table className="table table-bordered bg-white table-sm m-0">
          <thead className="text-center bg-light">
            <tr>
              <ThFixed className="align-middle" style={tableStyling}>
                No.
              </ThFixed>
              <ThFixed className="align-middle" style={tableStyling}>
                Kode Item
              </ThFixed>
              <Th className="align-middle" style={tableStyling}>
                Item Prelim
              </Th>
              <Th width={100} className="align-middle" style={tableStyling}>
                Qty. Prelim
              </Th>
              <Th width={150} className="align-middle" style={tableStyling}>
                Unit Price
              </Th>
              <ThFixed className="align-middle" style={tableStyling}>
                Durasi Pemakaian
              </ThFixed>
              <Th width={150} className="align-middle" style={tableStyling}>
                Sub Total
              </Th>
              {tipe === 'tambah' && (
                <ThFixed className="align-middle" style={tableStyling}>
                  Aksi
                </ThFixed>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData?.map((val, index) => {
                const qty = val.qty_analisa;
                const satuan_qty = val.nama_satuan_prelim;
                const subTotal =
                  val.unit_price && val.qty_analisa
                    ? parseInt(val.unit_price) * parseFloat(val.qty_analisa)
                    : val.harga_satuan_prelim && val.qty_analisa
                    ? parseInt(val.harga_satuan_prelim) *
                      parseFloat(val.qty_analisa)
                    : 0;
                grandTotal = grandTotal + (subTotal ?? 0);

                return (
                  <ListDataTable
                    key={index}
                    index={index}
                    val={val}
                    subTotal={subTotal}
                    setModalConfig={setModalConfig}
                    isHistory={false}
                    qty={qty}
                    kode={val.kode_item_prelim}
                    satuan_qty={satuan_qty}
                    satuan_durasi={val.nama_satuan_waktu}
                  />
                );
              })
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan={tipe === 'tambah' ? 9 : 8} align="center">
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            )}

            {/* Total Section */}
            {!!filteredData?.length > 0 && (
              <tr className="bg-light">
                <Td
                  colSpan={6}
                  className="text-right p-2 font-weight-bold"
                  style={tableStyling}
                >
                  {' '}
                  Total{' '}
                </Td>
                <Td
                  className="text-right text-nowrap font-weight-bold"
                  style={tableStyling}
                >
                  {RupiahConvert(String(parseInt(grandTotal))).detail}
                </Td>
                {tipe === 'tambah' && <Td></Td>}
              </tr>
            )}
          </tbody>
        </Table>

        {/* History Permintaan Prelim */}
        <div className="mt-3">
          <small className="font-weight-bold">Histori Permintaan Prelim</small>
        </div>

        <Table className="table table-bordered bg-white table-sm m-0">
          <thead className="text-center bg-light">
            <tr>
              <ThFixed className="align-middle" style={tableStyling}>
                No.
              </ThFixed>
              <ThFixed className="align-middle" style={tableStyling}>
                Informasi Permintaan Prelim
              </ThFixed>
              <ThFixed className="align-middle" style={tableStyling}>
                Kode Item
              </ThFixed>
              <Th className="align-middle" style={tableStyling}>
                Item Prelim
              </Th>
              <Th width={100} className="align-middle" style={tableStyling}>
                Qty. Permintan Prelim
              </Th>
              <Th width={150} className="align-middle" style={tableStyling}>
                Unit Price
              </Th>
              <ThFixed className="align-middle" style={tableStyling}>
                Durasi Pemakaian
              </ThFixed>
              <Th width={150} className="align-middle" style={tableStyling}>
                Sub Total
              </Th>
            </tr>
          </thead>
          <tbody>
            {filteredHistory?.length > 0 ? (
              filteredHistory?.map((val, index) => {
                const qty = val.qty_permintaan;
                const satuan_qty = val.nama_satuan_permintaan;
                const satuan_durasi = val.nama_satuan_durasi;
                const subTotal =
                  val.unit_price && qty
                    ? parseInt(val.unit_price) * parseFloat(qty)
                    : val.harga_satuan_prelim && qty
                    ? parseInt(val.harga_satuan_prelim) * parseFloat(qty)
                    : 0;

                return (
                  <ListDataTable
                    key={index}
                    index={index}
                    val={val}
                    subTotal={subTotal}
                    setModalConfig={setModalConfig}
                    isHistory={true}
                    qty={qty}
                    satuan_qty={satuan_qty}
                    satuan_durasi={satuan_durasi}
                  />
                );
              })
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan="10" align="center">
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Datatable;
