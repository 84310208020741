import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, Col } from 'react-bootstrap';
import { IoAdd, IoEyeOutline } from 'react-icons/io5';
import {
  CRUDLayout,
  Alert,
  DataStatus,
  ActionButton,
  InputSearch,
  Pagination,
  Table,
  THead,
  TBody,
  Tr,
  ThFixed,
  TdFixed,
  Th,
  Td,
  BackButton,
} from 'components';
import { DateConvert, TableNumber } from 'utilities';
import { useIsGuest } from 'hooks';
import { RealisasiPrelimApi } from 'api';

const ListRealisasiPrelim = ({ setNavbarTitle }) => {
  const title = 'Realisasi Prelim';
  const history = useHistory();
  const isGuest = useIsGuest();

  // STATES
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paginationConfig, setPaginationConfig] = useState({
    page: '1',
    dataLength: '10',
    totalPage: '1',
    dataCount: '0',
  });
  const [searchConfig, setSearchConfig] = useState({
    status: false,
    key: '',
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    RealisasiPrelimApi.spk_page({
      q: searchConfig.key,
      page: paginationConfig.page,
      per_page: paginationConfig.dataLength,
    })
      .then(({ data }) => {
        setData(data.data);
        setPaginationConfig({
          ...paginationConfig,
          dataCount: data.data_count,
          totalPage: data.total_page,
        });
        searchConfig.status &&
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil dari pencarian: ${searchConfig.key}`,
          });
      })
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        })
      )
      .finally(() => {
        if (searchConfig.key !== '') {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: `Hasil Pencarian : ${searchConfig.key}`,
          });
        } else {
          setAlertConfig({
            show: false,
          });
        }
        setIsPageLoading(false);
      });
  };

  useEffect(() => {
    setNavbarTitle(title);
    getInitialData();
  }, [
    setNavbarTitle,
    searchConfig.status,
    paginationConfig.page,
    paginationConfig.dataLength,
    searchConfig.key,
  ]);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return <li index={index}>{brg.nama_item}</li>;
            } else {
              return index < 3 && <li index={index}>{brg.nama_item}</li>;
            }
          })}
        </ul>
        {data.length < 4 ? (
          ''
        ) : (
          <div
            className="text-primary"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
              // fontWeight: "bold",
            }}
            onClick={() => {
              setisReadMoreClick((prev) => !prev);
            }}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const PageContent = () => {
    const DataTable = () => (
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <ThFixed>Tgl. SPK</ThFixed>
            <ThFixed>No. SPK</ThFixed>
            <Th>Customer</Th>
            <Th>Proyek</Th>
            <Th>Barang Jadi</Th>
            <ThFixed>Aksi</ThFixed>
          </Tr>
        </THead>
        <TBody>
          {data.map((val, index) => (
            <Tr key={index}>
              <TdFixed>
                {TableNumber(
                  paginationConfig.page,
                  paginationConfig.dataLength,
                  index
                )}
              </TdFixed>
              <Td className="text-center">
                {val.tgl_spk
                  ? DateConvert(new Date(val.tgl_spk)).defaultDMY
                  : '-'}
              </Td>
              <Td className="text-center">{val.no_spk ?? '-'}</Td>
              <Td>{val.nama_customer ?? '-'}</Td>
              <Td>{val.nama_proyek ?? '-'}</Td>
              <Td>
                {val.barang_jadi ? (
                  val.barang_jadi.length > 0 ? (
                    <BarangJadiCollapse data={val.barang_jadi} />
                  ) : (
                    '-'
                  )
                ) : (
                  '-'
                )}
              </Td>
              <Td>
                <ButtonGroup>
                  <ActionButton
                    tooltip
                    tooltipText="Detail"
                    variant="info"
                    size="sm"
                    onClick={() =>
                      history.push(
                        `/transaksi/realisasi-prelim/detail/${val.id_spk}`
                      )
                    }
                  >
                    <IoEyeOutline />
                  </ActionButton>

                  {!isGuest && (
                    <ActionButton
                      size="sm"
                      tooltip
                      tooltipText="Buat permintaan prelim"
                      onClick={() =>
                        history.push(
                          `/transaksi/realisasi-prelim/tambah/${val.id_spk}`
                        )
                      }
                    >
                      <IoAdd />
                    </ActionButton>
                  )}
                </ButtonGroup>
              </Td>
            </Tr>
          ))}
        </TBody>
      </Table>
    );

    return (
      <>
        <div className="mt-2 mb-1">
          <b>List SPK Dengan Transfer Prelim Yang Siap Direalisasi</b>
        </div>

        <DataTable />

        {!searchConfig.status && (
          <Pagination
            dataLength={paginationConfig.dataLength}
            dataNumber={
              paginationConfig.page * paginationConfig.dataLength -
              paginationConfig.dataLength +
              1
            }
            dataPage={
              paginationConfig.dataCount < paginationConfig.dataLength
                ? paginationConfig.dataCount
                : paginationConfig.page * paginationConfig.dataLength
            }
            dataCount={paginationConfig.dataCount}
            currentPage={paginationConfig.page}
            totalPage={paginationConfig.totalPage}
            onPaginationChange={({ selected }) =>
              setPaginationConfig({
                ...paginationConfig,
                page: selected + 1,
              })
            }
            onDataLengthChange={(e) =>
              setPaginationConfig({
                ...paginationConfig,
                page: 1,
                dataLength: e.target.value,
              })
            }
          />
        )}
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <div className="row">
            <Col md="8">
              <InputSearch
                onChange={(e) => {
                  setPaginationConfig((prev) => ({ ...prev, page: 1 }));
                  setSearchConfig((prev) => ({ ...prev, key: e.target.value }));
                }}
                onSubmit={(e) => e.preventDefault()}
              />
            </Col>
          </div>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <BackButton onClick={() => history.goBack()} />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      {/* alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() =>
          setAlertConfig({
            ...alertConfig,
            show: false,
          })
        }
      />

      {/* content */}
      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : data ? (
        data.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}
    </CRUDLayout>
  );
};

export default ListRealisasiPrelim;
