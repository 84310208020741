import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import Axios from 'axios';
import {
  ActionButton,
  SelectSearch,
  CRUDLayout,
  BackButton,
  Table,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  DataStatus,
  DatePicker,
  Alert,
} from 'components';
import _ from 'lodash';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { DateConvert, RupiahConvert } from 'utilities';
import { LaporanMonitoringApi } from 'api';
import { AiOutlineCheckSquare, AiOutlineCloseSquare } from 'react-icons/ai';
import { saveAs } from 'file-saver';
import { Formik } from 'formik';

const MonitoringOrder = ({ setNavbarTitle }) => {
  const inputHeader = { width: '210px' };
  // ROUTE
  const history = useHistory();
  const location = useLocation();
  const detailBulan = location?.state?.dataBulan;
  const detailTahun = location?.state?.dataTahun;
  // STATE DATA
  const [detailMonitoring, setDetailMonitoring] = useState([]);
  const [activated, setActivated] = useState(false);
  const [searchSelected, setSearchSelected] = useState({
    tgl_peluang_awal: activated
      ? new Date()
      : location?.state?.detail?.tgl_peluang_awal,
    tgl_peluang_akhir: activated
      ? new Date()
      : location?.state?.detail?.tgl_peluang_akhir,
    tgl_rae_awal: location?.state?.detail?.tgl_rae_awal,
    tgl_rae_akhir: location?.state?.detail?.tgl_rae_akhir,
    id_customer: location?.state?.detail?.id_customer,
    id_proyek: location?.state?.detail?.id_proyek,
    kelompok_proyek: location?.state?.detail?.kelompok_proyek,
  });
  // DROPDOWN DATA
  const [dataCustomer, setDataCustomer] = useState([
    { label: 'Semua', value: undefined },
  ]);
  const [dataProyek, setDataProyek] = useState([
    { label: 'Semua', value: undefined },
  ]);
  const dataKelompokProyek = [
    { label: 'Semua', value: undefined },
    { label: 'Proyek', value: 'proyek' },
    { label: 'Umum', value: 'umum' },
    { label: 'Tender', value: 'tender' },
  ];
  // FILTER BULAN
  const monthData = [
    { number: '01', name: 'Januari' },
    { number: '02', name: 'Februari' },
    { number: '03', name: 'Maret' },
    { number: '04', name: 'April' },
    { number: '05', name: 'Mei' },
    { number: '06', name: 'Juni' },
    { number: '07', name: 'Juli' },
    { number: '08', name: 'Agustus' },
    { number: '09', name: 'September' },
    { number: '10', name: 'Oktober' },
    { number: '11', name: 'November' },
    { number: '12', name: 'Desember' },
  ];
  // CONFIG
  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });

  // GET DATA
  const getInitialData = () => {
    setAlertConfig({ show: false, variant: '', text: '' });
    setFetchConfig({ ...fetchConfig, loading: true });

    Axios.all([
      LaporanMonitoringApi.getDetail({
        bulan_rae: detailBulan,
        tahun_rae: detailTahun,
        tgl_peluang_awal: searchSelected?.tgl_peluang_awal,
        tgl_peluang_akhir: searchSelected?.tgl_peluang_akhir,
        tgl_rae_awal: searchSelected?.tgl_rae_awal,
        tgl_rae_akhir: searchSelected?.tgl_rae_akhir,
        id_customer: searchSelected?.id_customer,
        id_proyek: searchSelected?.id_proyek,
        kelompok_proyek: searchSelected?.kelompok_proyek,
      }),
      LaporanMonitoringApi.getDropdown({ tipe: 'customer' }),
      LaporanMonitoringApi.getDropdown({ tipe: 'proyek' }),
    ])
      .then(
        Axios.spread((res, customer, proyek) => {
          const mapCustomer = customer?.data?.data
            ? customer.data.data.map((val) => ({
                label: val.nama_customer,
                value: val.id_customer,
              }))
            : [];
          const mapProyek = proyek?.data?.data
            ? proyek.data.data.map((val) => ({
                label: val.nama_proyek,
                value: val.id_proyek,
              }))
            : [];

          setDetailMonitoring(res?.data?.data ?? []);
          setDataCustomer([...dataCustomer, ...mapCustomer]);
          setDataProyek([...dataProyek, ...mapProyek]);

          setFetchConfig({ loading: false, success: true });
        })
      )
      .catch(({ response }) => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: response.data.message ?? 'Data gagal dimuat!',
        });
        setDetailMonitoring([]);
      })
      .finally(() => {
        setFetchConfig({
          loading: false,
          success: false,
        });
      });
  };
  // EXPORT DATA
  const getExportData = (file) => {
    getInitialData();

    LaporanMonitoringApi.getExportDetail({
      tipe: file,
      bulan_rae: detailBulan,
      tahun_rae: detailTahun,
      tgl_peluang_awal: searchSelected?.tgl_peluang_awal,
      tgl_peluang_akhir: searchSelected?.tgl_peluang_akhir,
      tgl_rae_awal: searchSelected?.tgl_rae_awal,
      tgl_rae_akhir: searchSelected?.tgl_rae_akhir,
      id_customer: searchSelected?.id_customer,
      id_proyek: searchSelected?.id_proyek,
      kelompok_proyek: searchSelected?.kelompok_proyek,
    })
      .then((res) => {
        if (res.data.message) {
          setAlertConfig({
            show: true,
            variant: 'primary',
            text: res?.data?.message ?? 'Tidak ada data!',
          });
        }
        const link = res?.data?.data;
        const parse =
          'https://' +
          link
            .split('/')
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join('/');

        return saveAs(parse, 'Detail Laporan Monitoring Order');
      })
      .catch(({ response }) => {
        return setAlertConfig({
          show: true,
          variant: 'danger',
          text:
            response?.data?.message ??
            'Export data gagal! (Data tidak ditemukan)',
        });
      });
  };

  useEffect(() => {
    setNavbarTitle('Monitoring Proder');
    getInitialData();
  }, []);

  // TABLE
  const TableData = () => {
    const ItemBarangCollapse = ({ data }) => {
      const [isReadMoreClick, setisReadMoreClick] = useState(false);

      return (
        <>
          <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
            {data?.map((brg, index) => {
              if (isReadMoreClick) {
                return <li key={index} index={index}>{`${brg.nama_item}`}</li>;
              } else {
                return (
                  index < 2 && (
                    <li key={index} index={index}>{`${brg.nama_item}`}</li>
                  )
                );
              }
            })}
          </ul>
          {data?.length > 2 && (
            <div
              className="text-primary"
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => setisReadMoreClick((prev) => !prev)}
            >
              {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
            </div>
          )}
        </>
      );
    };

    return (
      <>
        <h1 style={{ fontSize: '14px' }}>
          <b>
            Detail Monitoring Order
            <span className="pl-1 pr-1">
              {monthData
                .filter((val) => val.number === detailBulan)
                .map((fil) => fil.name)}
            </span>
            {detailTahun}
          </b>
        </h1>
        <Table>
          <THead>
            <Tr>
              <ThFixed>No</ThFixed>
              <ThFixed>Informasi RAE</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <Th style={{ minWidth: '200px' }}>PIC</Th>
              <Th style={{ minWidth: '250px' }}>Customer</Th>
              <Th style={{ minWidth: '250px' }}>Proyek</Th>
              <Th>Kelompok Proyek</Th>
              <ThFixed>Tgl. Target Penawaran</ThFixed>
              <ThFixed>Tgl. Realisasi Penawaran</ThFixed>
              <ThFixed>Instalasi</ThFixed>
              <Th style={{ minWidth: '300px' }}>Item Barang</Th>
              <Th style={{ minWidth: '70px' }}>Grand Total</Th>
            </Tr>
          </THead>
          <TBody>
            {detailMonitoring.map((val, index) => {
              const totalBarangJadi = val?.barang_jadi?.reduce(
                (prev, curr) =>
                  prev +
                  parseFloat(curr.harga_satuan_rae ?? 0) *
                    parseFloat(curr.qty_rae ?? 0),
                0
              );
              const totalPrelim = val?.detail_prelim?.reduce(
                (prev, curr) =>
                  prev +
                  parseFloat(curr.unit_price ?? 0) *
                    parseFloat(curr.qty_analisa ?? 0),
                0
              );
              const grandTotal = totalBarangJadi + totalPrelim;
              return (
                <Tr>
                  <TdFixed>{index + 1}</TdFixed>
                  <TdFixed>
                    <div className="text-left">
                      {val.tgl_rae
                        ? DateConvert(new Date(val.tgl_rae)).defaultDMY
                        : '-'}
                    </div>
                    <div className="text-left">{val.no_rae ?? '-'}</div>
                  </TdFixed>
                  <TdFixed>
                    <div className="text-left">
                      {val.tgl_peluang
                        ? DateConvert(new Date(val.tgl_peluang)).defaultDMY
                        : '-'}
                    </div>
                    <div className="text-left">{val.no_peluang ?? '-'}</div>
                  </TdFixed>
                  <Td>{val.nama_karyawan_pengaju ?? '-'}</Td>
                  <Td>{val.nama_customer ?? '-'}</Td>
                  <Td>{val.nama_proyek ?? '-'}</Td>
                  <Td>{val.kelompok_proyek ?? '-'}</Td>
                  <TdFixed>
                    {val.target_penawaran
                      ? DateConvert(new Date(val.target_penawaran)).defaultDMY
                      : '-'}
                  </TdFixed>
                  <TdFixed>
                    {val.realisasi_penawaran
                      ? DateConvert(new Date(val.realisasi_penawaran))
                          .defaultDMY
                      : '-'}
                  </TdFixed>
                  <TdFixed textCenter>
                    {val.instalasi ? (
                      <AiOutlineCheckSquare style={{ fontSize: '22px' }} />
                    ) : (
                      <AiOutlineCloseSquare style={{ fontSize: '22px' }} />
                    )}
                  </TdFixed>
                  <Td>
                    {val.barang_jadi && val.barang_jadi?.length > 0 ? (
                      <ItemBarangCollapse data={val.barang_jadi} />
                    ) : (
                      '-'
                    )}
                  </Td>
                  <Td textRight>
                    {RupiahConvert(String(parseInt(grandTotal))).detail}
                  </Td>
                </Tr>
              );
            })}
          </TBody>
        </Table>
      </>
    );
  };
  // ON CHANGE DATE
  const onChangePeluang = (dates) => {
    const [start, end] = dates;
    setSearchSelected({
      ...searchSelected,
      tgl_peluang_awal: start,
      tgl_peluang_akhir: end,
    });
  };

  const onChangeRAE = (dates) => {
    const [start, end] = dates;
    setSearchSelected({
      ...searchSelected,
      tgl_rae_awal: start,
      tgl_rae_akhir: end,
    });
  };

  return (
    <CRUDLayout>
      <div className="text-right mb-2">
        <BackButton onClick={() => history.push('/laporan/monitoring-order')} />
      </div>
      <Card className="p-3 mb-1 pl-4">
        <Row>
          {/* DatePicker */}
          <div className="ml-3 mt-1" style={inputHeader}>
            <DatePicker
              label="Tgl. Peluang"
              placeholderText="Pilih tanggal peluang..."
              dateFormat="dd/MM/yyyy"
              selected={searchSelected?.tgl_peluang_awal}
              onChange={onChangePeluang}
              startDate={searchSelected?.tgl_peluang_awal}
              endDate={searchSelected?.tgl_peluang_akhir}
              selectsRange
            />
          </div>
          <div className="ml-3 mt-1" style={inputHeader}>
            <DatePicker
              label="Tgl. RAE"
              placeholderText="Pilih tanggal RAE..."
              dateFormat="dd/MM/yyyy"
              selected={searchSelected?.tgl_rae_awal}
              onChange={onChangeRAE}
              startDate={searchSelected?.tgl_rae_awal}
              endDate={searchSelected?.tgl_rae_akhir}
              selectsRange
            />
          </div>
          {/* SelectSearch */}
          <div className="ml-3 mt-1" style={inputHeader}>
            <SelectSearch
              label="Customer"
              placeholder="Pilih customer..."
              onChange={(e) =>
                setSearchSelected({ ...searchSelected, id_customer: e.value })
              }
              option={dataCustomer}
            />
          </div>
          <div className="ml-3 mt-1" style={inputHeader}>
            <SelectSearch
              label="Proyek"
              placeholder="Pilih proyek..."
              onChange={(e) =>
                setSearchSelected({ ...searchSelected, id_proyek: e.value })
              }
              option={dataProyek}
            />
          </div>
          <div className="ml-3 mt-1" style={inputHeader}>
            <SelectSearch
              label="Kelompok Proyek"
              placeholder="Pilih kelompok proyek..."
              onChange={(e) =>
                setSearchSelected({
                  ...searchSelected,
                  kelompok_proyek: e.value,
                })
              }
              option={dataKelompokProyek}
            />
          </div>
          <div className="ml-auto mt-4 mr-3">
            <ActionButton
              className="pl-4 pr-4"
              onClick={() => getInitialData() && setActivated(true)}
            >
              Cari
            </ActionButton>
          </div>
        </Row>
      </Card>
      <div className="d-flex mb-3">
        <div className="d-flex ml-auto pt-4">
          <ActionButton
            className="mr-2"
            variant="outline-danger"
            text="Export PDF"
            onClick={() => getExportData('pdf')}
          />
          <ActionButton
            variant="outline-success"
            text="Export Excel"
            onClick={() => getExportData('excel')}
          />
        </div>
      </div>
      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />
      {/* <TableData /> */}
      {fetchConfig.loading ? (
        <DataStatus loading={true} text="Memuat..." />
      ) : !detailMonitoring || detailMonitoring.length < 1 ? (
        <DataStatus text="Tidak ada data" />
      ) : (
        <TableData />
      )}
    </CRUDLayout>
  );
};

export default MonitoringOrder;
