import Services from 'services';

class RealisasiPrelimApi {
  getPageRealisasiPrelim(params) {
    return Services.get('/realisasi_prelim/page', { params });
  }

  getPageSPK(params) {
    return Services.get('/realisasi_prelim/spk_page', { params });
  }

  spk_page(params) {
    return Services.get('/spk/page', { params });
  }

  job_order_page(params) {
    return Services.get('/job_order/page', { params });
  }

  job_order_single(params) {
    return Services.get('/spk/single', { params });
  }

  kontrak_prelim(params) {
    return Services.get('/kontrak_prelim', { params });
  }

  kontrak_no_baru(params) {
    return Services.get('/realisasi_kontrak_prelim/no_baru', { params });
  }

  realisasi_kontrak_prelim(params) {
    return Services.get('/realisasi_kontrak_prelim', { params });
  }

  overhead_prelim(params) {
    return Services.get('/overhead_prelim', { params });
  }

  overhead_no_baru(params) {
    return Services.get('/realisasi_overhead_prelim/no_baru', { params });
  }

  realisasi_overhead_prelim(params) {
    return Services.get('/realisasi_overhead_prelim', { params });
  }

  transfer_prelim(params) {
    return Services.get('/transfer_prelim', { params });
  }

  transfer_no_baru(params) {
    return Services.get('/realisasi_transfer_prelim/no_baru', { params });
  }

  realisasi_transfer_prelim(params) {
    return Services.get('/realisasi_transfer_prelim', { params });
  }

  realisasi_transfer_prelim_create(params) {
    return Services.post('/realisasi_transfer_prelim', params);
  }

  realisasi_kontrak_prelim_create(params) {
    return Services.post('/realisasi_kontrak_prelim', params);
  }

  realisasi_overhead_prelim_create(params) {
    return Services.post('/realisasi_overhead_prelim', params);
  }
}

export default new RealisasiPrelimApi();
