import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { IoCheckboxOutline, IoCloseCircleOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import { debounce } from 'lodash';
import {
  Alert,
  ApprovalStatusButton,
  CreateButton,
  CRUDLayout,
  DataStatus,
  FilterButton,
  InputSearch,
  Pagination,
  ReadButton,
  Table,
  TBody,
  Td,
  TdFixed,
  Th,
  THead,
  ThFixed,
  Tr,
  UpdateButton,
} from 'components';
import { RAEApi } from 'api';
import { DateConvert, TableNumber } from 'utilities';
import { ModalFilter } from '../components';
import { useSessionStorage } from 'hooks';

const RAE = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const paginationKey = `${location.pathname}_pagination`;
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [dataRAE, setDataRAE] = useState([]);
  const [alert, setAlert] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  const [pagination, setPagination] = useSessionStorage(paginationKey, {
    q: '',
    page: '1',
    per_page: '10',
    total_page: '1',
    data_count: '0',

    tgl_rae_awal: undefined,
    tgl_rae_akhir: undefined,
    peringkat_peluang: undefined,
    customer: undefined,
    proyek: undefined,
    kelompok_proyek: undefined,
    target_penawaran_awal: undefined,
    target_penawaran_akhir: undefined,
    realisasi_penawaran_awal: undefined,
    realisasi_penawaran_akhir: undefined,
    instalasi: undefined,
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    const query = {
      q: pagination.q,
      page: pagination?.page,
      per_page: pagination?.per_page,

      tgl_rae_awal: pagination?.tgl_rae_awal,
      tgl_rae_akhir: pagination?.tgl_rae_akhir,
      peringkat_peluang: pagination?.peringkat_peluang,
      customer: pagination?.customer,
      proyek: pagination?.proyek,
      kelompok_proyek: pagination?.kelompok_proyek,
      target_penawaran_awal: pagination?.target_penawaran_awal,
      target_penawaran_akhir: pagination?.target_penawaran_akhir,
      realisasi_penawaran_awal: pagination?.realisasi_penawaran_awal,
      realisasi_penawaran_akhir: pagination?.realisasi_penawaran_akhir,
      instalasi: pagination?.instalasi,
    };

    RAEApi.get(query)
      .then((data) => {
        setDataRAE(data?.data?.data ?? []);
        setPagination((prev) => ({
          ...prev,
          data_count: data?.data?.data_count,
          total_page: data?.data?.total_page,
        }));
      })
      .catch(() => {
        setAlert({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState?.alert) {
      setAlert(locationState?.alert);
    }
  };

  const dismissAlert = () => {
    setAlert({ show: false, variant: 'primary', text: '' });

    const locationState = location.state;
    let state = { ...locationState };
    delete state.alert;
    history.replace({ ...state });
  };

  const searchHandler = (e) => {
    const searchKey = e.target.value;

    setPagination({
      ...pagination,
      q: searchKey,
      page: '1',
    });
  };

  useEffect(() => {
    setNavbarTitle('Rencana Anggaran Estimasi (RAE)');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, [
    setNavbarTitle,
    pagination.q,
    pagination?.page,
    pagination?.per_page,
    pagination?.active,
    pagination?.tgl_rae_awal,
    pagination?.tgl_rae_akhir,
    pagination?.peringkat_peluang,
    pagination?.customer,
    pagination?.proyek,
    pagination?.kelompok_proyek,
    pagination?.target_penawaran_awal,
    pagination?.target_penawaran_akhir,
    pagination?.realisasi_penawaran_awal,
    pagination?.realisasi_penawaran_akhir,
    pagination?.instalasi,
  ]);

  const BarangJadiCollapse = ({ data }) => {
    const [isReadMoreClick, setisReadMoreClick] = useState(false);

    return (
      <>
        <ul className="pl-3 mb-1" style={{ fontSize: '12px' }}>
          {data.map((brg, index) => {
            if (isReadMoreClick) {
              return <li index={index}>{brg.nama_item}</li>;
            } else {
              return index <= 2 && <li index={index}>{brg.nama_item}</li>;
            }
          })}
        </ul>
        {data.length <= 2 ? (
          ''
        ) : (
          <div
            className="text-primary"
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              setisReadMoreClick((prev) => !prev);
            }}
          >
            {isReadMoreClick ? 'Sembunyikan' : 'Selengkapnya...'}
          </div>
        )}
      </>
    );
  };

  const APPROVAL = {
    APP: ['outline-success', 'APPROVED'],
    REJ: ['outline-danger', 'REJECT'],
    PEN: ['outline-secondary', 'PENDING'],
    REV: ['outline-warning', 'REVISE'],
    VER: ['outline-success', 'VERIFIED'],
    DRAFT: ['outline-draft', 'DRAFT'],
  };

  const checkIsAllowUpdate = (status) =>
    status === 'APP' ||
    status === 'VER' ||
    status === 'REV' ||
    status === 'DRAFT';

  const PageContent = () => {
    const DataTable = () => (
      <>
        <div className="mt-2 mb-1">
          <b>List Data Rancangan Anggaran Estimasi (RAE)</b>
        </div>

        <Table>
          <THead>
            <Tr>
              <Th textCenter width={30}>
                No
              </Th>
              <ThFixed>Aksi</ThFixed>
              <ThFixed>Informasi RAE</ThFixed>
              <ThFixed>Informasi Peluang</ThFixed>
              <Th style={{ minWidth: '200px' }}>Customer</Th>
              <Th>Proyek</Th>
              <Th style={{ minWidth: '200px' }}>Kelompok Proyek</Th>
              <ThFixed>Instalasi</ThFixed>
              <Th style={{ minWidth: '200px' }}>ATT</Th>
              <ThFixed>Tgl. Selesai</ThFixed>
              <Th style={{ minWidth: '300px' }}>Barang Jadi</Th>
              <ThFixed>Status Approval</ThFixed>
              <ThFixed>Baseline</ThFixed>
            </Tr>
          </THead>
          <TBody>
            {dataRAE.map((val, index) => (
              <Tr key={index}>
                <Td textCenter>
                  {TableNumber(pagination.page, pagination.per_page, index)}
                </Td>
                <Td noPadding>
                  <div className="d-flex align-middle">
                    <ReadButton
                      onClick={() =>
                        history.push('/transaksi/rae/detail/' + val.id_rae)
                      }
                    />

                    {checkIsAllowUpdate(val?.status_approval ?? 'DRAFT') && (
                      <UpdateButton
                        onClick={() =>
                          history.push('/transaksi/rae/ubah/' + val.id_rae)
                        }
                      />
                    )}
                  </div>
                </Td>
                <Td>
                  <div>
                    {val.tgl_rae
                      ? DateConvert(new Date(val.tgl_rae)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_rae ?? '-'}</div>
                </Td>
                <Td>
                  <div>
                    {val.tgl_peluang
                      ? DateConvert(new Date(val.tgl_peluang)).defaultDMY
                      : '-'}
                  </div>
                  <div>{val.no_peluang ?? '-'}</div>
                </Td>
                <Td>{val.nama_customer ?? '-'}</Td>
                <Td>{val.nama_proyek ?? '-'}</Td>
                <Td>{val.kelompok_proyek ?? '-'}</Td>
                <TdFixed textCenter>
                  {val?.instalasi === true ? (
                    <IoCheckboxOutline size="20" />
                  ) : (
                    <IoCloseCircleOutline size="20" />
                  )}
                </TdFixed>
                <Td>{val?.att ?? '-'}</Td>
                <TdFixed>
                  {' '}
                  {val.tgl_selesai
                    ? DateConvert(new Date(val.tgl_selesai)).defaultDMY
                    : '-'}{' '}
                </TdFixed>
                <Td>
                  {val?.barang_jadi
                    ? val.barang_jadi.length > 0 && (
                        <BarangJadiCollapse data={val.barang_jadi} />
                      )
                    : '-'}
                </Td>
                <Td>
                  <ApprovalStatusButton
                    variant={APPROVAL[val.status_approval ?? 'DRAFT'][0]}
                  >
                    {APPROVAL[val.status_approval ?? 'DRAFT'][1]}
                  </ApprovalStatusButton>
                </Td>
                <TdFixed>{val.baseline ? val.baseline : '-'}</TdFixed>
              </Tr>
            ))}
          </TBody>
        </Table>
      </>
    );

    return (
      <>
        <DataTable />
        <Pagination
          dataLength={pagination?.per_page}
          dataNumber={
            pagination?.page * pagination?.per_page - pagination?.per_page + 1
          }
          dataPage={
            pagination?.data_count < pagination?.per_page
              ? pagination?.data_count
              : pagination?.page * pagination?.per_page
          }
          dataCount={pagination?.data_count}
          currentPage={pagination?.page}
          totalPage={pagination?.total_page}
          onPaginationChange={({ selected }) =>
            setPagination((prev) => ({
              ...prev,
              page: selected + 1,
            }))
          }
          onDataLengthChange={(e) =>
            setPagination((prev) => ({
              ...prev,
              per_page: e.target.value,
            }))
          }
        />
      </>
    );
  };

  return (
    <CRUDLayout>
      <CRUDLayout.Head>
        <CRUDLayout.HeadSearchSection>
          <Row className="d-flex align-items-center mb-2">
            <Col md="10" className="d-flex">
              <InputSearch
                defaultValue={pagination.q}
                onChange={debounce(searchHandler, 1500)}
              />
              <FilterButton
                active={pagination.active}
                onClick={() => setShowFilter((prev) => !prev)}
              />
            </Col>
          </Row>
        </CRUDLayout.HeadSearchSection>
        <CRUDLayout.HeadButtonSection>
          <CreateButton
            text="Tambah Data"
            onClick={() => history.push('/transaksi/rae/peluang')}
          />
        </CRUDLayout.HeadButtonSection>
      </CRUDLayout.Head>

      <Alert
        show={alert.show}
        variant={alert.variant}
        text={alert.text}
        showCloseButton={true}
        onClose={() => dismissAlert()}
      />

      {isPageLoading ? (
        <DataStatus loading={true} text="Memuat data . . ." />
      ) : dataRAE ? (
        dataRAE.length > 0 ? (
          <PageContent />
        ) : (
          <DataStatus text="Tidak ada data" />
        )
      ) : (
        <DataStatus text="Data gagal dimuat" />
      )}

      <ModalFilter
        status="rae"
        show={showFilter}
        setShow={setShowFilter}
        filter={pagination}
        setFilter={setPagination}
      />
    </CRUDLayout>
  );
};

export default RAE;
