import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DateConvert } from 'utilities';

const InfoSectionDetailSPK = ({ data }) => {
  const InfoItem = ({ title, value, link, onClick }) => (
    <tr onClick={onClick}>
      <td>{title}</td>
      <td className={`pl-4 pr-2`}>:</td>
      <td
        className={link ? 'text-primary' : ''}
        style={{ cursor: link ? 'pointer' : 'default' }}
      >
        {value}
      </td>
    </tr>
  );

  const ShowData = ({ title, value }) => (
    <div>
      <small
        className="text-secondary"
        style={{ opacity: 0.9, textTransform: 'capitalize' }}
      >
        {title}
      </small>
      <div className="text-dark" style={{ fontSize: '0.9rem' }}>
        <div>{value ? value : '-'}</div>
      </div>
    </div>
  );

  return (
    <>
      <Row>
        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem
                title="Tgl. Penawaran"
                value={
                  data.tgl_penawaran
                    ? DateConvert(new Date(data.tgl_penawaran)).defaultDMY
                    : '-'
                }
              />
              <InfoItem title="No. Penawaran" value={data.no_penawaran} />
              <InfoItem
                // link
                title="No. Peluang"
                value={data?.no_peluang ?? '-'}
                // onClick={() =>
                //   setModalPeluangConfig({ show: true, no_peluang: dataPenawaran?.no_peluang ?? "" })
                // }
              />
            </tbody>
          </table>
        </Col>

        <Col>
          <table style={{ fontSize: '14px' }}>
            <tbody>
              <InfoItem title="Customer" value={data.nama_customer} />
              <InfoItem title="ATT" value={data.att} />
              <InfoItem
                title="Tgl. Realisasi Penawaran"
                value={
                  data?.realisasi_penawaran
                    ? DateConvert(new Date(data?.realisasi_penawaran))
                        .defaultDMY
                    : '-'
                }
              />
              <InfoItem title="Proyek" value={data.nama_proyek ?? '-'} />
            </tbody>
          </table>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col>
          <ShowData
            title="Tgl. SPK"
            value={
              data?.tgl_spk
                ? DateConvert(new Date(data?.tgl_spk)).defaultDMY
                : '-'
            }
          />
        </Col>
        <Col>
          <ShowData title="No.SPK" value={data?.no_spk ?? '-'} />
        </Col>
        <Col>
          <ShowData
            title="Tgl. Selesai Barang Jadi"
            value={
              data?.tgl_selesai
                ? DateConvert(new Date(data?.tgl_selesai)).defaultDMY
                : '-'
            }
          />
        </Col>
        <Col>
          <ShowData
            title="Segment Pasar"
            value={data?.nama_segmentasi_pasar ?? '-'}
          />
        </Col>
      </Row>

      <hr />
    </>
  );
};

export default InfoSectionDetailSPK;
