import Services from '../../../services';

class RegItemSubkonApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/prelim/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getBuaso() {
    return Services.get('/buaso');
  }
  getSatuan() {
    return Services.get('/satuan/dropdown');
  }
  getSatuanWaktu() {
    return Services.get('/satuan/dropdown?flag=waktu');
  }
  getKelompok() {
    return Services.get('/prelim/kelompok?id_buaso=6');
  }
  generateKodeItem() {
    return Services.get('/prelim/no_baru');
  }
  create(data) {
    return Services.post('/prelim', data);
  }
  update(data) {
    return Services.put('/prelim', data);
  }
  delete(id_prelim) {
    return Services.post('/prelim/delete', id_prelim);
  }
  search(key) {
    return Services.get(`prelim/page/?q=${key}`);
  }
  show(id_prelim) {
    return Services.put('/prelim/show', id_prelim);
  }
  hide(id_prelim) {
    return Services.put('/prelim/hide', id_prelim);
  }

  export() {
    return Services.get('/prelim/csv');
  }
}

export default new RegItemSubkonApi();
