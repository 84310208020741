import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Axios from 'axios';
import HTMLReactParser from 'html-react-parser';
import { Alert, DataStatus, BackButton } from 'components';
import { PermintaanPrelimApi } from 'api';
import { TableItemSPK, InfoSectionDetailSPK } from '../Section';

const DetailSPKPermintaanPrelim = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();
  const { id: id_spk } = useParams();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dataJO, setDataJO] = useState({});
  const [dataSPK, setDataSPK] = useState([]);
  const [dataAnalisa, setDataAnalisa] = useState([]);
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });

  const getInitialData = () => {
    setIsPageLoading(true);
    Axios.all([PermintaanPrelimApi.getSingleSPK({ id_spk })])
      .then(
        Axios.spread(({ data }) => {
          setDataJO(data?.data ?? {});
          setDataSPK(data?.data?.list_item ?? []);
          setDataAnalisa(data?.data.analisa ?? []);
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const getGrandTotalPrelim = dataAnalisa?.reduce(
    (acc, { qty_analisa, unit_price, konstanta }) =>
      acc +
      parseInt(parseFloat(qty_analisa || 0) * parseInt(unit_price || 0)) *
        parseFloat(konstanta || 0),
    0
  );

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  useEffect(() => {
    setNavbarTitle('Permintaan Prelim');
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b className="text-capitalize">
            Detail Data Surat Perjanjian Kerja (SPK)
          </b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() => setAlertConfig({ ...alertConfig, show: false })}
          />

          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              {/* Info section Detail SPK */}
              <InfoSectionDetailSPK data={dataJO} />

              {/* List Item SPK */}
              <TableItemSPK
                data={dataSPK}
                diskon={dataJO.diskon}
                ppn={dataJO.ppn}
                totalPrelim={getGrandTotalPrelim}
              />

              {/* Catatan */}
              <b>Catatan</b>
              <div className="p-2 border">
                {HTMLReactParser(dataJO?.catatan ?? <p></p>)}
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default DetailSPKPermintaanPrelim;
