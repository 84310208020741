import Services from 'services';

class RegItemUpahApi {
  getPage(page, dataLength, key) {
    return Services.get(
      `/upah/page/?page=${page}&per_page=${dataLength}&q=${key}`
    );
  }
  getBuaso() {
    return Services.get('/buaso');
  }
  getSatuan() {
    return Services.get('/satuan/dropdown');
  }
  getKelompok() {
    return Services.get('/subkon/kelompok?id_buaso=1');
  }
  getPabrikan() {
    return Services.get('/pabrikan/dropdown');
  }
  generateKodeItem() {
    return Services.get('/upah/no_baru');
  }
  create(data) {
    return Services.post('/upah', data);
  }
  update(data) {
    return Services.put('/upah', data);
  }
  delete(id_upah) {
    return Services.post('/upah/delete', id_upah);
  }
  search(key) {
    return Services.get(`upah/page/?q=${key}`);
  }
  show(id_upah) {
    return Services.put('/upah/show', id_upah);
  }
  hide(id_upah) {
    return Services.put('/upah/hide', id_upah);
  }
  export() {
    return Services.get('/upah/csv');
  }
}

export default new RegItemUpahApi();
