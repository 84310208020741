import Services from 'services';

class LaporanMonitoringApi {
  getPage(params) {
    return Services.get('/monitoring_order/front_page', { params });
  }

  getDetail(params) {
    return Services.get('/monitoring_order/detail', { params });
  }

  detail(getBulan, getTahun) {
    return Services.get(
      `/monitoring_order/detail?bulan_rae=${getBulan}&tahun_rae=${getTahun}`
    );
  }

  getExport(params) {
    return Services.get('/monitoring_order/export', { params });
  }

  getExportDetail(params) {
    return Services.get('/monitoring_order/export_detail', { params });
  }

  getDropdown(params) {
    return Services.get('/monitoring_order/dropdown', { params });
  }
}

export default new LaporanMonitoringApi();
