import Services from 'services';

class PermintaanProduksiApi {
  get(params) {
    return Services.get('permintaan_prelim/page', { params });
  }

  spk_page(params) {
    return Services.get('permintaan_prelim/list_spk', { params });
  }

  list_job_order(params) {
    return Services.get('/permintaan_prelim/list_job_order', { params });
  }

  list_kelompok_prelim(params) {
    return Services.get('/permintaan_prelim/list_kelompok_prelim', { params });
  }

  getSingle(params) {
    return Services.get('/permintaan_prelim/single', { params });
  }

  getSingleSPK(params) {
    return Services.get('/permintaan_prelim/single_spk', { params });
  }

  getSingleJobOrder(params) {
    return Services.get('permintaan_prelim/single_jo', { params });
  }

  getNoBaru(params) {
    return Services.get('permintaan_prelim/no_baru', { params });
  }

  postData(data) {
    return Services.post('/permintaan_prelim', data);
  }
}

export default new PermintaanProduksiApi();
