import React, { useEffect, useRef, useState } from 'react';
import { Card, Row } from 'react-bootstrap';
import { useReactToPrint } from 'react-to-print';
import {
  ActionButton,
  SelectSearch,
  CRUDLayout,
  ReadButton,
  Table,
  THead,
  Tr,
  ThFixed,
  Th,
  TBody,
  TdFixed,
  Td,
  DataStatus,
  Alert,
} from 'components';
import { useHistory, useLocation } from 'react-router-dom';
import { RupiahConvert } from 'utilities';
import { LaporanMonitoringApi } from 'api';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import ExportMonitoringOrder from './ExportMonitoringOrder';

const MonitoringOrder = ({ setNavbarTitle }) => {
  const history = useHistory();
  const today = new Date();
  const refPrint = useRef();
  const reactToPrint = useReactToPrint({ content: () => refPrint.current });
  const dropdownBulan = [
    { value: '01', label: 'Bulan Januari' },
    { value: '02', label: 'Bulan Februari' },
    { value: '03', label: 'Bulan Maret' },
    { value: '04', label: 'Bulan April' },
    { value: '05', label: 'Bulan Mei' },
    { value: '06', label: 'Bulan Juni' },
    { value: '07', label: 'Bulan Juli' },
    { value: '08', label: 'Bulan Agustus' },
    { value: '09', label: 'Bulan September' },
    { value: '10', label: 'Bulan Oktober' },
    { value: '11', label: 'Bulan November' },
    { value: '12', label: 'Bulan Desember' },
  ];
  const [dataMonitoring, setDataMonitoring] = useState([]);
  const [dataKelompokProyek, setDataKelompokProyek] = useState([]);
  const [dataBulan, setDataBulan] = useState(undefined);
  const [dataTahun, setDataTahun] = useState(new Date().getFullYear());
  const [dataFilter, setDataFilter] = useState([
    {
      bulan: {},
      tahun: {},
    },
  ]);

  const [fetchConfig, setFetchConfig] = useState({
    loading: true,
    success: false,
  });

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: '',
    text: '',
  });

  // GET DATA
  const getInitialData = (printPDF = false) => {
    setFetchConfig({ ...fetchConfig, loading: true });

    LaporanMonitoringApi.getPage({
      bulan_rae: dataBulan,
      tahun_rae: dataTahun,
    })
      .then((res) => {
        const data = res?.data?.data ?? [];
        setDataMonitoring(data);
        setDataKelompokProyek([
          ...new Set(data.map((val) => val.kelompok_proyek)),
        ]);
        setFetchConfig({
          loading: false,
          success: true,
        });

        printPDF && reactToPrint();
      })
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: 'danger',
          text: 'Data gagal dimuat!',
        });
      })
      .finally(() => {
        setFetchConfig({
          loading: false,
          success: false,
        });
      });
  };

  const getExportData = (file) => {
    LaporanMonitoringApi.getExport({
      tipe: file,
      bulan_rae: dataBulan,
      tahun_rae: dataTahun,
    })
      .then((res) => {
        const link = res?.data?.data;
        const parse =
          'https://' +
          link
            .split('/')
            .filter((_val, idx) => idx !== 0)
            .filter((_val, idx) => idx !== 0)
            .join('/');
        saveAs(parse, 'Laporan Monitoring Order');
      })
      .catch(() => {
        window.alert('Export data gagal');
      });
  };

  useEffect(() => {
    setNavbarTitle('Monitoring Order');
    getInitialData();
  }, []);

  const getRupiah = () => {
    const getGrandTotalKelompok = (data, month, kelompok) =>
      data
        .filter((val) => val.bulan_rae === month)
        .filter((val) => val.kelompok_proyek === kelompok)
        .reduce(
          (prev, current) =>
            parseInt(parseFloat(prev) + parseFloat(current?.total_rae ?? 0)),
          0
        );

    const getGrandTotalBulan = (data, month) =>
      data
        .filter((val) => val.bulan_rae === month)
        .reduce(
          (prev, current) =>
            parseInt(parseFloat(prev) + parseFloat(current?.total_rae ?? 0)),
          0
        );

    const getBulanFilter = (data) =>
      data
        .filter((val) => dataFilter?.bulan === val.bulan_rae)
        .reduce(
          (prev, current) =>
            parseInt(parseFloat(prev) + parseFloat(current?.total_rae ?? 0)),
          0
        );

    const getTotal = (data) =>
      data.reduce(
        (prev, current) =>
          parseInt(parseFloat(prev) + parseFloat(current?.total_rae ?? 0)),
        0
      );

    return {
      total_kelompok: getGrandTotalKelompok,
      total_bulan: getGrandTotalBulan,
      total_bulan_filter: getBulanFilter,
      total: getTotal,
    };
  };
  // TABLE
  const TableData = ({ readOnly = false }) => (
    <>
      <h1 style={{ fontSize: '14px' }}>
        <b>
          Laporan Monitoring Order{' '}
          {dataFilter?.tahun ?? new Date().getFullYear()}
        </b>
      </h1>
      <Table>
        <THead>
          <Tr>
            <ThFixed>No</ThFixed>
            <Th colSpan={3}>Deskripsi</Th>
            <Th>Total</Th>
            {!readOnly && <ThFixed>Aksi</ThFixed>}
          </Tr>
        </THead>
        <TBody>
          {dropdownBulan.map((val, index) => {
            if (
              !dataMonitoring.filter((check) => check.bulan_rae === val.value)
                .length > 0
            ) {
              return null;
            }

            return (
              <>
                <Tr key={index}>
                  <TdFixed textCenter>
                    {dataFilter?.bulan ? 1 : index + 1}
                  </TdFixed>
                  <Td colSpan={3}>
                    <b>{val.label}</b>
                  </Td>
                  <Td textRight>
                    <b>
                      {
                        RupiahConvert(
                          String(
                            parseInt(
                              getRupiah().total_bulan(dataMonitoring, val.value)
                            )
                          )
                        ).detail
                      }
                    </b>
                  </Td>
                  {!readOnly && (
                    <TdFixed>
                      <ReadButton
                        onClick={() =>
                          history.push(
                            `/laporan/monitoring-order/detail/${val.value}`,
                            {
                              dataBulan: val.value,
                              dataTahun:
                                dataFilter?.tahun ?? new Date().getFullYear(),
                            }
                          )
                        }
                      />
                    </TdFixed>
                  )}
                </Tr>
                {dataKelompokProyek.map((kelompok) =>
                  dataMonitoring.filter(
                    (check) =>
                      check.bulan_rae === val.value &&
                      check.kelompok_proyek === kelompok
                  ).length > 0 ? (
                    <>
                      <Tr>
                        <Td></Td>
                        <Td textRight>{kelompok ?? '-'}</Td>
                        <Td colSpan={2} textRight>
                          {getRupiah().total_kelompok(
                            dataMonitoring,
                            val.value,
                            kelompok
                          ) > 0
                            ? RupiahConvert(
                                String(
                                  parseInt(
                                    getRupiah().total_kelompok(
                                      dataMonitoring,
                                      val.value,
                                      kelompok
                                    )
                                  )
                                )
                              ).detail
                            : ''}
                        </Td>
                        <Td></Td>
                        {!readOnly && <TdFixed></TdFixed>}
                      </Tr>
                      {dataMonitoring.filter(
                        (fil) =>
                          fil.bulan_rae === val.value &&
                          fil.kelompok_proyek === kelompok
                      ).length > 0
                        ? dataMonitoring
                            .filter(
                              (fil) =>
                                fil.bulan_rae === val.value &&
                                fil.kelompok_proyek === kelompok
                            )
                            .map((data) => {
                              return (
                                <Tr>
                                  <Td></Td>
                                  <Td></Td>
                                  <Td>
                                    {data.instalasi
                                      ? 'Instalasi'
                                      : 'Non Instalasi'}
                                  </Td>
                                  <Td>
                                    {data.total_rae > 0
                                      ? RupiahConvert(
                                          String(parseInt(data.total_rae))
                                        ).detail
                                      : ''}
                                  </Td>
                                  <Td></Td>
                                  {!readOnly && <Td></Td>}
                                </Tr>
                              );
                            })
                        : ''}
                    </>
                  ) : (
                    <></>
                  )
                )}
              </>
            );
          })}
          <Tr>
            <Td colSpan={4}>
              <b>Total</b>
            </Td>
            {dataFilter?.bulan ? (
              <Td textRight>
                <b>
                  {
                    RupiahConvert(
                      String(
                        parseInt(getRupiah().total_bulan_filter(dataMonitoring))
                      )
                    ).detail
                  }
                </b>
              </Td>
            ) : (
              <Td textRight>
                <b>
                  {
                    RupiahConvert(
                      String(parseInt(getRupiah().total(dataMonitoring)))
                    ).detail
                  }
                </b>
              </Td>
            )}
            {!readOnly && <Td></Td>}
          </Tr>
        </TBody>
      </Table>
    </>
  );
  // STYLE
  const inputHeader = { width: '190px' };

  return (
    <CRUDLayout>
      {/* Header */}
      <Card className="p-3 mb-5">
        <Row>
          <div className="ml-3 mt-1" style={inputHeader}>
            <SelectSearch
              label="Bulan"
              placeholder="Pilih bulan..."
              name="bulan_rae"
              defaulValue={[{ value: undefined, label: 'Semua' }]
                .concat(dropdownBulan)
                .find((val) => val.value === dataBulan)}
              option={[{ value: undefined, label: 'Semua' }].concat(
                dropdownBulan
              )}
              onChange={(e) => setDataBulan(e.value)}
            />
          </div>
          <div className="ml-3 mt-1" style={inputHeader}>
            <SelectSearch
              label="Tahun"
              placeholder="Pilih tahun..."
              name="tahun_rae"
              option={_.range(today.getFullYear(), 2020).map((val) => ({
                label: val,
                value: val,
              }))}
              onChange={(e) => setDataTahun(e.value)}
              defaultValue={
                dataTahun
                  ? _.range(today.getFullYear(), 2020)
                      .map((val) => ({ label: val, value: val }))
                      .find((val) => dataTahun === val.value)
                  : ''
              }
            />
          </div>
          <div className="ml-3 mt-4" style={inputHeader}>
            <ActionButton
              className="pl-4 pr-4"
              onClick={() =>
                getInitialData() ||
                setDataFilter({
                  ...dataFilter,
                  tahun: dataTahun,
                  bulan: dataBulan,
                })
              }
            >
              Cari
            </ActionButton>
          </div>
          <div className="ml-auto d-flex pr-3">
            <div className="ml-4 pt-4">
              <ActionButton
                variant="outline-danger"
                text="Export PDF"
                onClick={() => getInitialData(true)}
              />
            </div>
            <div className="ml-2 pt-4">
              <ActionButton
                variant="outline-success"
                text="Export Excel"
                onClick={() => getExportData('excel')}
              />
            </div>
          </div>
        </Row>
      </Card>

      {/* Alert */}
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      {fetchConfig.loading ? (
        <DataStatus loading={true} text="Memuat..." />
      ) : !dataMonitoring || dataMonitoring.length < 1 ? (
        <DataStatus text="Tidak ada data" />
      ) : (
        <TableData />
      )}

      {/* PDF Monitoring Order */}
      <div style={{ display: 'none' }}>
        <ExportMonitoringOrder
          ref={refPrint}
          dataFilter={dataFilter.filter}
          tableData={TableData}
        />
      </div>
    </CRUDLayout>
  );
};

export default MonitoringOrder;
