import React, { useState } from 'react';
import { Table, Th, Td, ThFixed } from 'components';
import { IoAddOutline, IoCheckmarkOutline } from 'react-icons/io5';
import { Alert, ActionButton, DataStatus } from 'components';
import { DateConvert, RupiahConvert } from 'utilities';

const Datatable = ({ data, history, setModalConfig, kelompok, tipe }) => {
  // ALERT
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: 'primary',
    text: '',
  });
  // CSS
  const tableStyling = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
  };
  const tableStylingItem = {
    padding: '0.5px',
    fontSize: '14px',
    verticalAlign: 'middle',
    minWidth: '430px',
  };

  let grandTotal = 0;

  const filteredData = [...data].filter(
    (val) => val.nama_kelompok === kelompok
  );
  const filteredHistory =
    [...history].filter((val) => val.nama_kelompok === kelompok) ?? [];

  const ListDataTable = ({
    index,
    val,
    setModalConfig,
    aksi,
    is_data,
    isHistory,
  }) => {
    let tgl_prelim = '';
    let no_prelim = '';
    let kode_prelim = '';
    let qty = 0;
    let nama_satuan = '';
    let durasi = 0;
    let satuan_durasi = '';
    let tambah = true;
    if (is_data) {
      tgl_prelim =
        kelompok === 'Lain-lain'
          ? val.tgl_overhead_prelim
          : kelompok === 'Honor Staff'
          ? val.tgl_kontrak_prelim
          : val.tgl_transfer_prelim;
      kode_prelim = val.kode_item_prelim;
      no_prelim =
        kelompok === 'Lain-lain'
          ? val.no_overhead_prelim
          : kelompok === 'Honor Staff'
          ? val.no_kontrak_prelim
          : val.no_transfer_prelim;
      qty =
        kelompok === 'Lain-lain'
          ? val.qty_overhead
          : kelompok === 'Honor Staff'
          ? val.qty_kontrak
          : val.qty_transfer;
      nama_satuan =
        kelompok === 'Lain-lain'
          ? val.nama_satuan_overhead
          : kelompok === 'Honor Staff'
          ? val.nama_satuan_kontrak
          : val.nama_satuan_transfer;
      durasi = val.qty_durasi;
      satuan_durasi = val.nama_satuan_durasi;
    } else {
      tgl_prelim =
        kelompok === 'Lain-lain'
          ? val.tgl_realisasi_overhead_prelim
          : kelompok === 'Honor Staff'
          ? val.tgl_realisasi_kontrak_prelim
          : val.tgl_realisasi_transfer_prelim;
      kode_prelim = val.kode_item_prelim;
      no_prelim =
        kelompok === 'Lain-lain'
          ? val.no_realisasi_overhead_prelim
          : kelompok === 'Honor Staff'
          ? val.no_realisasi_kontrak_prelim
          : val.no_realisasi_transfer_prelim;
      qty = val.qty_realisasi;
      nama_satuan = val.nama_satuan_realisasi;
      durasi = val.durasi_realisasi;
      satuan_durasi = val.nama_satuan_waktu;
    }

    if (kelompok === 'Honor Staff') {
      history
        .filter((data) => data.no_kontrak_prelim === val.no_kontrak_prelim)
        .map(() => (tambah = false));
    } else if (kelompok === 'Lain-lain') {
      history
        .filter((data) => data.no_overhead_prelim === val.no_overhead_prelim)
        .map(() => (tambah = false));
    } else {
      history
        .filter((data) => data.no_transfer_prelim === val.no_transfer_prelim)
        .map(() => (tambah = false));
    }

    const checkIsButtonDisabled = () => {
      const totalQty = filteredHistory
        .filter((fil) => fil.kode_item_prelim === val.kode_item_prelim)
        .reduce((prev, curr) => {
          const newQty = parseFloat(curr?.qty_realisasi ?? 0);

          return prev + newQty;
        }, 0);
      console.log(totalQty);
      return totalQty >= qty;
    };

    return (
      <tr key={index}>
        <Td className="text-center" style={tableStyling}>
          {index + 1}
        </Td>
        {/* Cek jika history, tampilkan tgl_permintaan_prelim dan no prelim*/}
        {isHistory && (
          <Td style={tableStyling}>
            {
              DateConvert(
                new Date(
                  val?.tgl_realisasi_kontrak_prelim ??
                    val?.tgl_realisasi_transfer_prelim ??
                    val?.tgl_realisasi_overhead_prelim
                )
              ).defaultDMY
            }{' '}
            <br />
            {val?.no_realisasi_kontrak_prelim ??
              val?.no_realisasi_transfer_prelim ??
              val?.no_realisasi_overhead_prelim}
          </Td>
        )}
        <Td className="text-center" style={tableStyling}>
          {' '}
          {val.kode_item_prelim ?? '-'}{' '}
        </Td>
        <Td style={tableStyling}>{val.nama_item_prelim ?? '-'}</Td>
        <Td style={tableStyling}>
          {qty ? `${parseFloat(qty)} ${nama_satuan}` : '-'}
        </Td>
        <Td className="text-right" style={tableStyling}>
          {
            RupiahConvert(
              String(
                parseInt(
                  val?.harga_satuan ??
                    val?.harga_satuan_prelim ??
                    val?.unit_price ??
                    0
                )
              )
            ).detail
          }
        </Td>
        <Td style={tableStyling}>
          {`${parseInt(val?.qty_durasi ?? val?.durasi_realisasi)} Hari`}{' '}
        </Td>
        <Td className="text-right" style={tableStyling}>
          {
            RupiahConvert(
              String(
                parseInt(
                  parseInt(
                    val?.harga_satuan ??
                      val?.harga_satuan_prelim ??
                      val?.unit_price ??
                      0
                  ) * parseFloat(qty)
                )
              )
            ).detail
          }
        </Td>

        {aksi ? (
          <Td className="text-center" style={tableStyling}>
            <ActionButton
              type="button"
              size="sm"
              variant="primary"
              text={<IoAddOutline />}
              onClick={() =>
                setModalConfig({
                  show: true,
                  type: 'primary',
                  tipe: kelompok,
                  data: val,
                  history: filteredHistory,
                })
              }
              disable={checkIsButtonDisabled()}
            />
          </Td>
        ) : (
          ''
        )}
      </tr>
    );
  };

  return (
    <div className="p-3">
      <Alert
        show={alertConfig.show}
        variant={alertConfig.variant}
        text={alertConfig.text}
        showCloseButton={true}
        onClose={() => setAlertConfig({ ...alertConfig, show: false })}
      />

      <div>
        {/* List Item Prelim */}
        <div>
          <small className="font-weight-bold">List Item Prelim</small>
        </div>

        <Table className="table table-bordered bg-white table-sm m-0">
          <thead className="text-center bg-light">
            <tr>
              <ThFixed className="align-middle" style={tableStyling}>
                No.
              </ThFixed>
              <ThFixed className="align-middle" style={tableStyling}>
                Kode Item
              </ThFixed>
              <Th className="align-middle" style={tableStyling}>
                Item Prelim
              </Th>
              <Th width={100} className="align-middle" style={tableStyling}>
                Qty. Prelim
              </Th>
              <Th width={150} className="align-middle" style={tableStyling}>
                Unit Price
              </Th>
              <ThFixed className="align-middle" style={tableStyling}>
                Durasi Pemakaian
              </ThFixed>
              <Th width={150} className="align-middle" style={tableStyling}>
                Sub Total
              </Th>
              {tipe === 'tambah' && (
                <ThFixed className="align-middle" style={tableStyling}>
                  Aksi
                </ThFixed>
              )}
            </tr>
          </thead>
          <tbody>
            {filteredData?.length > 0 ? (
              filteredData?.map((val, index) => {
                const qty =
                  val?.qty_analisa ??
                  val?.qty_transfer ??
                  val?.qty_kontrak ??
                  val?.qty_overhead ??
                  0;
                const harga =
                  val?.unit_price ??
                  val?.harga_satuan ??
                  val?.harga_satuan_prelim ??
                  0;
                const satuan_qty = val.nama_satuan_prelim;
                const subTotal = parseInt(harga) * parseInt(qty);
                grandTotal = grandTotal + (subTotal ?? 0);

                return (
                  <ListDataTable
                    key={index}
                    index={index}
                    val={val}
                    subTotal={subTotal}
                    setModalConfig={setModalConfig}
                    qty={qty}
                    satuan_qty={satuan_qty}
                    satuan_durasi={val.nama_satuan_waktu}
                    is_data={true}
                    aksi={tipe === 'tambah' ? true : false}
                  />
                );
              })
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <Td colSpan={tipe === 'tambah' ? 9 : 8} align="center">
                  <DataStatus text="Tidak ada data" />
                </Td>
              </tr>
            )}

            {/* Total Section */}
            {!!filteredData?.length > 0 && (
              <tr className="bg-light">
                <Td
                  colSpan={6}
                  className="text-right p-2 font-weight-bold"
                  style={tableStyling}
                >
                  {' '}
                  Total{' '}
                </Td>
                <Td
                  className="text-right text-nowrap font-weight-bold"
                  style={tableStyling}
                >
                  {RupiahConvert(String(parseInt(grandTotal))).detail}
                </Td>
                {tipe === 'tambah' && <Td></Td>}
              </tr>
            )}
          </tbody>
        </Table>

        {/* History Realisasi Prelim */}
        <div className="mt-3">
          <small className="font-weight-bold">Histori Realisasi Prelim</small>
        </div>

        <Table className="table table-bordered bg-white table-sm m-0">
          <thead className="text-center bg-light">
            <tr>
              <ThFixed className="align-middle" style={tableStyling}>
                No.
              </ThFixed>
              <ThFixed className="align-middle" style={tableStyling}>
                Informasi Realisasi Prelim
              </ThFixed>
              <ThFixed className="align-middle" style={tableStyling}>
                Kode Item
              </ThFixed>
              <Th className="align-middle" style={tableStyling}>
                Item Prelim
              </Th>
              <Th width={100} className="align-middle" style={tableStyling}>
                Qty. Realisasi Prelim
              </Th>
              <Th width={150} className="align-middle" style={tableStyling}>
                Unit Price
              </Th>
              <ThFixed className="align-middle" style={tableStyling}>
                Durasi Pemakaian
              </ThFixed>
              <Th width={150} className="align-middle" style={tableStyling}>
                Sub Total
              </Th>
            </tr>
          </thead>
          <tbody>
            {filteredHistory?.length > 0 ? (
              filteredHistory?.map((val, index) => {
                const qty = val?.qty_realisasi;
                const harga =
                  val?.unit_price ??
                  val?.harga_satuan ??
                  val?.harga_satuan_prelim ??
                  0;
                const satuan_qty = val.nama_satuan_permintaan;
                const satuan_durasi = val.nama_satuan_durasi;
                const subTotal = harga * qty;
                return (
                  <ListDataTable
                    key={index}
                    index={index}
                    val={val}
                    subTotal={subTotal}
                    setModalConfig={setModalConfig}
                    isHistory={true}
                    qty={qty}
                    satuan_qty={satuan_qty}
                    satuan_durasi={satuan_durasi}
                  />
                );
              })
            ) : (
              <tr style={{ textAlign: 'center' }}>
                <td colSpan="10" align="center">
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Datatable;
